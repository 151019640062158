<template>
  <section class="rg-grid">
    <div class="w12 m6 l6">
      <div style="display: flex; justify-content: space-between">
        <h2>Versionen</h2>
        <div>
          <rg-dropdown :width="300">
            <template #trigger>
              <rg-button
                type="is-primary"
                icon-left="plus"
                label="Neue Version erstellen"
                icon-right="chevron-down"
              />
            </template>
            <template v-if="Object.values(templates).some((t) => !t.hide)">
              <p>
                <strong>Version aus Vorlage generieren</strong>
              </p>
              <template v-for="[key, template] in Object.entries(templates)">
                <rg-button
                  v-if="!template.hide"
                  :key="key"
                  :label="template.name"
                  icon-left="plus"
                  :type="
                    template.machineTypes &&
                    template.machineTypes.includes(machine.type)
                      ? 'is-primary'
                      : ''
                  "
                  @click="setup(key)"
                />
              </template>
              <hr />
            </template>
            <rg-button
              label="Leere Version erstellen"
              icon-left="plus"
              @click="setup()"
            />
          </rg-dropdown>
        </div>
      </div>
      <br />
      <section class="card" style="border-radius: 10px; padding: 20px">
        <span v-if="versions.loading">
          <span v-if="!versions.data.length">Versionen werden geladen</span>
          <rg-button v-else disabled icon-left="file"
            >Formular wird geladen...</rg-button
          >
        </span>
        <div v-else-if="versions.data.length">
          <version
            v-for="(version, index) in versions.data"
            :key="version.id"
            :link="'/machines/' + $route.params.id + '/tech/' + version.id"
            :preview="
              '/machines/' + $route.params.id + '/preview/' + version.id
            "
            :version="version"
            :newest-live="newestLive"
            :index="index"
            :slug="machine.slug"
            :count="versions.data.length"
            @duplicate="generateVersion($event)"
            @sendlive="sendLive($event)"
          />
        </div>
        <div v-else>
          <div class="text-center" style="padding: 50px 0">
            <strong>Keine Versionen gefunden</strong><br /><br />
            <rg-button
              label="Erstelle die erste Version"
              icon-right="chevron-right"
              type="is-primary"
              @click="setup()"
            />
          </div>
        </div>
      </section>
    </div>
    <div class="w12 m6 l6">
      <h2>Versionen kopieren</h2>
      <br />
      <section class="card" style="border-radius: 10px; padding: 20px">
        <div class="rg-grid">
          <h4 class="w12">Daten einer anderen Version kopieren</h4>

          <div class="field w12">
            <input
              v-model="copyVersion"
              style="width: 100%"
              type="text"
              placeholder="Slug einer Live Maschine oder Versions-ID"
            />
            <rg-button
              :disabled="!copyVersion"
              label="Kopieren"
              icon-left="save"
              @click="copyAnotherVersion()"
            />
          </div>
        </div>
        <span style="opacity: 0.5">{{
          copyVersion.length ? copyVersionFormat.msg : ""
        }}</span>
        <div v-if="copyVersion.trim() === 'jsonrecovery'" class="rg-grid">
          <h4 class="w12" style="margin-top: 20px">
            <fa class="fa-fw" icon="magic" />
            Super hidden recovery process
          </h4>
          <div class="w6">
            <label :class="[$style.jsonupload, 'text-center']" for="jsonupload">
              <fa class="fa-2x" icon="upload" /><br /><br />
              JSON hochladen
              <input
                id="jsonupload"
                type="file"
                accept="application/JSON"
                @input="json($event.target.files)"
              />
            </label>
            <rg-button
              class="flex-center"
              style="width: 100%"
              :disabled="!JSONfile.length"
              label="Einlesen und Speichern"
              icon-left="save"
              @click="jsonFileLoad()"
            />
          </div>
          <div class="w6">
            <textarea
              v-model="JSONtext"
              :class="$style.JSONdownload"
              placeholder="Hier den Text aus der neuen 'JSON generieren'-Funktion einfügen"
            />
            <rg-button
              class="flex-center"
              style="width: 100%"
              :disabled="!JSONtext"
              label="Einlesen und Speichern"
              icon-left="save"
              @click="jsontextconvert()"
            />
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { ref, computed, watchEffect } from "@vue/composition-api";
import dropdown from "@/components/elements/rg-dropdown";
import version from "@/components/elements/rg-version";
import superagent from "superagent";
import { altBackendUrl } from "@/constants.js";
import Vue from "vue";
import templates from "@/templates";

export default {
  name: "TechdataInput",
  components: { version, "rg-dropdown": dropdown },
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    let isAdmin = context.root.$store.state.user.tier === "administration";

    let machine = ref({});
    let JSONfile = ref({});
    let JSONtext = ref("");
    let copyVersion = ref("");
    let newestLive = ref(null);
    let versions = ref({
      loading: true,
      data: [],
    });

    machine.value = props.data;
    watchEffect(() => {
      machine.value = props.data;
    });

    let copyVersionFormat = computed(() => {
      let format = {
        slug: /^[A-z0-9]+-(-?[A-z0-9])+$/.test(copyVersion.value),
        hash: !/\D/.test(copyVersion.value),
      };
      format.valid = format.slug || format.hash;
      format.msg = format.slug
        ? "Slug erkannt"
        : format.hash
        ? "Versions-ID erkannt"
        : "Unbekanntes Format";
      return format;
    });

    const machineWiPsQuery = `query Query($machineWiPsMachineId: Int!) {
        MachineWIPs(machineID: $machineWiPsMachineId) {
          id
          author
          machine_id
          publishedat
          createdat
          updatedat
          source_language_id
          wip_comment
          editor_version
        }
        NewestWIPEditorVersion(machineID: $machineWiPsMachineId)
      }`;

    superagent
      .post(altBackendUrl)
      .send({
        query: machineWiPsQuery,
        variables: {
          machineWiPsMachineId: parseInt(machine.value.id),
        },
      })
      .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
      .then((versionList) => {
        let list = versionList.body?.data?.MachineWIPs || [];

        if (list?.length > 0) {
          let id = list.sort((a, b) => {
            return new Date(b.createdat) - new Date(a.createdat) || b.id - a.id;
          })[0].id;
          let mapped = list.map((version) => {
            if (!newestLive.value && version.publishedat)
              newestLive.value = version.id;
            let readonly = version.id !== id || version.publishedat;
            return {
              ...version,
              readonly,
            };
          });

          versions.value = {
            loading: false,
            data: mapped || [],
          };
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.status === 426) {
          Vue.$toast.open({
            message: "Sitzung abgelaufen. Bitte neu anmelden",
            icon: "exclamation",
            type: "error",
          });
          context.root.$store.dispatch("logout").then(() => {
            context.root.$router.push("/login");
          });
        } else {
          Vue.$toast.open({
            message: "Netzwerkfehler " + error.status,
            icon: "exclamation",
            type: "error",
          });
        }
      })
      .finally(() => {
        versions.value.loading = false;
      });

    function json(e) {
      JSONfile.value = {};
      const fr = new FileReader();
      try {
        fr.readAsText(e.item(0));
      } catch (error) {
        JSONfile.value = {};
        console.error(error);
      }
      fr.onload = function (ev) {
        JSONfile.value = JSON.parse(ev.target.result);
        console.log(JSONfile.value);
      };
    }

    function jsonFileLoad() {
      if (JSONfile.value) {
        save(JSONfile.value);
      }
    }

    function jsontextconvert() {
      try {
        const result = JSON.parse(JSONtext.value);
        save(result);
      } catch (e) {
        console.log(e);
        Vue.$toast.open({
          message: "JSON error",
          type: "error",
          icon: "exclamation",
        });
      }
    }

    function copyAnotherVersion() {
      const v = copyVersionFormat.value.slug
        ? { slug: copyVersion.value }
        : copyVersionFormat.value.hash
        ? { hash: copyVersion.value }
        : null;
      if (v) {
        generateVersion(v);
      }
    }

    async function generateVersion({ hash, slug }) {
      if (!hash && !slug) {
        return;
      }
      let data = null;
      let options = {};

      if (hash) {
        console.log("loading hash");
        // get version by id
        // get all versions by version.machineid
        // get newest published version if there is one
        // if no published version -> save
        // if published version older than id -> save
        // if published version newer than id -> error

        const machineWiPsQuery = `query Query($machineWiPsId: Int) {
            MachineWIPs(id: $machineWiPsId) {
              machine_id
              machine_data
              createdat
              source_language_id
              wip_comment
            }
          }`;

        // STEP 1: get version by id
        await superagent
          .post(altBackendUrl)
          .send({
            query: machineWiPsQuery,
            variables: {
              machineWiPsId: parseInt(hash),
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then(async (vers) => {
            console.log("STEP 1:", vers);
            const VERSION = vers.body?.data?.MachineWIPs?.[0];

            if (!vers?.body?.errors && VERSION) {
              const machineWiPsQuery = `query Query($machineWiPsMachineId: Int) {
                  MachineWIPs(machineID: $machineWiPsMachineId) {
                    id
                    publishedat
                    createdat
                    source_language_id
                    wip_comment
                  }
                }`;

              // STEP 2: get all versions by version.machineid
              await superagent
                .post(altBackendUrl)
                .send({
                  query: machineWiPsQuery,
                  variables: {
                    machineWiPsMachineId: VERSION.machine_id,
                  },
                })
                .set(
                  "Authorization",
                  `Bearer ${context.root.$store.state.user.apiToken}`
                )
                .then((allversions) => {
                  const ALL = allversions.body?.data?.MachineWIPs || [];

                  if (!allversions.body?.errors && ALL.length) {
                    if (
                      ALL.find((v) => {
                        return (
                          v.publishedat &&
                          new Date(VERSION.createdat) < new Date(v.publishedat)
                        );
                      })
                    ) {
                      Vue.$toast.open({
                        message:
                          "Die Maschine dieser Version ist live. Bitte verwende den Slug.",
                        icon: "exclamation",
                        type: "error",
                      });
                    } else if (VERSION.machine_data) {
                      data = VERSION.machine_data;
                      options.source_language_id = VERSION.source_language_id;
                      options.wip_comment = null; //should be null for every new version for now
                    } else {
                      Vue.$toast.open({
                        message:
                          "Diese Version ist leer und kann nicht kopiert werden.",
                        icon: "exclamation",
                        type: "error",
                      });
                    }
                  } else {
                    Vue.$toast.open({
                      message: "Überprüfung auf Live-Versionen Fehlgeschlagen",
                      icon: "exclamation",
                      type: "error",
                    });
                  }
                })
                .catch(() => {
                  Vue.$toast.open({
                    message: "Überprüfung auf Live-Versionen Fehlgeschlagen",
                    icon: "exclamation",
                    type: "error",
                  });
                });
            } else {
              Vue.$toast.open({
                message: "Version konnte nicht gefunden werden.",
                icon: "exclamation",
                type: "error",
              });
            }
          });
      } else if (slug) {
        console.log("loading per slug");
        if (!/^[A-z0-9]+-(-?[A-z0-9])+$/.test(slug)) {
          Vue.$toast.open({
            message: "Slug ungültig",
            icon: "exclamation",
            type: "error",
          });
        } else {
          let originLanguageCode = null;

          const getLanguageQuery = `
              query Query($slug: String) {
                Machine(slug: $slug) {
                  Data {
                    originalfallbacklanguage
                  }
                }
              }
            `;

          await superagent
            .post(altBackendUrl)
            .send({
              query: getLanguageQuery,
              variables: {
                slug: slug,
              },
            })
            .set(
              "Authorization",
              `Bearer ${context.root.$store.state.user.apiToken}`
            )
            .then(async (res) => {
              originLanguageCode =
                res.body?.data?.Machine?.Data?.originalfallbacklanguage;

              const getLanguageIdQuery = `
                  query Query($languageCode: String) {
                    AppLanguages(language_code: $languageCode) {
                      id
                    }
                  }
                `;
              await superagent
                .post(altBackendUrl)
                .send({
                  query: getLanguageIdQuery,
                  variables: {
                    languageCode: originLanguageCode,
                  },
                })
                .set(
                  "Authorization",
                  `Bearer ${context.root.$store.state.user.apiToken}`
                )
                .then((res) => {
                  options.source_language_id =
                    res.body?.data?.AppLanguages?.[0]?.id;
                  options.wip_comment = null;
                });
            });

          const machineWIPQuery = `
              query Query($machineSlug: String, $language: String, $includeInternalFields: Boolean) {
                Machine(slug: $machineSlug) {
                  id
                  Data(language: $language) {
                    all(includeInternalFields: $includeInternalFields)
                  }
                }
              }
            `;

          await superagent
            .post(altBackendUrl)
            .send({
              query: machineWIPQuery,
              variables: {
                machineSlug: slug,
                language: originLanguageCode,
                includeInternalFields: true,
              },
            })
            .set(
              "Authorization",
              `Bearer ${context.root.$store.state.user.apiToken}`
            )
            .then((res) => {
              if (res?.body?.data?.Machine?.Data?.all) {
                data = res?.body?.data?.Machine?.Data?.all;
              } else {
                Vue.$toast.open({
                  message: "Version korrupt",
                  type: "error",
                  icon: "exclamation",
                });
              }
            });
        }
      } else {
        Vue.$toast.open({
          message: "Keinen Slug / keine ID angegeben",
          icon: "exclamation",
          type: "error",
        });
        return;
      }
      if (data) {
        console.log("saving ", data);
        save(data, options);
      }
    }

    function save(data, options = null) {
      if (machine.value.slug && data) {
        const WIPmutation = `
            mutation mMachineWIP($machineWipId: Int, $machineWipMachineId: Int, $machineWipMachineData: JSON, $machineWipAuthor: Int, $machineWipPublishedat: DateTime, $machineWipCreatedat: DateTime, $sourceLanguageId: Int, $wipComment: String) {
            MachineWIP(id: $machineWipId, machine_id: $machineWipMachineId, machine_data: $machineWipMachineData, author: $machineWipAuthor, publishedat: $machineWipPublishedat, createdat: $machineWipCreatedat, source_language_id: $sourceLanguageId, wip_comment: $wipComment) {
              id
            }
          }`;

        const PAYLOAD = {
          data: data?.data || data,
          _slug: machine.value.slug,
          author: context.root.$store.state.user.login,
          updatedby: context.root.$store.state.user.login,
          editorVersion: data?.editorVersion || "v1",
          timestamp: new Date(),
        };
        superagent
          .post(altBackendUrl)
          .send({
            query: WIPmutation,
            variables: {
              machineWipId: -1,
              machineWipMachineId: machine.value.id,
              machineWipMachineData: PAYLOAD,
              machineWipAuthor: context.root.$store.state.user.id,
              sourceLanguageId: options?.source_language_id || 0,
              wipComment: options?.wip_comment || null,
              // machineWipPublishedat: new Date(),
              // machineWipCreatedat: new Date(),
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            console.log(res);

            if (!res.body.error) {
              Vue.$toast.open({
                message: "Neue Version erfolgreich gespeichert. Lade neu…",
                icon: "check",
              });
              wonkyRefresh();
            } else {
              console.error(res.body.error);
              Vue.$toast.open({
                message: "Es ist ein Fehler aufgetreten",
                type: "error",
                icon: "exclamation",
              });
            }
          })
          .catch((err) => {
            console.error(err?.response?.body?.error);
            Vue.$toast.open({
              message: "Es ist ein Fehler aufgetreten",
              type: "error",
              icon: "exclamation",
            });
          });
      }
    }

    function wonkyRefresh() {
      setTimeout(() => {
        context.root.$router.go();
      }, 2000);
    }

    function parseTemplate(groups) {
      let temp = [];
      groups.forEach((element, index) => {
        if (element.is === "attribute") {
          temp.push({
            attribute: {
              comment: element.comment || "",
              flagged: element.flagged || false,
              title: element.title || "",
              unit: element.unit || "",
              value: element.value || "",
              type: "string",
              category: element.category || "Technische Daten",
            },
          });
        } else {
          temp.push({
            group: {
              title: element.title,
              description: "",
              comment: element.comment,
              flagged: false,
              childs: [],
            },
          });

          for (let i = 0; i < element.attributes; i++) {
            temp[index].group.childs.push({
              attribute: {
                comment: "",
                flagged: false,
                title: "",
                unit: "",
                value: "",
                type: "string",
                category: element.attributeType || "Technische Daten",
              },
            });
          }
          if (element.children?.length) {
            temp[index].group.childs.push(...parseTemplate(element.children));
          }
        }
      });
      return temp;
    }

    function setup(templateKey = null) {
      let data = [];
      if (templateKey) {
        data = parseTemplate(templates[templateKey].data);
      } else {
        data.push({
          group: {
            title: "",
            description: "",
            comment: "",
            flagged: false,
            childs: [],
          },
        });
        for (let i = 0; i < 5; i++) {
          data[0].group.childs.push({
            attribute: {
              comment: "",
              flagged: false,
              title: "",
              unit: "",
              value: "",
              type: "string",
              category: "Technische Daten",
            },
          });
        }
      }
      save(data);
    }

    function sendLive(vHash) {
      console.log(vHash);
      if (isAdmin && vHash) {
        if (
          window.confirm(
            "Möchtest du die Version #" + vHash + " veröffentlichen?"
          )
        ) {
          const SendMachineVersionLiveMutation = `mutation SendMachineVersionLiveMutation($vHash: Int!) {
            SendMachineVersionLive(versionID: $vHash)
          }`;

          superagent
            .post(altBackendUrl)
            .send({
              query: SendMachineVersionLiveMutation,
              variables: {
                vHash: vHash,
              },
            })
            .set(
              "Authorization",
              `Bearer ${context.root.$store.state.user.apiToken}`
            )
            .then((res) => {
              if (!res?.body?.errors) {
                console.info("Horray, nothing broke.");
                Vue.$toast.open({
                  message: res.body.data.SendMachineVersionLive,
                  icon: "check",
                });
                wonkyRefresh();
              } else {
                console.error(res.body.errors.message);
                Vue.$toast.open({
                  message: res.body.errors.message,
                  type: "error",
                  icon: "exclamation",
                });
              }
            })
            .catch((e) => {
              console.error(e);
              Vue.$toast.open({
                message: e,
                icon: "exclamation",
                type: "error",
              });
            });
        }
      } else if (!isAdmin) {
        Vue.$toast.open({
          message: "Nur Administratoren können Versionen veröffentlichen",
          icon: "exclamation",
          type: "error",
        });
      }
    }

    return {
      isAdmin,
      sendLive,
      copyAnotherVersion,
      machine,
      copyVersion,
      generateVersion,
      copyVersionFormat,
      setup,
      json,
      jsontextconvert,
      save,
      versions,
      newestLive,
      JSONtext,
      JSONfile,
      jsonFileLoad,
      templates,
    };
  },
};
</script>

<style lang="scss" module>
.modal {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  // height: 520px;
  z-index: 999999;
  width: 500px;
  background: var(--text-light);

  @supports (backdrop-filter: blur(20px)) {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(20px);
  }

  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  padding: 40px 30px;
}

.JSONdownload {
  height: 100px;
  font-size: 0.7em;
  width: 100%;
  resize: none;
  padding: 10px;
  border: 1px solid grey;
  border-radius: 4px 4px 0 0;

  + button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1px;
  }
}

.jsonupload {
  display: flex;
  height: 100px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px dashed grey;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-radius: 6px;
  cursor: pointer;

  svg {
    margin-bottom: 10px;
  }

  &:hover {
    background: rgba(128, 128, 128, 0.1);
  }

  &:active {
    background: rgba(128, 128, 128, 0.2);
  }

  input {
    display: none;
  }

  + button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>

<style lang="scss">
.flip-enter-active,
.flip-leave-active,
.flip-move {
  transition: all 0.2s ease !important;
}

.flip-enter,
.flip-leave-to {
  opacity: 0;
}

.opacity-enter-active,
.opacity-leave-active {
  transform: translateY(-50%) scale(1);
  transition: all 0.2s ease !important;
}

.opacity-enter,
.opacity-leave-to {
  opacity: 0;
  transform: translateY(-50%) scale(0.8) !important;
}

.rg-select {
  display: block;
  font-size: 1rem;
  font-family: sans-serif;
  padding: 0.5em 0.5em 0.5em 0.5em;
  max-width: 100%;
  border: none;
  border: 1px solid var(--card-light-stack);
  color: var(--text-dark);
  border-radius: 6px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--input-background);
  text-overflow: ellipsis;
}

.rg-select::-ms-expand {
  display: none;
}

.rg-select:focus {
  border-color: var(--text-dark);
  outline: none;
}

.rg-select option {
  font-weight: normal;
  font-family: sans-serif;
  color: var(--text-dark);
}
</style>
