<template>
  <div :class="$style.detail">
    <rg-widget title="Sicherheit">
      <template #data>
        <rg-button
          type="is-primary"
          icon-left="key"
          label="Passwort zurücksetzen"
          @click="resetPassword()"
        />
      </template>
    </rg-widget>
  </div>
</template>

<script>
import { ref, watchEffect } from "@vue/composition-api";
import Vue from "vue";
import superagent from "superagent";
import { altBackendUrl } from "../../../constants";

export default {
  components: {
    "rg-widget": () => import("@/components/elements/rg-widget"),
  },
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    let user = ref({});
    watchEffect(() => {
      user.value = props.data;
    });

    function resetPassword() {
      let confirmed = null;
      confirmed = confirm(
        `Achtung:
        Hiermit wird das Passwort für den Benutzer
        ${user.value.User.prename} ${user.value.User.name}
        und der ID ${user.value.User.id} zurückgesetzt.
        Der Nutzer wird per E-Mail an ${user.value.User.email}
        über diesen Vorgang informiert.`
      );
      if (confirmed) {
        // console.log("Fire at will!");
        const resetPWMutation = `mutation RequestPassword($pwRequestEmail: String!, $pwRequestId: Int) {
          PWRequest(email: $pwRequestEmail, id: $pwRequestId)
        }
      `;
        superagent
          .post(altBackendUrl)
          .send({
            query: resetPWMutation,
            variables: {
              pwRequestEmail: user.value.User.email,
              pwRequestId: user.value.User.id,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((resPW) => {
            if (resPW.body?.data?.PWRequest) {
              Vue.$toast.open({
                icon: "check",
                message: "Das Passwort wurde erfolgreich zurückgesetzt.",
              });
            } else {
              Vue.$toast.open({
                icon: "times-circle",
                type: "error",
                message:
                  "FEHLER: Das Passwort konnte nicht zurückgesetzt werden.",
              });
            }
          });
      } else {
        // console.log("Cease fire!");
        Vue.$toast.open({
          icon: "dumpster",
          message: "Der Vorgang wurde abgebrochen.",
        });
      }
    }

    return { user, resetPassword };
  },
};
</script>

<style lang="scss" module>
.detail {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-flow: dense;
  grid-auto-columns: 1fr 1fr;
}
</style>
