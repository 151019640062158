<template>
  <div class="rg-grid">
    <div class="level w12">
      <h2>Verknüpfungen</h2>
    </div>
    <div
      class="card w6"
      style="border-radius: 10px; padding: 20px; margin-bottom: 10px"
    >
      <h3>Maschinen durchsuchen</h3>
      <br />
      <input
        v-model="slugsearch"
        type="search"
        placeholder="Direkte Suche per Slug"
        style="width: 100%"
        @input="esQuery(200, 1)"
        @change="esQuery(0, 1)"
      />
      <br />
      <input
        v-model="search"
        type="search"
        placeholder="Nach Slug und Titel suchen"
        style="width: 100%"
        @input="esQuery(200, 1)"
        @change="esQuery(0, 1)"
      />
      <br />
      <div class="level">
        <h2>{{ results_total }} Ergebnisse</h2>
        <div style="display: flex; align-items: center; gap: 4px">
          <rg-button
            :disabled="page.current <= 1"
            icon="angle-double-left"
            @click="esQuery(0, 1)"
          />
          <rg-button
            :disabled="page.current <= 1"
            icon="angle-left"
            @click="esQuery(0, page.current - 1)"
          />
          {{ page.current }} von
          {{ Math.min(Math.ceil(results_total / page.elements), page.max) }}
          <rg-button
            :disabled="
              page.current >=
              Math.min(Math.ceil(results_total / page.elements), page.max)
            "
            icon="angle-right"
            @click="esQuery(0, page.current + 1)"
          />
          <rg-button
            :disabled="
              page.current >=
              Math.min(Math.ceil(results_total / page.elements), page.max)
            "
            icon="angle-double-right"
            @click="
              esQuery(
                0,
                Math.min(Math.ceil(results_total / page.elements), page.max)
              )
            "
          />
        </div>
      </div>

      <br />

      <div>
        <div
          v-for="result in results"
          :key="result.id"
          :style="
            'display: flex; gap: 8px; align-items: start; justify-content: space-between; padding: 5px 5px; margin-bottom: 10px; background: var(--card-light-stack); border-radius: 8px; border: 2px solid;' +
            (result.id === lastHover
              ? 'border-color: var(--primary)'
              : 'border-color: transparent')
          "
          @mouseenter="lastHover = result.id"
        >
          <div
            style="
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 5px;
              border-radius: 8px;
            "
          >
            <div style="display: flex; flex-wrap: wrap; gap: 5px">
              <span
                v-if="result.manufacturer"
                title="Hersteller"
                style="
                  display: inline-flex;
                  background: var(--card-light-stack);
                  padding: 4px 5px;
                  border-radius: 5px;
                  gap: 5px;
                  align-items: center;
                "
              >
                <fa class="fa-fw" icon="industry" />
                {{ result.manufacturer }}
              </span>
              <span
                v-if="result.series"
                title="Serie"
                style="
                  display: inline-flex;
                  background: var(--card-light-stack);
                  padding: 4px 5px;
                  border-radius: 5px;
                  gap: 5px;
                  align-items: center;
                "
              >
                <fa class="fa-fw" icon="sitemap" />
                {{ result.series }}
              </span>
              <span
                v-if="result.model"
                title="Modell"
                style="
                  display: inline-flex;
                  background: var(--card-light-stack);
                  padding: 4px 5px;
                  border-radius: 5px;
                  gap: 5px;
                  align-items: center;
                "
              >
                <fa class="fa-fw" icon="dice-d6" />
                {{ result.model }}
              </span>
            </div>
            <code>{{ result.slug }}</code>
            <div>
              <a
                :href="'/machines/' + result.id + '/connections'"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong style="font-size: 1.15rem">{{ result.title }}</strong>
              </a>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 4px;
              width: 150px;
            "
            :title="
              isRelated(result)
                ? 'Lösche zuerst die bestehende Verknüpfung um diese Maschine neu zuzuordnen'
                : ''
            "
          >
            <rg-button
              block
              :disabled="isRelated(result)"
              :type="isParent(result) ? 'is-primary' : ''"
              style="width: 100%; justify-content: center"
              @click="connect(result.id)"
            >
              <span style="margin-right: 5px">Übergeordnet</span>
              <fa
                icon="arrow-right"
                class="fa-fw"
                style="transform: rotate(-45deg)"
              />
            </rg-button>
            <rg-button
              block
              :disabled="isRelated(result)"
              :type="isChild(result) ? 'is-primary' : ''"
              style="width: 100%; justify-content: center"
              @click="connect(undefined, result.id)"
            >
              <span style="margin-right: 5px">Untergeordnet</span>
              <fa
                icon="arrow-right"
                class="fa-fw"
                style="transform: rotate(45deg)"
              />
            </rg-button>
          </div>
        </div>
      </div>
    </div>
    <div class="card w6" style="border-radius: 10px; margin-bottom: 10px">
      <div style="padding: 20px">
        <h3>Übergeordnete Maschinen</h3>
      </div>
      <div style="background: var(--card-light-stack)">
        <div
          v-for="(result, i) in partOfMachines"
          :key="result.key"
          :style="
            'display: flex; gap: 8px; align-items: start; justify-content: space-between; padding: 10px 20px;' +
            (result.id == lastHover
              ? 'background: var(--card-light-stack);'
              : '') +
            (i ? 'border-top: 1px solid;' : '')
          "
        >
          <div
            style="
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 5px;
              border-radius: 8px;
            "
          >
            <div style="display: flex; flex-wrap: wrap; gap: 5px">
              <span
                v-if="result.manufacturer"
                title="Hersteller"
                style="
                  display: inline-flex;
                  background: var(--card-light-stack);
                  padding: 4px 5px;
                  border-radius: 5px;
                  gap: 5px;
                  align-items: center;
                "
              >
                <fa class="fa-fw" icon="industry" />
                {{ result.manufacturer }}
              </span>
              <span
                v-if="result.series"
                title="Serie"
                style="
                  display: inline-flex;
                  background: var(--card-light-stack);
                  padding: 4px 5px;
                  border-radius: 5px;
                  gap: 5px;
                  align-items: center;
                "
              >
                <fa class="fa-fw" icon="sitemap" />
                {{ result.series }}
              </span>
              <span
                v-if="result.model"
                title="Modell"
                style="
                  display: inline-flex;
                  background: var(--card-light-stack);
                  padding: 4px 5px;
                  border-radius: 5px;
                  gap: 5px;
                  align-items: center;
                "
              >
                <fa class="fa-fw" icon="dice-d6" />
                {{ result.model }}
              </span>
            </div>
            <code>{{ result.slug }}</code>
            <div>
              <a
                :href="'/machines/' + result.id + '/connections'"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong style="font-size: 1.15rem">{{ result.title }}</strong>
              </a>
            </div>
          </div>
          <rg-button
            type="is-danger"
            icon="times"
            @click="removeConnection(result.id, machine.id)"
          />
        </div>
        <div
          v-if="!partOfMachines.length"
          style="text-align: center; color: var(--black-4); padding: 20px"
        >
          Keine übergeordneten Maschinen
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          padding: 20px 0;
        "
      >
        <fa icon="chevron-down" size="3x" style="color: var(--black-2)" />
        <h3
          style="
            color: var(--black-9);
            display: inline-block;
            border-radius: 9999px;
            background: var(--card-light-stack);
            padding: 20px 30px;
          "
        >
          Diese Maschine
        </h3>
        <fa icon="chevron-down" size="3x" style="color: var(--black-2)" />
      </div>
      <div style="padding: 20px">
        <h3>Untergeordnete Maschinen</h3>
      </div>
      <div style="background: var(--card-light-stack)">
        <div
          v-for="(result, i) in relatedMachines"
          :key="result.key"
          :style="
            'display: flex; gap: 8px; align-items: start; justify-content: space-between; padding: 10px 20px;' +
            (result.id == lastHover
              ? 'background: var(--card-light-stack);'
              : '') +
            (i ? 'border-top: 1px solid;' : '')
          "
        >
          <div
            style="
              flex: 1;
              display: flex;
              flex-direction: column;
              gap: 5px;
              border-radius: 8px;
            "
          >
            <div style="display: flex; flex-wrap: wrap; gap: 5px">
              <span
                v-if="result.manufacturer"
                title="Hersteller"
                style="
                  display: inline-flex;
                  background: var(--card-light-stack);
                  padding: 4px 5px;
                  border-radius: 5px;
                  gap: 5px;
                  align-items: center;
                "
              >
                <fa class="fa-fw" icon="industry" />
                {{ result.manufacturer }}
              </span>
              <span
                v-if="result.series"
                title="Serie"
                style="
                  display: inline-flex;
                  background: var(--card-light-stack);
                  padding: 4px 5px;
                  border-radius: 5px;
                  gap: 5px;
                  align-items: center;
                "
              >
                <fa class="fa-fw" icon="sitemap" />
                {{ result.series }}
              </span>
              <span
                v-if="result.model"
                title="Modell"
                style="
                  display: inline-flex;
                  background: var(--card-light-stack);
                  padding: 4px 5px;
                  border-radius: 5px;
                  gap: 5px;
                  align-items: center;
                "
              >
                <fa class="fa-fw" icon="dice-d6" />
                {{ result.model }}
              </span>
            </div>
            <code>{{ result.slug }}</code>
            <div>
              <a
                :href="'/machines/' + result.id + '/connections'"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong style="font-size: 1.15rem">{{ result.title }}</strong>
              </a>
            </div>
          </div>
          <rg-button
            type="is-danger"
            icon="times"
            @click="removeConnection(machine.id, result.id)"
          />
        </div>
        <div
          v-if="!relatedMachines.length"
          style="text-align: center; color: var(--black-4); padding: 20px"
        >
          Keine untegeordneten Maschinen
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "@vue/composition-api";
import superagent from "superagent";
import { es } from "../../../helper";
import Vue from "vue";
import { altBackendUrl } from "../../../constants";

export default {
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    const machine = ref({});
    const slugsearch = ref("");
    const search = ref("");
    const lastHover = ref(0);
    let debounce = null;
    let page = ref({
      current: 1,
      elements: 10,
      max: 50,
    });
    let results = ref([]);
    let results_total = ref(0);

    let partOfMachines = ref([]);
    let relatedMachines = ref([]);

    watchEffect(() => {
      machine.value = props.data;
    });

    onMounted(() => {
      fetchRelations();
    });

    const isParent = (machine) =>
      partOfMachines.value.some((m) => machine.id === m.id);

    const isChild = (machine) =>
      relatedMachines.value.some((m) => machine.id === m.id);

    const isRelated = (machine) => isParent(machine) || isChild(machine);
    const fetchRelations = () => {
      console.log("fetchRelations");
      const gqFetchRelations = `
        query Query($machineId: Int) {
          Machine(id: $machineId) {
            PartOf {
              id
              parentID: parentid
              slug
              vin
              title
              manufacturer
              series
              model
              type
            }
            RelatedMachines {
              id
              parentID: parentid
              slug
              vin
              title
              manufacturer
              series
              model
              type
            }
          }
        }`;

      superagent
        .post(altBackendUrl)
        .send({
          query: gqFetchRelations,
          variables: { machineId: parseInt(props.data.id) },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: moduleList }) => {
          console.log(moduleList.data.Machine);
          partOfMachines.value = moduleList.data.Machine.PartOf;
          relatedMachines.value = moduleList.data.Machine.RelatedMachines;
        })
        .catch((err) => {
          Vue.$toast.open({
            message: "Verknüpfungen konnten nicht geladen werden.",
          });
          console.error(err);
        });
    };

    const connect = (MAIN_ID = machine.value.id, SUB_ID = machine.value.id) => {
      if (MAIN_ID === SUB_ID) {
        Vue.$toast.open({
          message: "Maschinen können nicht mit sich selbst verknüpft werden.",
          icon: "exclamation-triangle",
        });
        return;
      }
      console.log("connecting parent", MAIN_ID, "to child", SUB_ID);
      const gqCreateConnection = `
        mutation Mutation($machineRelationId: Int, $mainId: Int, $subId: Int) {
          MachineRelation(id: $machineRelationId, main_id: $mainId, sub_id: $subId) {
            id
            main_id
            sub_id
          }
        }`;

      superagent
        .post(altBackendUrl)
        .send({
          query: gqCreateConnection,
          variables: {
            machineRelationId: -1,
            mainId: parseInt(MAIN_ID),
            subId: parseInt(SUB_ID),
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((body) => {
          console.log(body);
          Vue.$toast.open({
            message: "Verknüpfungen erstellt.",
            icon: "check",
          });
        })
        .catch((err) => {
          Vue.$toast.open({
            message: "Verknüpfungen konnten nicht hergestellt werden.",
            icon: "exclamation-triangle",
          });
          console.error(err);
        })
        .finally(() => {
          fetchRelations();
        });
    };

    const esQuery = (timeout, newPage = 1) => {
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        page.value.current = newPage;
        const SEARCH = search.value.trim().toLowerCase();
        const SLUGSEARCH = slugsearch.value.trim().toLowerCase();

        let query = { match_all: {} };
        if (SLUGSEARCH) {
          query = {
            bool: {
              must: [{ match_phrase: { slug: SLUGSEARCH } }],
            },
          };
        } else if (SEARCH) {
          query = {
            bool: {
              minimum_should_match: 1,
              should: [
                { match: { slug: { query: SEARCH, fuzziness: "auto" } } },
                { match: { title: { query: SEARCH, fuzziness: "auto" } } },
              ],
            },
          };
          if (/^\d+$/.test(SEARCH)) {
            query.bool.should = [{ match: { _routing: SEARCH } }];
          }
        }

        superagent
          .post(es + "/repguide_machines/_search?track_total_hits=true", {
            query,
            sort: [SEARCH ? "_score" : { id: { order: "desc" } }],
            highlight: { fields: { slug: {}, title: {} } },
            from: (page.value.current - 1) * page.value.elements,
            size: page.value.elements,
          })
          .set(
            "Authorization",
            "ApiKey SHRMYXBYc0I2YjNKWVpPcjlXVG06YXdOcEpTQ0JTYUMyRktKajdOZGxmQQ=="
          )
          .then((res) => {
            results.value = res.body.hits.hits.map((m) => m._source);
            results_total.value = res.body.hits.total.value;
          });
      }, timeout);
    };

    function removeConnection(mainId, subId) {
      const gqDeleteConnection = `
        mutation Mutation($mainId: Int!, $subId: Int!) {
          Delete{
            MachineRelation(mainId: $mainId, subId: $subId) 
          }
        }`;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqDeleteConnection,
          variables: {
            mainId: parseInt(mainId),
            subId: parseInt(subId),
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((response) => {
          if (response.body?.data?.Delete?.MachineRelation) {
            Vue.$toast.open({
              message: "Verknüpfung wurde erfolgreich gelöscht.",
              icon: "check",
            });
          } else {
            Vue.$toast.open({
              message: "Verknüpfung konnte nicht gelöscht werden.",
              icon: "exclamation-triangle",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          Vue.$toast.open({
            message:
              "Verknüpfung konnte nicht gelöscht werden. Fehler: " +
              err.message,
            icon: "exclamation-triangle",
          });
        })
        .finally(() => {
          fetchRelations();
        });
    }

    esQuery(0);

    return {
      machine,
      slugsearch,
      search,
      results,
      results_total,
      esQuery,
      page,
      partOfMachines,
      relatedMachines,
      isParent,
      isChild,
      isRelated,
      lastHover,
      connect,
      removeConnection,
    };
  },
};
</script>
