<template>
  <div>
    <navigation />
    <main>
      <h1><fa icon="database" />&nbsp;Maschinen</h1>
      <p>Alle Maschinen auf Rep.Guide</p>
      <div class="level">
        <input
          v-model="slugsearch"
          :class="$style.is_medium"
          type="search"
          placeholder="Direkte Suche per Slug"
          style="flex:1;font-size: 1.2em; padding: 10px 16px"
          @input="esQuery(200, 1)"
          @change="esQuery(0, 1)"
        >
        <input
          v-model="search"
          :class="$style.is_medium"
          type="search"
          placeholder="Nach Slug und Titel suchen"
          style="flex: 1; font-size: 1.2em; padding: 10px 16px"
          @input="esQuery(200, 1)"
          @change="esQuery(0, 1)"
        >

        <div>
          <router-link to="machines/add">
            <rg-button
              type="is-primary"
              icon-left="plus"
              label="Maschine anlegen"
              title="Maschine anlegen"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
          </router-link>
          <dropdown>
            <template
              #trigger
            >
              <rg-button
                icon="chevron-down"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              />
            </template>
            <router-link to="/jaltest_matching">
              <rg-button
                icon-left="plus"
                label="Jaltest Maschine anlegen"
              />
            </router-link>
          </dropdown>
        </div>
      </div>
      <br>
      <h2>{{ machinesCount }} Ergebnisse</h2>
      <rg-button
        :disabled="page.current <= 1"
        icon="angle-double-left"
        @click="esQuery(0, 1)"
      />
      <rg-button
        :disabled="page.current <= 1"
        icon="angle-left"
        @click="esQuery(0, page.current - 1)"
      />
      {{ page.current }} von {{ Math.min(Math.ceil(machinesCount / page.elements), page.max) }}
      <rg-button
        :disabled="page.current >= Math.min(Math.ceil(machinesCount / page.elements), page.max)"
        icon="angle-right"
        @click="esQuery(0, page.current + 1)"
      />
      <rg-button
        :disabled="page.current >= Math.min(Math.ceil(machinesCount / page.elements), page.max)"
        icon="angle-double-right"
        @click="esQuery(0, Math.min(Math.ceil(machinesCount / page.elements), page.max))"
      />
      <br>
      <rg-table
        :rows="machines"
        cols="id, slug, gitlabticket, title, status, hastechdata, firstliveat, meta"
      >
        <template #default="slotProps">
          <template v-for="row in slotProps.chunk">
            <tr
              :key="row.id"
              :class="{'highlight': highlight.includes(row.id)}"
            >
              <td
                v-for="(value, property) in row"
                :key="property"
              >
                <code
                  v-if="['id', 'gitlabticket'].includes(property)"
                  v-clipboard="value"
                  v-clipboard:success="clipboardSuccessHandler"
                >
                  {{ value }}
                </code>
                <code
                  v-else-if="'slug' === property"
                  v-clipboard="value"
                  v-clipboard:success="clipboardSuccessHandler"
                  v-html="row.meta.highlight"
                />
                <code
                  v-else-if="'title' === property"
                  v-html="row.title"
                />
                <span v-else-if="'firstliveat' === property">{{ value ? new Date(value).toLocaleString() : '' }}</span>
                <span
                  v-else-if="'hastechdata' === property"
                  :style="!value ? 'opacity:.2' : ''"
                >
                  <fa
                    class="fa-fw"
                    :icon="value ? 'check' : 'times'"
                  />
                </span>
                <span v-else-if="'meta' === property">
                  <span
                    v-if="!row.meta.hasFamily"
                    title="… and ready to mingle?"
                  >Single</span>
                  <rg-button
                    v-else
                    :type="{'is-primary': row.meta.isParent}"
                    :label="row.meta.isParent ? 'Parent' : 'Child'"
                    @click="openModal(row)"
                  />
                </span>
                <span v-else>{{ value }}</span>
              </td>
              <td>
                <div class="flex-right">
                  <router-link :to="'/machines/'+ row.id+'/tech'">
                    <rg-button
                      label="Öffnen"
                      type="is-primary"
                    />
                  </router-link>
                  <dropdown
                    style="margin-left: 5px;"
                    :width="225"
                  >
                    <template #trigger>
                      <rg-button icon="chevron-down" />
                    </template>
                    <router-link :to="'/machines/'+ row.id">
                      <rg-button
                        icon-left="tractor"
                        label="Übersicht"
                      />
                    </router-link>
                    <router-link :to="'/machines/'+ row.id+'/type'">
                      <rg-button
                        icon-left="code-branch"
                        label="Typenstruktur"
                      />
                    </router-link>
                    <router-link :to="'/machines/'+ row.id+'/tech'">
                      <rg-button
                        icon-left="server"
                        label="Technische Daten"
                      />
                    </router-link>
                    <router-link :to="'/machines/'+ row.id+'/plan'">
                      <rg-button
                        icon-left="clipboard-check"
                        label="Wartungsplan"
                      />
                    </router-link>
                    <hr>
                    <a
                      :href="'https://rep.guide/de/machine/'+row.slug"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <rg-button
                        icon-left="eye"
                        label="Rep.Guide Ansicht"
                        icon-right="external-link-alt"
                      />
                    </a>
                    <a
                      v-if="row.gitlabticket"
                      :href="issues+row.gitlabticket"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <rg-button
                        icon-left="tag"
                        :label="'GitLab Ticket#'+row.gitlabticket"
                        icon-right="external-link-alt"
                      />
                    </a>
                  </dropdown>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </rg-table>
    </main>
    <transition name="opacity">
      <section
        v-if="modal.open"
        :class="[$style.modal, 'card']"
      >
        <h2>Übersicht der Vererbung</h2><br>
        <div
          @mouseenter="setHighlight(modal.data.parent.id)"
          @mouseleave="setHighlight()"
        >
          <machine-compact
            parent
            search
            :active="modal.data.id === modal.data.parent"
            :machine="modal.data.parent"
            @search="modal.open = false; search = $event.toString(); esQuery(0, 1)"
          />
        </div>
        <div
          v-for="machine in modal.data.children"
          :key="machine.id"
          style="display: grid; grid-template-columns: 30px 1fr"
          @mouseenter="setHighlight(machine.id)"
          @mouseleave="setHighlight()"
        >
          <div style="display: inline-flex; align-items: center; opacity: .5">
            <fa
              icon="level-up-alt"
              class="fa-fw fa-lg fa-rotate-90"
            />
          </div>
          <machine-compact
            search
            :machine="machine"
            @search="modal.open = false; search = $event.toString(); esQuery(0, 1)"
          />
        </div>
        <div style="text-align:right">
          <br>
          <rg-button
            label="Schließen"
            @click="modal.open = false"
          />
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import machine_compact from '@/components/elements/rg-machine-compact'
import dropdown from '@/components/elements/rg-dropdown'
import { issues, es } from '@/helper.js'
import superagent from 'superagent'
import Vue from 'vue'
import rgButton from '@/components/elements/rg-button.vue';

export default {
  name: "Typestructure",
  components: {
    'machine-compact': machine_compact, dropdown, rgButton,
    'rgTable': () => import('@/components/elements/Table.vue')
  },
  props: {},

  setup() {
    let search = ref('')
    let slugsearch = ref('')
    let highlight = ref([])
    let modal = ref({
      open: false,
      data: {},
    })
    let machines = ref([])
    let machinesCount = ref(0)
    let showState = ref(false)
    let debounce = null
    let page = ref({
      current: 1,
      elements: 25,
      max: 50,
    })

    const esQuery = (timeout, newPage = 1) => {
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        page.value.current = newPage
        const SEARCH = search.value.trim().toLowerCase()
        const SLUGSEARCH = slugsearch.value.trim().toLowerCase()

        let query = { match_all: {} }
        if (SLUGSEARCH) {
          query = {
            bool: {
              must: [
                { match_phrase: { 'slug': SLUGSEARCH } },
              ]
            }
          }
        } else if (SEARCH) {
          query = {
            bool: {
              minimum_should_match: 1,
              should: [
                { match: { 'slug': { query: SEARCH, fuzziness: "auto" } } },
                { match: { title: { query: SEARCH, fuzziness: "auto" } } },
              ]
            }
          }
          if (/^\d+$/.test(SEARCH)) {
            query.bool.should = [{ match: { '_routing': SEARCH } }]
          }
        }

        superagent.post(es + '/repguide_machines/_search?track_total_hits=true', {
          query,
          sort: [(SEARCH ? '_score' : { id: { order: "desc" } })],
          highlight: { fields: { slug: {}, title: {} } },
          from: (page.value.current - 1) * page.value.elements,
          size: page.value.elements,
        }).set("Authorization", "ApiKey SHRMYXBYc0I2YjNKWVpPcjlXVG06YXdOcEpTQ0JTYUMyRktKajdOZGxmQQ==")
          .then((res) => {
            machines.value = res.body.hits.hits.map((machine) => {
              const tempMech = { ...machine._source }
              tempMech.title = machine.highlight?.title?.[0] || tempMech.title
              tempMech.id = machine.highlight?.id?.[0] || tempMech.id
              tempMech.meta = {
                hasFamily: tempMech.parentid || tempMech.isparent,
                isParent: tempMech.isparent,
                parentid: tempMech.parentid,
                highlight: machine.highlight?.slug?.[0] || tempMech.slug
              }
              return tempMech
            })

            machinesCount.value = res.body.hits.total.value
          })
      }, timeout)
    }

    esQuery(0)

    window.addEventListener('keypress', e => {
      if (e.keyCode === 27) { modal.value.open = false }
    })

    function setHighlight(...val) { highlight.value = val }

    function openModal(row) {
      console.log(row.id);
      superagent.post(es + '/repguide_machines/_search', {
        query: { terms: { '_routing': [row.meta.parentid || row.id] } },
        size: 1000
      }).set("Authorization", "ApiKey SHRMYXBYc0I2YjNKWVpPcjlXVG06YXdOcEpTQ0JTYUMyRktKajdOZGxmQQ==")
        .then((res) => {
          let parent, children, mechs = res.body.hits?.hits?.map(h => h._source)
          parent = mechs.find(m => m.isparent)
          children = mechs.filter(m => !m.isparent)
          modal.value.data = children.length ? { parent, children, id: row.id } : { single: true, id: row.id }
          modal.value.open = true
        })
    }

    function clipboardSuccessHandler () {
      Vue.$toast.open({ message: 'Erfolgreich kopiert', icon: 'clipboard-check', });
    }

    return {
      search,
      slugsearch,
      esQuery,
      modal,
      issues,
      page,
      openModal,
      machines,
      machinesCount,
      highlight,
      setHighlight,
      showState,
      clipboardSuccessHandler
    }
  },
}
</script>

<style>
tr.has-link td { cursor: pointer!important }
tr.highlight { background: var(--black-2)!important }
</style>

<style lang="scss" module>
.modal {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999999999;
  width: 700px;
  border-radius: 16px;
  padding: 40px 30px;
  max-height: 800px;
  overflow-y: auto;
}
.is_medium {
  // font-size: 1.5em!important;
  // flex: 1;
}
input.is_medium { margin-right: 1em; }
</style>
