<template>
  <div>
    <navigation />
    <main>
      <h1>Preismodell {{ data.description || "lädt…" }}</h1>
      <pre>{{ data }}</pre>
    </main>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import superagent from "superagent";
import Vue from "vue";
import { altBackendUrl } from "../../constants";

export default {
  name: "PricingDetail",
  components: {
    // 'rg-widget': () => import('@/components/elements/rg-widget.vue'),
  },
  props: {},
  setup(props, context) {
    let data = ref([]);

    const gqQueryPricing = `
    query Query($pricingId: Int!) {
      Pricing(pricingId: $pricingId) {
        id
        tier
        description
        isfreeplan
        createdat
        updatedat
        isactive
        contract_key
        free_period_duration
        termination_period_duration
        contract_duration
        termination_period_special_duration
        invoice_interval
        invoice_currency
        invoice_amount
        isdefault
        item_number
        country_key
        title
        predecessor
      }
    }
    `;
    superagent
      .post(altBackendUrl)
      .send({
        query: gqQueryPricing,
        variables: { pricingId: parseInt(context.root.$route.params.id) },
      })
      .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
      .then((res) => {
        data.value = res?.body?.data?.Pricing;
      })
      .catch((error) => {
        if (error.status === 426) {
          Vue.$toast.open({
            message: "Sitzung abgelaufen. Bitte neu anmelden",
            icon: "exclamation",
            type: "error",
          });
          context.root.$store.dispatch("logout").then(() => {
            context.root.$router.push("/login");
          });
        }
      });

    return { data };
  },
};
</script>

<style lang="scss" module>
.detail {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-flow: dense;
  grid-auto-columns: 1fr 1fr;
}
</style>
