<template>
  <section v-if="matching.loading">
    <p>LOADING…</p>
  </section>
  <section v-else-if="!matching.data || !matching.data.id">
    Dieser Maschinentyp kann aktuell nicht verknüpft werden.
  </section>
  <section v-else>
    <div
      v-if="matching.data.status !== 'new'"
      class="rg-grid"
      :style="{ marginBottom: '50px' }"
    >
      <div class="w12 m6 l6">
        <h2>Aktuelle Verknüpfung</h2>
        <br />
        <div class="card" style="border-radius: 10px; padding: 20px">
          <div class="level">
            <div class="level-left">
              <h3>{{ getStatus(matching.data.status) }}</h3>
            </div>
            <div class="level-right">
              <rg-button
                label="Verknüpfung löschen"
                class="is-danger"
                @click="unmatch"
              />
            </div>
          </div>
          <table class="rg-table">
            <tr v-if="matching.data.app_version">
              <td>Jaltest Version</td>
              <td>
                <strong
                  >Jaltest v{{ matching.data.app_version }} ({{
                    matching.data.language
                  }})</strong
                >
              </td>
            </tr>
            <tr v-if="matching.data.createdat !== matching.data.updatedat">
              <td>Zuletzt bearbeitet</td>
              <td>
                <strong>{{
                  new Date(matching.data.updatedat).toLocaleDateString()
                }}</strong>
                um
                <strong>{{
                  new Date(matching.data.updatedat).toLocaleTimeString()
                }}</strong>
              </td>
            </tr>
            <tr v-if="matching.data.matchedat">
              <td>Verknüpfung erstellt</td>
              <td>
                <strong>{{
                  new Date(matching.data.matchedat).toLocaleDateString()
                }}</strong>
                um
                <strong>{{
                  new Date(matching.data.matchedat).toLocaleTimeString()
                }}</strong
                ><br />
                <span
                  >von Benutzer mit ID
                  <strong>{{ matching.data.matchedby }}</strong></span
                >
              </td>
            </tr>
            <tr>
              <td>Verknüpfung bestätigt</td>
              <td v-if="matching.data.approvedat">
                <strong>{{
                  new Date(matching.data.approvedat).toLocaleDateString()
                }}</strong>
                um
                <strong>{{
                  new Date(matching.data.approvedat).toLocaleTimeString()
                }}</strong
                ><br />
                <span
                  >von Benutzer mit ID
                  <strong>{{ matching.data.approvedby }}</strong></span
                >
              </td>
              <td v-else style="color: var(--black-5)">
                Verknüpfung wurde noch nicht bestätigt
              </td>
            </tr>
            <tr>
              <td>Fahrzeugtypen</td>
              <td
                v-if="matching.data.vehicle_type_id"
                :class="{ 'is-blurred': approving }"
              >
                <strong>{{ matching.data.vehicle_type_title }}</strong>
                <br />
                <code>{{ matching.data.vehicle_type_id }}</code>
              </td>
              <td v-else style="color: var(--black-5)">
                Es wurde kein Fahrzeugtyp verknüpft
              </td>
            </tr>
            <tr>
              <td>Hersteller</td>
              <td
                v-if="matching.data.brand_id"
                :class="{ 'is-blurred': approving }"
              >
                <strong>{{ matching.data.brand_title }}</strong>
                <br />
                <code>{{ matching.data.brand_id }}</code>
              </td>
              <td v-else style="color: var(--black-5)">
                Es wurde kein Hersteller verknüpft
              </td>
            </tr>
            <tr>
              <td>Serie</td>
              <td
                v-if="matching.data.model_id"
                :class="{ 'is-blurred': approving }"
              >
                <strong>{{ matching.data.model_title }}</strong>
                <br />
                <code>{{ matching.data.model_id }}</code>
              </td>
              <td v-else style="color: var(--black-5)">
                Es wurde keine Serie verknüpft
              </td>
            </tr>
            <tr>
              <td>Motor</td>
              <td
                v-if="matching.data.engine_id"
                :class="{ 'is-blurred': approving }"
              >
                <strong>{{ matching.data.engine_title }}</strong>
                <br />
                <code>{{ matching.data.engine_id }}</code>
              </td>
              <td v-else style="color: var(--black-5)">
                Es wurde kein Motor verknüpft
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="w12 m6 l6">
        <h2>Kommentar</h2>
        <br />
        <section class="card" style="border-radius: 10px; padding: 20px">
          <pre v-if="matching.data.comment">{{ matching.data.comment }}</pre>
          <p v-else style="color: var(--black-5)">Kein Kommentar</p>
        </section>
        <br />
        <h2>Matching configuration</h2>
        <br />
        <section class="card" style="border-radius: 10px; padding: 20px">
          <span>coming soon</span>
        </section>
      </div>
    </div>

    <div style="text-align: center">
      <rg-button
        v-if="matching.data.status === 'matched' && !approving"
        size="large"
        label="Verknüpfung bestätigen"
        class="is-primary is-large"
        style="margin: 50px 0 10px; margin-right: 10px"
        @click="startApproving"
      />
      <rg-button
        v-if="matching.data.status !== 'new' && !editable"
        size="large"
        label="Verknüpfung bearbeiten..."
        :class="[
          'is-large',
          {
            'is-primary': !['matched', 'approved'].includes(
              matching.data.status
            ),
          },
        ]"
        style="margin: 50px 0 10px"
        @click="startEditing"
      />
    </div>

    <div v-if="approving || editable || matching.data.status === 'new'">
      <h2>Jaltest Matching</h2>
      <br />
      <div
        :class="['card', { 'has-danger-glow': approving }]"
        style="border-radius: 10px; padding: 20px; position: relative"
      >
        <div v-if="approving" class="approve-label">
          Verknüpfungsbestätigung –
          <a href="#" @click.prevent="abortApproving">Abbrechen</a>
        </div>
        <div :class="['its-a-match', { animate: its_a_match }]">
          <canvas id="its-a-match-canvas" />
          <span>It's a Match!</span>
        </div>
        <div class="rg-grid" style="margin-top: 20px">
          <div class="w12 l4">
            <h2>Jaltest durchsuchen</h2>
            Jaltest Version {{ jaltestVersion }} • Sprache
            <select
              v-model="language.selected"
              disabled
              class="rg-select"
              style="display: inline; padding: 8px 12px"
            >
              <option
                v-for="option in language.options"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
            <br /><br />
            <div class="form">
              <span class="level">
                <strong>Typ der Maschine</strong>
                <a
                  :class="{ 'is-loading': loading }"
                  @click="
                    getBrands(() => {
                      type.selected = jal_type(machine.type);
                    })
                  "
                >
                  "{{ machine.type }}" übernehmen
                </a>
              </span>
              <select
                v-model="type.selected"
                :disabled="loading || !type.options.length"
                class="rg-select"
                @change="getBrands()"
              >
                <option
                  v-for="option in type.options"
                  :key="option.id"
                  :value="option"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
            <br />

            <div class="form">
              <span class="level">
                <strong>Hersteller der Maschine</strong>
                <a
                  :class="{ 'is-loading': loading }"
                  @click="
                    getSeries(() => {
                      brand.selected = jal_brand(machine.manufacturer);
                    })
                  "
                >
                  "{{ machine.manufacturer }}" übernehmen
                </a>
              </span>
              <select
                v-model="brand.selected"
                :disabled="loading || !brand.options.length"
                class="rg-select"
                @change="getSeries()"
              >
                <option
                  v-for="option in brand.options"
                  :key="option.id"
                  :value="option"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
            <br />
            <div v-if="!loading && series.pagination.TotalCount > 0">
              <p class="level">
                <strong> Bitte eine Serie wählen. </strong>
                <strong> {{ series.pagination.TotalCount }} Ergebnisse </strong>
              </p>
              <span class="level">
                <rg-button
                  label="vorherige Seite"
                  :disabled="!series.pagination.HasPrevious"
                  @click="previousPage()"
                />
                <rg-button
                  label="nächste Seite"
                  :disabled="!series.pagination.HasNext"
                  @click="nextPage()"
                />
              </span>
              <p>
                Seite {{ series.pagination.CurrentPage }} von
                {{ series.pagination.TotalPages }}
              </p>
              <div class="form">
                <table class="table">
                  <tr>
                    <th>Serie</th>
                    <th>Familie</th>
                    <th>Verfügbare Inhalte</th>
                    <th>Versionshinweis</th>
                  </tr>
                  <tr
                    v-for="row in series.options"
                    :key="row.id"
                    :style="
                      row.id === series.selected.id ? 'color:#1575f7;' : ''
                    "
                    @click="getEngines(row.id, row.name, row.family)"
                  >
                    <td>
                      {{ row.name }}
                    </td>
                    <td>
                      {{ row.family }}
                    </td>
                    <td>
                      <code v-for="section in row.sections" :key="section">
                        {{ section }}
                      </code>
                    </td>
                    <td>
                      {{ row.createdOrUpdated ? "Aktualisiert" : "" }}
                    </td>
                  </tr>
                </table>
              </div>
              <span class="level">
                <rg-button
                  label="vorherige Seite"
                  :disabled="!series.pagination.HasPrevious"
                  @click="previousPage()"
                />
                <rg-button
                  label="nächste Seite"
                  :disabled="!series.pagination.HasNext"
                  @click="nextPage()"
                />
              </span>
            </div>
            <div v-else>
              <strong v-if="series.pagination.TotalCount > 0">
                Lade Serien... bitte warten...
              </strong>
              <strong v-else> Keine Daten gefunden </strong>
            </div>
            <hr class="hr-text" data-content="Weitere Optionen" />
            <rg-button
              label="Nachprüfung beantragen"
              :class="[
                'is-block',
                { 'is-danger': matching.data.vehicle_type_id },
              ]"
              @click="secondlevel"
            />
            <br />
            <rg-button
              label="Maschine kann nicht zugeordnet werden"
              :class="[
                'is-block',
                { 'is-danger': matching.data.vehicle_type_id },
              ]"
              @click="notfound()"
            />
            <br />
            <rg-button
              label="Maschine evtl. später in Jaltest"
              :class="[
                'is-block',
                { 'is-danger': matching.data.vehicle_type_id },
              ]"
              @click="notfound('upcoming')"
            />
            <br />
            Redaktionelle Bemerkung
            <textarea
              v-model="matching.data.comment"
              :style="{ width: '100%', margin: '10px 0' }"
              rows="6"
            />
            <div style="text-align: right">
              <rg-button
                label="Kommentar speichern"
                type="is-primary"
                @click="saveComment"
              />
            </div>
          </div>

          <div class="w12 l8">
            <h3>Details: {{ machine.title }}</h3>
            <table class="rg-table condensed">
              <tr>
                <th>Serie</th>
                <td v-if="machine.series">
                  {{ machine.series }}
                </td>
                <td v-else style="color: var(--black-5)">unbekannt</td>
              </tr>
              <tr>
                <th>Motor</th>
                <td v-if="machine.engine">
                  {{ machine.engine }}
                </td>
                <td v-else style="color: var(--black-5)">unbekannt</td>
              </tr>
              <tr>
                <th>Abgasnorm</th>
                <td v-if="machine.emission">
                  {{ machine.emission }}
                </td>
                <td v-else style="color: var(--black-5)">unbekannt</td>
              </tr>
              <tr>
                <td>Getriebe</td>
                <td v-if="machine.transmission">
                  {{ machine.transmission }}
                </td>
                <td v-else style="color: var(--black-5)">unbekannt</td>
              </tr>
            </table>
            <br /><br />
            <h2>Suchergebnisse</h2>
            <br />
            <div
              v-if="result && result.name"
              style="border: 1px solid var(--black-5); padding: 20px"
            >
              <div class="level">
                <div class="level-left">
                  <h3>
                    <strong>{{ result.name }}</strong>
                    <span v-if="result.startYear || result.endYear">
                      (Baujahr {{ result.startYear }} bis {{ result.endYear }})
                    </span>
                    <br />
                    <small>aus der Familie "{{ result.family }}"</small>
                  </h3>
                </div>
                <div class="level-right">
                  <rg-button
                    v-if="approving"
                    label="Verknüpfung bestätigen"
                    @click="matchApprovement()"
                  />
                  <span
                    v-else-if="
                      !matching.data.engine_id &&
                      matching.data.model_id === result.id
                    "
                  >
                    <h3 v-if="matching.data.status === 'approved'">
                      <fa icon="check" class="fa-fw" />&nbsp;Bestätigte
                      Verknüpfung
                    </h3>
                    <h3 v-else>Aktuell verknüpft</h3>
                  </span>
                  <rg-button
                    v-else
                    :disabled="matching.data.status !== 'new' && !editable"
                    label="Ohne Motor verknüpfen"
                    @click="connect()"
                  />
                </div>
              </div>
              <div v-if="result.engines.length">
                <br />
                <h3>
                  <span style="color: var(--black-7)"
                    >Verbaute Motoren vom Typ</span
                  >
                  {{ result.engine }}
                </h3>
                <table class="rg-table">
                  <tr v-for="(res, index) in result.engines" :key="index">
                    <td>
                      <h3 style="margin: 0">
                        <fa
                          icon="level-up-alt"
                          class="fa-rotate-90"
                          :style="{ margin: '0 10px' }"
                        />
                        <strong>{{ res.name }}</strong>
                        <small v-if="result.startYear || result.endYear">
                          (Baujahr {{ res.startYear }} bis {{ res.endYear }})
                        </small>
                      </h3>
                    </td>
                    <td style="text-align: right">
                      <rg-button
                        v-if="approving"
                        class="is-primary"
                        label="Verknüpfung bestätigen"
                        @click="matchApprovement(res)"
                      />
                      <span v-else-if="matching.data.engine_id === res.id">
                        <h3 v-if="matching.data.status === 'approved'">
                          <fa icon="check" class="fa-fw" />&nbsp;Bestätigte
                          Verknüpfung
                        </h3>
                        <h3 v-else>Aktuell verknüpft</h3>
                      </span>
                      <rg-button
                        v-else
                        :disabled="matching.data.status !== 'new' && !editable"
                        class="is-primary"
                        label="Modell und Motor verknüpfen"
                        @click="connect(res)"
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <div
                v-else
                style="
                  color: var(--black-7);
                  text-align: center;
                  padding: 50px 0;
                "
              >
                Keine Motoren zu dieser Serie gefunden
                <br /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { brands } from "@/jaltest_cache";
import { ref, watchEffect } from "@vue/composition-api";
import { generateStatus } from "@/helper";
import superagent from "superagent";
import { altBackendUrl } from "@/constants.js";
import Vue from "vue";
const confetti = require("canvas-confetti");

export default {
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    const userid = context.root.$store.state.user.id;
    const machine = ref({});

    const its_a_match = ref(false);

    const matching = ref({ loading: true, data: {} });

    const existing = ref({ loading: true, found: false, matching: {} });
    const protocol = ref({
      showAll: false,
      loading: true,
      data: [],
    });

    const editable = ref(false);
    const approving = ref(false);

    const loading = ref(false);
    const result = ref(null);
    const language = ref({ options: ["de", "en"], selected: "de" });
    getTypes();
    getJaltestCurrentVersion();
    // const types = ref({});
    const type = ref({
      el: null,
      options: [],
      selected: {
        id: "7506d6f3-2980-45e8-bff8-9e34aafef294",
        name: "TRAKTOREN",
      },
    });
    const emptyTypes = ref([
      "cbb64c2e-d811-41ed-8c24-e0c137874202",
      "dfe3e21c-4ee9-492b-b103-fa88b3145494",
      "63aeac71-2862-4318-a97d-123d232e394f",
    ]); //MHE, OHW, AGV
    const brand = ref({ el: null, options: brands, selected: {} });
    const series = ref({
      el: null,
      options: [],
      selected: {},
      pagination: {
        CurrentPage: 1,
        HasNext: false,
        HasPrevious: false,
        PageSize: 10,
        TotalCount: 0,
        TotalPages: 0,
      },
    });
    const engines = ref({ el: null, options: [], selected: {} });
    const seriesElement = ref(null);
    const jaltestVersion = ref("");

    watchEffect(() => {
      machine.value = props.data;
    });

    const gqQueryJaltestMatching = `
    query JaltestModels($machineid: Int) {
      JaltestModels(machineid: $machineid) {
        id
        machineid
        model_id
        engine_id
        vehicle_type_id
        brand_id
        model_title
        engine_title
        vehicle_type_title
        brand_title
        app_version
        options
        language
        createdat
        updatedat
        status
        matchedby
        approvedby
        assignedto
        matchedat
        approvedat
        comment
      }
    }
    `;

    superagent
      .post(altBackendUrl)
      .send({
        query: gqQueryJaltestMatching,
        variables: { machineid: machine.value.id },
      })
      .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
      .then(({ body: MATCH }) => {
        matching.value.data = MATCH.data.JaltestModels[0];
        matching.value.loading = false;
        brand.value.selected = getRepguideManufacturer();
        !brand.value.selected?.id ? getBrands() : getSeries();
        editable.value = matching.value.data?.status === "new";
      })
      .catch((er) => {
        Vue.$toast.open(generateStatus(er.status));
      })
      .finally(() => {
        matching.value.loading = false;
      });

    function getStatus(status) {
      switch (status) {
        case "new":
          return "Verknüpfung erstellen";
        case "matched":
          return "Warte auf Bestätigung";
        case "approved":
          return "Verknüpfung bestätigt";
        case "secondlevel":
          return "Nachprüfung beantragt";
        case "secondlevel-approval":
          return "Nachprüfung einer Bestätigung";
        case "notfound":
          return "Nicht verknüpfbar";
        case "upcoming":
          return "Warte auf Jaltest Update";
        default:
          return "Unbekannt";
      }
    }

    function clipboardSuccessHandler() {
      Vue.$toast.open({
        message: "Erfolgreich kopiert",
        icon: "clipboard-check",
      });
    }

    function getRepguideManufacturer() {
      return (
        brands.find(
          (brand) =>
            brand.name.toLowerCase() ===
            machine.value?.manufacturer?.toLowerCase()
        ) || {}
      );
    }

    function getTypes() {
      loading.value = true;
      const gqVehicleTypesQuery = `
      query Query {
        JaltestCoverageTypeIds
      }
      `;

      superagent
        .post(altBackendUrl)
        .send({
          query: gqVehicleTypesQuery,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          type.value.options = res?.body?.data?.JaltestCoverageTypeIds?.sort(
            (a, b) => a.name.localeCompare(b.name)
          )
            .map((option) => ({
              id: option.id,
              name: option.name,
            }))
            .filter((option) => !emptyTypes.value.includes(option.id));
        })
        .catch((er) => {
          Vue.$toast.open(generateStatus(er.status));
          console.trace(er);
        })
        .finally(() => {
          loading.value = false;
        });
    }

    function getBrands() {
      loading.value = true;

      const gqVehicleBrands = `
      query Query($vehicleTypeId: String) {
        JaltestCoverageBrands(vehicleTypeId: $vehicleTypeId)
      }
      `;

      superagent
        .post(altBackendUrl)
        .send({
          query: gqVehicleBrands,
          variables: { vehicleTypeId: type.value.selected.id },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          brand.value.options = res?.body?.data?.JaltestCoverageBrands?.sort(
            (a, b) => a.name.localeCompare(b.name)
          ).map((option) => ({
            id: option.id,
            name: option.name,
          }));
        })
        .catch((er) => {
          Vue.$toast.open(generateStatus(er.status));
          console.trace(er);
        })
        .finally(() => {
          loading.value = false;
        });
    }

    function getJaltestCurrentVersion() {
      loading.value = true;
      const gqJaltestVersion = `
      query Query {
        JaltestCurrentVersion
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqJaltestVersion,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          jaltestVersion.value = res?.body?.data?.JaltestCurrentVersion;
        })
        .catch((er) => {
          Vue.$toast.open(generateStatus(er.status));
          console.trace(er);
        })
        .finally(() => {
          loading.value = false;
        });
    }

    function getSeries() {
      if (brand.value.selected?.id) {
        loading.value = true;
        const gqVehicleSeries = `
        query Query($brandId: String, $pageSize: Int!, $pageNumber: Int!) {
          JaltestCoverageModels(BrandId: $brandId, PageSize: $pageSize, PageNumber: $pageNumber)
        }
        `;
        superagent
          .post(altBackendUrl)
          .send({
            query: gqVehicleSeries,
            variables: {
              brandId: brand.value.selected.id,
              pageSize: series.value.pagination.PageSize || 10,
              pageNumber: series.value.pagination.CurrentPage || 1,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            series.value.pagination =
              res?.body?.data?.JaltestCoverageModels?.pagination;
            series.value.options =
              res?.body?.data?.JaltestCoverageModels?.body.sort((a, b) =>
                a.name.localeCompare(b.name)
              );
            if (editable.value) {
              startEditing();
            }
            loading.value = false;
          })
          .catch((er) => {
            Vue.$toast.open(generateStatus(er.status));
            console.trace(er);
            loading.value = false;
          });
      }
    }

    function getEngines(modelId, modelName = "", modelFamily = "") {
      if (modelId) {
        series.value.selected.id = modelId;
        series.value.selected.name = modelName;
        loading.value = true;

        const gqVehicleEngines = `
        query Query($modelId: String) {
          JaltestCoverageEngineTypes(ModelId: $modelId)
        }
        `;
        superagent
          .post(altBackendUrl)
          .send({
            query: gqVehicleEngines,
            variables: { modelId: modelId },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            result.value = {
              id: modelId,
              name: modelName,
              family: modelFamily,
              engines: res?.body?.data?.JaltestCoverageEngineTypes?.sort(
                (a, b) => a.name.localeCompare(b.name)
              ),
            };
          })
          .catch((er) => {
            Vue.$toast.open(generateStatus(er.status));
            console.trace(er);
          })
          .finally(() => {
            loading.value = false;
          });
      }
    }

    function jal_type(rg_type) {
      if (type.value.options.length) {
        switch (rg_type.toLowerCase()) {
          case "traktor":
            return type.value.options.find(
              (op) => op.id === "7506d6f3-2980-45e8-bff8-9e34aafef294"
            );

          default:
            // AGV
            return type.value.options.find(
              (op) => op.id === "7506d6f3-2980-45e8-bff8-9e34aafef294"
            );
        }
      } else {
        return null;
      }
    }

    function jal_brand(rg_brand) {
      return (
        brand.value.options.find(
          (op) => op.name.toLowerCase() === rg_brand.toLowerCase()
        ) || null
      );
    }

    function saveComment() {
      const payload = {
        jaltestModelId: parseInt(matching.value.data.id),
        comment: matching.value?.data?.comment?.trim() || null,
      };

      const gqUpdateCommentMutation = `
        mutation Mutation($jaltestModelId: Int, $comment: String) {
          JaltestModel(id: $jaltestModelId, comment: $comment) {
            id
          }
        }
        `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqUpdateCommentMutation,
          variables: {
            jaltestModelId: payload.jaltestModelId,
            comment: payload.comment,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(() => {
          Vue.$toast.open({
            message: "Kommentar erfolgreich gespeichert.",
            icon: "check",
          });
          Object.assign(matching.value.data, payload);
        })
        .catch((er) => {
          Vue.$toast.open(generateStatus(er.status));
          console.trace(er);
        });
      console.table(payload);
    }

    function startEditing() {
      editable.value = true;
      if (
        series.value.options.find(
          (model) => model.id === matching.value.data.model_id
        ) !== undefined
      ) {
        series.value.selected = series.value.options.find(
          (model) => model.id === matching.value.data.model_id
        );
        series.value.selected?.id
          ? getEngines()
          : Vue.nextTick(() => {
              seriesElement.value?.focus();
            });
      }
    }

    function startApproving() {
      approving.value = true;
      editable.value = true;
      series.value.selected = {};
      result.value = null;
      Vue.nextTick(() => {
        seriesElement.value.focus();
      });
    }

    function matchApprovement(engine) {
      const gqApproveMatchMutation = `
        mutation Mutation($jaltestModelId: Int, $status: String, $approvedby: Int, $approvedat: DateTime, $comment: String) {
          JaltestModel(id: $jaltestModelId, status: $status, approvedby: $approvedby, approvedat: $approvedat, comment: $comment) {
            id
          }
        }
        `;

      if (
        (matching.value.data.vehicle_type_id === type.value.selected.id &&
          matching.value.data.brand_id === brand.value.selected.id &&
          matching.value.data.model_id === series.value.selected.id &&
          !matching.value.data.engine_id) ||
        matching.value.data.engine_id === engine?.id
      ) {
        const payload = {
          jaltestModelId: parseInt(matching.value.data.id),
          status: "approved",
          approvedby: parseInt(userid),
          approvedat: new Date(),
          comment: matching?.value?.data?.comment?.trim() || null,
        };

        superagent
          .post(altBackendUrl)
          .send({
            query: gqApproveMatchMutation,
            variables: {
              jaltestModelId: payload.jaltestModelId,
              status: payload.status,
              approvedby: payload.approvedby,
              approvedat: payload.approvedat,
              comment: payload.comment,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            try {
              its_a_match.value = true;
              var cv = document.getElementById("its-a-match-canvas");
              var conf = confetti.create(cv, { resize: true });
              conf({ particleCount: 100, spread: 75, origin: { y: 0.7 } });
              setTimeout(() => (its_a_match.value = false), 1250);
              // TODO: create jaltest tab
            } catch (e) {
              console.error("no confetti for you :(", e);
            }
            Vue.$toast.open({
              message: "Erfolgreich bestätigt.",
              icon: "check",
            });
            Object.assign(matching.value.data, payload);
            context.root.$store.dispatch("log", {
              channel: "jaltest",
              reference: parseInt(matching.value.data.id),
              action: "successfully validated a matching",
              response: res.status,
            });
          })
          .catch((er) => {
            Vue.$toast.open(generateStatus(er.status));
            console.trace(er);
          })
          .finally(() => {
            approving.value = false;
          });
      } else {
        approving.value = false;

        const payload = {
          jaltestModelId: parseInt(matching.value.data.id),
          status: "secondlevel-approval",
          approvedat: new Date(),
          approvedby: parseInt(userid),
          comment: matching?.value?.data?.comment?.trim() || null,
        };

        superagent
          .post(altBackendUrl)
          .send({
            query: gqApproveMatchMutation,
            variables: {
              jaltestModelId: payload.jaltestModelId,
              status: payload.status,
              approvedby: payload.approvedby,
              approvedat: payload.approvedat,
              comment: payload.comment,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            Vue.$toast.open({
              duration: 30000,
              message: "Die Verknüpfungen stimmen nicht überein",
              icon: "exclamation",
              type: "error",
            });
            Vue.$toast.open({
              duration: 30000,
              message:
                "Das Fahrzeug wurde automatisch zur Nachprüfung eingereicht",
              icon: "info",
            });
            Object.assign(matching.value.data, payload);

            const meta = {
              original: { ...matching.value.data },
              confirmed: {
                matchedby: userid,
                matchedat: new Date().toLocaleDateString(),
                vehicle_type_title: type.value.selected.name,
                vehicle_type_id: type.value.selected.id,
                brand_title: brand.value.selected.name,
                brand_id: brand.value.selected.id,
                model_title: series.value.selected.name,
                model_id: series.value.selected.id,
                engine_title: engine?.name,
                engine_id: engine?.id,
              },
            };
            // console.log(meta);
            // console.log("i wanna log stuff ok?");
            context.root.$store.dispatch("log", {
              channel: "jaltest",
              reference: parseInt(matching.value.data.id),
              action: "triggered a secondlevel approval",
              response: res.status,
              meta: JSON.stringify(meta),
            });
          })
          .catch((er) => {
            Vue.$toast.open(generateStatus(er.status));
            console.trace(er);
          });
      }
    }

    function abortApproving() {
      approving.value = false;
    }

    function notfound(status = "notfound") {
      const confirm =
        !matching.value.data.vehicle_type_id ||
        window.confirm(
          "Achtung: Diese Aktion ist setzt die Verknüpfung zurück\nWirklich forfahren?"
        );
      if (confirm) {
        const payload = {
          jaltestModelId: parseInt(matching.value.data.id),
          status,
          model_id: null,
          model_title: null,
          engine_id: null,
          engine_title: null,
          vehicle_type_id: null,
          vehicle_type_title: null,
          brand_id: null,
          brand_title: null,
          app_version: null,
          language: null,
          matchedat: null,
          matchedby: parseInt(userid),
          approvedat: null,
          approvedby: null,
          options: null,
          comment: matching?.value?.data?.comment?.trim() || null,
        };

        const gqNoMatchMutation = `
        mutation Mutation($jaltestModelId: Int, $status: String, $modelId: String, $modelTitle: String, $engineId: String,
        $engineTitle: String, $vehicleTypeId: String, $vehicleTypeTitle: String, $brandId: String, $brandTitle: String,
        $appVersion: String, $language: String, $matchedat: DateTime, $matchedby: Int, $approvedat: DateTime, $approvedby: Int,
        $options: JSON, $comment: String) {
          JaltestModel(id: $jaltestModelId, status: $status, model_id: $modelId, model_title: $modelTitle, engine_id: $engineId,
          engine_title: $engineTitle, vehicle_type_id: $vehicleTypeId, vehicle_type_title: $vehicleTypeTitle, brand_id: $brandId,
          brand_title: $brandTitle, app_version: $appVersion, language: $language, matchedat: $matchedat, matchedby: $matchedby,
          approvedat: $approvedat, approvedby: $approvedby, options: $options, comment: $comment) {
            id
          }
        }
        `;

        superagent
          .post(altBackendUrl)
          .send({
            query: gqNoMatchMutation,
            variables: {
              jaltestModelId: payload.jaltestModelId,
              status: payload.status,
              modelId: payload.model_id,
              modelTitle: payload.model_title,
              engineId: payload.engine_id,
              engineTitle: payload.engine_title,
              vehicleTypeId: payload.vehicle_type_id,
              vehicleTypeTitle: payload.vehicle_type_title,
              brandId: payload.brand_id,
              brandTitle: payload.brand_title,
              appVersion: payload.app_version,
              language: payload.language,
              matchedat: payload.matchedat,
              matchedby: payload.matchedby,
              approvedat: payload.approvedat,
              approvedby: payload.approvedby,
              options: payload.options,
              comment: payload.comment,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            Vue.$toast.open({
              message: "Erfolgreich gespeichert.",
              icon: "check",
            });
            Object.assign(matching.value.data, payload);
            context.root.$store.dispatch("log", {
              channel: "jaltest",
              reference: parseInt(matching.value.data.id),
              action: "set status to " + status,
              response: res.status,
            });
          })
          .catch((er) => {
            Vue.$toast.open(generateStatus(er.status));
            console.trace(er);
          });
      }
    }

    function secondlevel() {
      const confirm =
        !matching.value.data.vehicle_type_id ||
        window.confirm(
          "Achtung: Diese Aktion ist setzt die Verknüpfung zurück\nWirklich forfahren?"
        );
      if (confirm) {
        const payload = {
          jaltestModelId: parseInt(matching.value.data.id),
          status: "secondlevel",
          model_id: null,
          model_title: null,
          engine_id: null,
          engine_title: null,
          vehicle_type_id: null,
          vehicle_type_title: null,
          brand_id: null,
          brand_title: null,
          app_version: null,
          language: null,
          matchedat: null,
          matchedby: parseInt(userid),
          approvedat: null,
          approvedby: null,
          options: null,
          comment: matching?.value?.data?.comment?.trim() || null,
        };

        const gqSecondLevelMatchingMutation = `
        mutation Mutation($jaltestModelId: Int, $status: String, $modelId: String, $modelTitle: String, $engineId: String,
        $engineTitle: String, $vehicleTypeId: String, $vehicleTypeTitle: String, $brandId: String, $brandTitle: String,
        $appVersion: String, $language: String, $matchedat: DateTime, $matchedby: Int, $approvedat: DateTime, $approvedby: Int,
        $options: JSON, $comment: String) {
          JaltestModel(id: $jaltestModelId, status: $status, model_id: $modelId, model_title: $modelTitle, engine_id: $engineId,
          engine_title: $engineTitle, vehicle_type_id: $vehicleTypeId, vehicle_type_title: $vehicleTypeTitle, brand_id: $brandId,
          brand_title: $brandTitle, app_version: $appVersion, language: $language, matchedat: $matchedat, matchedby: $matchedby,
          approvedat: $approvedat, approvedby: $approvedby, options: $options, comment: $comment) {
            id
          }
        }
        `;

        superagent
          .post(altBackendUrl)
          .send({
            query: gqSecondLevelMatchingMutation,
            variables: {
              jaltestModelId: payload.jaltestModelId,
              status: payload.status,
              modelId: payload.model_id,
              modelTitle: payload.model_title,
              engineId: payload.engine_id,
              engineTitle: payload.engine_title,
              vehicleTypeId: payload.vehicle_type_id,
              vehicleTypeTitle: payload.vehicle_type_title,
              brandId: payload.brand_id,
              brandTitle: payload.brand_title,
              appVersion: payload.app_version,
              language: payload.language,
              matchedat: payload.matchedat,
              matchedby: payload.matchedby,
              approvedat: payload.approvedat,
              approvedby: payload.approvedby,
              options: payload.options,
              comment: payload.comment,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            Vue.$toast.open({
              message: "Erfolgreich gespeichert.",
              icon: "check",
            });
            Object.assign(matching.value.data, payload);
            context.root.$store.dispatch("log", {
              channel: "jaltest",
              reference: parseInt(matching.value.data.id),
              action: "set status to secondlevel",
              response: res.status,
            });
          })
          .catch((er) => {
            Vue.$toast.open(generateStatus(er.status));
            console.trace(er);
          });
      }
    }

    function unmatch() {
      const confirm =
        !matching.value.data.vehicle_type_id ||
        window.confirm(
          "Achtung: Diese Aktion ist irreversibel.\nVerknüpfung wirklich löschen?"
        );
      if (confirm) {
        const payload = {
          jaltestModelId: parseInt(matching.value.data.id),
          status: "new",
          model_id: null,
          model_title: null,
          engine_id: null,
          engine_title: null,
          vehicle_type_id: null,
          vehicle_type_title: null,
          brand_id: null,
          brand_title: null,
          app_version: null,
          language: null,
          matchedat: null,
          matchedby: null,
          approvedat: null,
          approvedby: null,
          options: null,
          comment: matching?.value?.data?.comment?.trim() || null,
        };

        const gqResetMatchMutation = `
        mutation Mutation($jaltestModelId: Int, $status: String, $modelId: String, $modelTitle: String, $engineId: String,
        $engineTitle: String, $vehicleTypeId: String, $vehicleTypeTitle: String, $brandId: String, $brandTitle: String,
        $appVersion: String, $language: String, $matchedat: DateTime, $matchedby: Int, $approvedat: DateTime, $approvedby: Int,
        $options: JSON, $comment: String) {
          JaltestModel(id: $jaltestModelId, status: $status, model_id: $modelId, model_title: $modelTitle, engine_id: $engineId,
          engine_title: $engineTitle, vehicle_type_id: $vehicleTypeId, vehicle_type_title: $vehicleTypeTitle, brand_id: $brandId,
          brand_title: $brandTitle, app_version: $appVersion, language: $language, matchedat: $matchedat, matchedby: $matchedby,
          approvedat: $approvedat, approvedby: $approvedby, options: $options, comment: $comment) {
            id
          }
        }
        `;
        superagent
          .post(altBackendUrl)
          .send({
            query: gqResetMatchMutation,
            variables: {
              jaltestModelId: payload.jaltestModelId,
              status: payload.status,
              modelId: payload.model_id,
              modelTitle: payload.model_title,
              engineId: payload.engine_id,
              engineTitle: payload.engine_title,
              vehicleTypeId: payload.vehicle_type_id,
              vehicleTypeTitle: payload.vehicle_type_title,
              brandId: payload.brand_id,
              brandTitle: payload.brand_title,
              appVersion: payload.app_version,
              language: payload.language,
              matchedat: payload.matchedat,
              matchedby: payload.matchedby,
              approvedat: payload.approvedat,
              approvedby: payload.approvedby,
              options: payload.options,
              comment: payload.comment,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            Vue.$toast.open({
              message: "Verknüpfung wurde gelöscht.",
              icon: "check",
            });
            Object.assign(matching.value.data, payload);
            context.root.$store.dispatch("log", {
              channel: "jaltest",
              reference: parseInt(matching.value.data.id),
              action: "deleted matching",
              response: res.status,
            });
          })
          .catch((er) => {
            Vue.$toast.open(generateStatus(er.status));
            console.trace(er);
          });
      }
    }

    function connect(engine = null) {
      if (editable.value) {
        const payload = {
          jaltestModelId: parseInt(matching.value.data.id),
          status: "matched",
          model_id: series.value.selected.id,
          model_title: series.value.selected.name,
          engine_id: engine ? engine.id : null,
          engine_title: engine ? engine.name : null,
          vehicle_type_id: type.value.selected.id,
          vehicle_type_title: type.value.selected.name,
          brand_id: brand.value.selected.id,
          brand_title: brand.value.selected.name,
          app_version: "23.1",
          language: language.value.selected,
          matchedat: new Date(),
          matchedby: parseInt(userid),
          approvedat: null,
          approvedby: null,
          options: null,
          comment: matching?.value?.data?.comment?.trim() || null,
        };

        const gqMatchMutation = `
        mutation Mutation($jaltestModelId: Int, $status: String, $modelId: String, $modelTitle: String, $engineId: String,
        $engineTitle: String, $vehicleTypeId: String, $vehicleTypeTitle: String, $brandId: String, $brandTitle: String,
        $appVersion: String, $language: String, $matchedat: DateTime, $matchedby: Int, $approvedat: DateTime, $approvedby: Int,
        $options: JSON, $comment: String) {
          JaltestModel(id: $jaltestModelId, status: $status, model_id: $modelId, model_title: $modelTitle, engine_id: $engineId,
          engine_title: $engineTitle, vehicle_type_id: $vehicleTypeId, vehicle_type_title: $vehicleTypeTitle, brand_id: $brandId,
          brand_title: $brandTitle, app_version: $appVersion, language: $language, matchedat: $matchedat, matchedby: $matchedby,
          approvedat: $approvedat, approvedby: $approvedby, options: $options, comment: $comment) {
            id
          }
        }
        `;

        superagent
          .post(altBackendUrl)
          .send({
            query: gqMatchMutation,
            variables: {
              jaltestModelId: payload.jaltestModelId,
              status: payload.status,
              modelId: payload.model_id,
              modelTitle: payload.model_title,
              engineId: payload.engine_id,
              engineTitle: payload.engine_title,
              vehicleTypeId: payload.vehicle_type_id,
              vehicleTypeTitle: payload.vehicle_type_title,
              brandId: payload.brand_id,
              brandTitle: payload.brand_title,
              appVersion: payload.app_version,
              language: payload.language,
              matchedat: payload.matchedat,
              matchedby: payload.matchedby,
              approvedat: payload.approvedat,
              approvedby: payload.approvedby,
              options: payload.options,
              comment: payload.comment,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            Vue.$toast.open({
              message: "Maschine erfolgreich verknüpft.",
              icon: "check",
            });
            Object.assign(matching.value.data, payload);
            context.root.$store.dispatch("log", {
              channel: "jaltest",
              reference: parseInt(matching.value.data.id),
              action: "matched machine",
              response: res.status,
            });
          })
          .catch((er) => {
            Vue.$toast.open(generateStatus(er.status));
            console.trace(er);
          });
      }
    }

    function nextPage() {
      if (!series.value.pagination.HasNext) return;
      series.value.pagination.CurrentPage =
        series.value.pagination.CurrentPage + 1;
      getSeries(false, "forwards");
    }

    function previousPage() {
      if (
        !series.value.pagination.HasPrevious ||
        series.value.pagination.CurrentPage === 1
      )
        return;
      series.value.pagination.CurrentPage =
        series.value.pagination.CurrentPage - 1;
      getSeries();
    }

    return {
      machine,
      getStatus,
      unmatch,
      matching,
      editable,
      notfound,
      secondlevel,
      startEditing,
      startApproving,
      matchApprovement,
      abortApproving,
      protocol,
      approving,
      userid,
      saveComment,
      existing,
      jal_type,
      jal_brand,
      getTypes,
      getBrands,
      getSeries,
      getEngines,
      jaltestVersion,
      language,
      type,
      brand,
      series,
      engines,
      loading,
      result,
      connect,
      its_a_match,
      seriesElement,
      clipboardSuccessHandler,
      nextPage,
      previousPage,
    };
  },
};
</script>

<style lang="scss">
a.is-loading {
  cursor: wait;
}
.hr-text {
  position: relative;
  border: 0;
  margin: 30px 0 20px;
  text-align: center;
  &:before {
    content: "";
    background: var(--black-2);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: var(--black-8);
    padding: 0 0.5em;
    background-color: var(--card-light);
  }
}
.approve-label {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  background: hsl(348, 100%, 61%);
  padding: 5px 10px;
  border-radius: 0 0 6px 6px;
  text-align: center;
  color: white;
  a {
    color: white;
    text-decoration-color: white;
  }
}
.is-blurred {
  filter: blur(5px) !important;
  opacity: 0.5 !important;
  user-select: none;
}
.its-a-match {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  #its-a-match-canvas {
    width: 100%;
    height: 100%;
  }

  background: transparent;
  transition: background 0.2s ease;
  span {
    font-size: 3em;
    font-weight: bold;
    letter-spacing: 1px;
    user-select: none;
    opacity: 0;
    color: var(--black-O);
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(50px);
    position: absolute;
    transition: all 0.5s cubic-bezier(0.31, 1.53, 0.3, 1);
  }

  &.animate {
    background: var(--white-5);
    span {
      transform: translateX(-50%) translateY(0px);
      opacity: 1 !important;
    }
  }
}
.log-difference td:not(.headline) {
  color: var(--danger) !important;
}
.protocol .is-blurred table * {
  color: var(--text-dark) !important;
}
.table {
  border-collapse: collapse;
  width: 100%;
  position: relative;

  tr:nth-child(even) {
    background: rgba(0, 0, 0, 0.05);
  }
  tr:not(:nth-child(1)):hover {
    background: rgba(0, 0, 0, 0.2);
  }

  th,
  td {
    border: 1px solid var(--primary-color);
    padding: 6px;
    height: 50px;
  }

  th {
    user-select: none;
    text-align: left;
  }
  tr em {
    background: #ff05;
    font-style: normal;
    font-weight: bold;
  }
}
</style>
