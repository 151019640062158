<template>
  <div>
    <navigation />
    <main>
      <h1><fa icon="dollar-sign" />&nbsp;Preismodelle</h1>
      <p>Alle Rep.Guide Preismodelle</p>
      <br />
      <rg-table
        :rows="data"
        cols="id, item_number, tier, description, invoice_amount, invoice_currency, isfreeplan, isactive"
      >
        <template #default="slotProps">
          <router-link
            v-for="row in slotProps.chunk"
            :key="row.id"
            :to="'/pricings/' + row.id"
            tag="tr"
          >
            <td v-for="(value, property) in row" :key="property">
              <span @click="(event) => event.stopPropagation()">{{
                value
              }}</span>
            </td>
          </router-link>
        </template>
      </rg-table>
    </main>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/composition-api";
import superagent from "superagent";
import Vue from "vue";
import { altBackendUrl } from "../../constants";

export default {
  name: "Pricingmodels",
  components: {
    rgTable: () => import("@/components/elements/Table.vue"),
  },
  props: {},
  setup(props, context) {
    let data = ref([]);
    onMounted(() => {});

    const gqQueryPricings = `
    query Query {
      Pricings(limit: 10000, skip: 0) {
        id
        tier
        description
        isfreeplan
        createdat
        updatedat
        isactive
        contract_key
        free_period_duration
        termination_period_duration
        contract_duration
        termination_period_special_duration
        invoice_interval
        invoice_currency
        invoice_amount
        isdefault
        item_number
        country_key
        title
        predecessor
      }
    }
    `;
    superagent
      .post(altBackendUrl)
      .send({
        query: gqQueryPricings,
      })
      .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
      .then((res) => {
        data.value = res?.body?.data?.Pricings?.sort((a, b) => {
          return a["id"] - b["id"];
        });
      })
      .catch((error) => {
        if (error.status === 426) {
          Vue.$toast.open({
            message: "Sitzung abgelaufen. Bitte neu anmelden",
            icon: "exclamation",
            type: "error",
          });
          context.root.$store.dispatch("logout").then(() => {
            context.root.$router.push("/login");
          });
        }
      });

    return { data };
  },
};
</script>
