<template>
  <div class="rg-grid">
    <div
      v-if="warnings.length > 0"
      class="w12"
      style="
        border: 1px solid;
        border-radius: 5px;
        padding: 10px;
        border-color: hsl(44, 100%, 48%);
      "
    >
      <div class="level">
        <h2>Warnungen</h2>
        <h2 v-if="isDecision">Bestätigen</h2>
      </div>
      <article v-for="warning in warnings" :key="warning.key" class="level">
        <p>
          <fa
            icon="exclamation-triangle"
            class="fa-fw"
            style="color: hsl(44, 100%, 48%)"
          />
          {{ warning.message }}
        </p>
        <p v-if="warning.decision">
          <label class="switch" style="margin: 0 5px 0 10px">
            <input v-model="decisionToSave[warning.key]" type="checkbox" />
            <span class="slider" />
          </label>
        </p>
      </article>
    </div>
    <div class="level w12">
      <h2>Vertrag bearbeiten</h2>
      <div
        class="level"
        :title="
          isDecision && !decisionToSave
            ? 'Bitte zunächst alle Warnungen bestätigen, die eine Bestätigung erfordern.'
            : ''
        "
      >
        <span>Bearbeitung</span>
        <label class="switch" style="margin: 0 5px 0 10px">
          <input
            v-model="editable.form"
            type="checkbox"
            @change="toggleEdit($event)"
          />
          <span class="slider" />
        </label>
        <rg-button
          :type="isDecision && decisionsPending ? 'is-danger' : 'is-primary'"
          :disabled="!checkFormInput() || (isDecision && decisionsPending)"
          :icon-left="isDecision && decisionsPending ? 'exclamation' : 'save'"
          label="Speichern"
          @click="saveChanges()"
        />
      </div>
    </div>
    <rg-widget title="Vertragsdaten" class="w6 form">
      <template #data>
        <strong class="level"
          ><span>Vertragsart</span>
          <span
            v-if="
              editable.form &&
              formDirty(data.contract_type, editable.data.contract_type)
            "
            :class="$style.changed"
          />
        </strong>
        <select
          v-model="editable.data.contract_type"
          class="rg-select"
          :disabled="!editable.form"
        >
          <option
            v-for="option in pricings"
            :key="option.contract_key"
            :value="option.contract_key"
          >
            {{
              `${option.contract_key} - ${option.description} (${option.invoice_amount} ${option.invoice_currency})`
            }}
          </option>
        </select>
        <strong class="level"
          ><span>Vertrags-Index</span>
          <span
            v-if="
              editable.form &&
              formDirty(data.contract_sequence, editable.data.contract_sequence)
            "
            :class="$style.changed"
          />
        </strong>
        <input
          v-if="editable.form"
          v-model="editable.data.contract_sequence"
          type="text"
        />
        <input
          v-else
          :value="data.contract_sequence"
          :disabled="!editable.form"
          type="text"
        />
        <strong class="level"
          ><span>Vertragsnachfolger</span>
          <span
            v-if="
              editable.form &&
              formDirty(
                data.contract_successor,
                editable.data.contract_successor
              )
            "
            :class="$style.changed"
          />
        </strong>
        <span v-if="!data.contract_successor"
          ><fa
            icon="exclamation-triangle"
            class="fa-fw"
            style="color: hsl(44, 100%, 48%)"
          />
          {{
            data.contract_end
              ? `Dieser Vertrag hat
          keinen Nachfolger und endet zum Vertragsende am ${new Date(
            data.contract_end
          ).toLocaleString("de-DE", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}`
              : "Dieser Vertrag hat weder Ende noch Nachfolger und läuft somit unendlich."
          }}
        </span>
        <select
          v-model="editable.data.contract_successor"
          class="rg-select"
          :disabled="!editable.form"
        >
          <option
            v-for="option in pricings"
            :key="option.contract_key"
            :value="option.contract_key"
          >
            {{
              `${option.contract_key} - ${option.description} (${option.invoice_amount} ${option.invoice_currency})`
            }}
          </option>
        </select>
        <div class="level">
          <strong><span>Vertragsbeginn</span></strong>
        </div>
        <span
          v-if="
            editable.form &&
            formDirty(
              formatDateTime(data.contract_start),
              editable.data.contract_start
            )
          "
          :class="$style.changed"
        />
        <input
          v-if="editable.form"
          v-model="editable.data.contract_start"
          type="datetime-local"
        />
        <input
          v-else
          :value="formatDateTime(data.contract_start)"
          :disabled="!editable.form"
          type="datetime-local"
        />
        <div class="level">
          <strong><span>Vertragsende</span></strong>
          <fa
            v-if="editable.form && !editable.data.contract_end"
            icon="exclamation-triangle"
            class="fa-fw"
            style="color: hsl(44, 100%, 48%)"
            title="Der Vertrag hat kein Ende. Ist dies gewollt?"
          />
        </div>
        <span
          v-if="
            editable.form &&
            formDirty(
              formatDateTime(data.contract_end),
              editable.data.contract_end
            )
          "
          :class="$style.changed"
        />
        <input
          v-if="editable.form"
          v-model="editable.data.contract_end"
          type="datetime-local"
          :style="
            !editable.data.contract_end
              ? 'border-color:hsl(44, 100%, 48%);'
              : ''
          "
        />
        <input
          v-else
          :value="formatDateTime(data.contract_end)"
          :disabled="!editable.form"
          type="datetime-local"
        />
        <strong class="level"
          ><span>Kommentar</span>
          <span
            v-if="
              editable.form &&
              formDirty(
                data.contract_annotation,
                editable.data.contract_annotation
              )
            "
            :class="$style.changed"
          />
        </strong>
        <input
          v-if="editable.form"
          v-model="editable.data.contract_annotation"
          type="text"
        />
        <input
          v-else
          :value="data.contract_annotation"
          :disabled="!editable.form"
          type="text"
        />
        <strong class="level"
          ><span>Kündigungsgrund</span>
          <span
            v-if="
              editable.form &&
              formDirty(
                data.cancellation_reason,
                editable.data.cancellation_reason
              )
            "
            :class="$style.changed"
          />
        </strong>
        <textarea
          v-if="!editable.form"
          :value="data.cancellation_reason"
          disabled
          cols="30"
          rows="10"
        />
        <textarea
          v-else
          v-model="editable.data.cancellation_reason"
          cols="30"
          rows="10"
        />
      </template>
    </rg-widget>
    <rg-widget title="Informationen" class="w6 form">
      <template #data>
        <div class="level">
          <strong><span>Vertrags-ID</span></strong>
          {{ data.id }}
        </div>
        <div class="level">
          <strong><span>Firmen-ID</span></strong>
          {{ data.company_id }}
        </div>
        <div class="level">
          <strong><span>Erstellt</span></strong>
          {{
            new Date(data.createdat).toLocaleString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          }}
        </div>
        <div class="level">
          <strong><span>Zuletzt geändert</span></strong>
          {{
            new Date(data.updatedat).toLocaleString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          }}
        </div>
        <hr />
        <h3>Abrechnungsdaten</h3>
        <div class="level">
          <strong><span>Artikelnummer</span></strong>
          {{ data.invoice_item_number || "N/A" }}
        </div>
        <div class="level">
          <strong><span>Rechnungsbetrag</span></strong>
          {{ data.invoice_amount }} {{ data.invoice_currency }}
        </div>
        <div class="level">
          <strong><span>Beitragsfrei bis</span></strong>
        </div>
        <span
          v-if="
            editable.form &&
            formDirty(
              formatDateTime(data.billing_blocked_until),
              editable.data.billing_blocked_until
            )
          "
          :class="$style.changed"
        />
        <input
          v-if="editable.form"
          v-model="editable.data.billing_blocked_until"
          type="datetime-local"
        />
        <input
          v-else
          :value="formatDateTime(data.billing_blocked_until)"
          :disabled="!editable.form"
          type="datetime-local"
        />
        <strong class="level"
          ><span>Beitragsbefreiung für Folgevertrag (in Monaten)</span>
          <fa
            v-if="
              editable.data.free_period_boost &&
              !editable.data.contract_successor
            "
            icon="exclamation-triangle"
            class="fa-fw"
            style="color: hsl(44, 100%, 48%)"
            title="Ohne Vertragsnachfolger wird dieser Wert beim Speichern verworfen!"
          />
          <span
            v-if="
              editable.form &&
              formDirty(data.free_period_boost, editable.data.free_period_boost)
            "
            :class="$style.changed"
          />
        </strong>
        <input
          v-if="editable.form"
          v-model="editable.data.free_period_boost"
          type="number"
          min="0"
          :disabled="!editable.data.contract_successor"
          :placeholder="
            !editable.data.contract_successor
              ? 'Kein Vertragsnachfolger angegeben'
              : ''
          "
        />
        <input
          v-else
          :value="data.free_period_boost || 0"
          :disabled="!editable.form"
          type="number"
          min="0"
        />
        <hr />
        <h3>Beitragsbefreiungen (aktueller Vertrag)</h3>
        <table class="rg-table condensed">
          <tr>
            <th>Beginn</th>
            <th>Ende</th>
            <th>Kommentar</th>
            <th>Erstelt von</th>
            <th>Optionen</th>
          </tr>
          <tr v-for="property in data.PaymentExemptions" :key="property.id">
            <td>
              {{
                property.exemption_start
                  ? new Date(property.exemption_start).toLocaleString(
                      undefined,
                      {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        timeZone: "UTC",
                      }
                    )
                  : ""
              }}
            </td>
            <td>
              {{
                property.exemption_end
                  ? new Date(property.exemption_end).toLocaleString(undefined, {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      timeZone: "UTC",
                    })
                  : ""
              }}
            </td>
            <td>
              {{ property.exemption_comment }}
            </td>
            <td v-if="property.exemption_granted_by">
              {{ property.exemption_granted_by.prename }}
              {{ property.exemption_granted_by.name }}
            </td>
            <td v-else>n/a</td>
            <td>
              <rg-button
                type="is-danger"
                icon-left="trash"
                label="Löschen"
                @click="deleteExemption(property.id)"
              />
            </td>
          </tr>
          <tr>
            <td>
              <input v-model="newExemption.start" type="date" />
            </td>
            <td>
              <input v-model="newExemption.end" type="date" />
            </td>
            <td>
              <input v-model="newExemption.comment" type="text" />
            </td>
            <td v-if="newExemption.start">
              {{ user.prename }}
              {{ user.name }}
            </td>
            <td v-else />
            <td>
              <rg-button
                type="is-primary"
                :disabled="!newExemption.start || !newExemption.end"
                icon-left="save"
                label="Speichern"
                @click="saveExemption()"
              />
            </td>
          </tr>
        </table>
      </template>
    </rg-widget>
  </div>
</template>

<script>
import { ref, watchEffect } from "@vue/composition-api";
import RgWidget from "@/components/elements/rg-widget.vue";
import { formDirty } from "@/helper.js";
import superagent from "superagent";
import Vue from "vue";
import { altBackendUrl } from "../../../constants";

export default {
  name: "CompanyHome",
  components: {
    RgWidget,
  },
  props: {
    data: { type: Object, default: () => {} },
  },
  setup(props, context) {
    let data = ref({});
    let editable = ref({
      loginValidated: null,
      emailValidated: null,
      form: false,
      data: {
        contract_type: null,
        contract_successor: null,
      },
    });
    let user = Object.assign(
      {},
      {
        prename: context.root.$store.state.user.prename,
        name: context.root.$store.state.user.name,
      }
    );
    let newExemption = ref({
      start: null,
      end: null,
      comment: null,
      grantedBy: context.root.$store?.state?.user?.id,
    });
    let pricings = ref([0]);
    let decisionToSave = ref({});

    getPricings();

    watchEffect(() => {
      data.value = props.data;
      if (
        props.data.contract_successor &&
        !editable.value.data.contract_successor
      )
        editable.value.data.contract_successor = props.data.contract_successor;
      if (props.data.contract_type && !editable.value.data.contract_type)
        editable.value.data.contract_type = props.data.contract_type;
    });

    function checkFormInput() {
      if (
        editable.value.data.contract_type &&
        editable.value.data.contract_sequence
      )
        return true;
    }

    function toggleEdit(val) {
      if (val) {
        editable.value.data = { ...data.value };
        editable.value.data.contract_start = formatDateTime(
          editable.value.data.contract_start
        );
        editable.value.data.contract_end = formatDateTime(
          editable.value.data.contract_end
        );
        editable.value.data.billing_blocked_until = formatDateTime(
          editable.value.data.billing_blocked_until
        );
      }
    }

    function contractHasEnded(contractEnd) {
      const nowDate = new Date(Date.now());
      const checkDate = new Date(contractEnd);
      return checkDate - nowDate > 0 ? false : true;
    }

    function formatDateTime(date = Date.now()) {
      const offset = new Date(date).getTimezoneOffset() * 60000;
      const newDate = new Date(new Date(date).setMilliseconds(0, 0) - offset);
      return date ? newDate.toISOString().slice(0, -1) : "";
    }

    function saveChanges() {
      if (this.isDecision && !this.decisionsPending) return; //Just for safety reasons
      const payload = {
        id: parseInt(editable.value.data.id) || null,
        contractType: editable.value.data.contract_type,
        contractSequence:
          parseInt(editable.value.data.contract_sequence) || null,
        contractSuccessor: editable.value.data.contract_successor || null,
        //Refactor to support locale of customer
        contractStart: editable.value.data.contract_start
          ? new Date(
              formatDateTime(editable.value.data.contract_start)
            ).toISOString()
          : null,
        //Refactor to support locale of customer
        contractEnd: editable.value.data.contract_end
          ? new Date(
              formatDateTime(editable.value.data.contract_end)
            ).toISOString()
          : null,
        contractAnnotation: editable.value.data.contract_annotation || null,
        billingBlockedUntil: editable.value.data.billing_blocked_until
          ? new Date(
              formatDateTime(editable.value.data.billing_blocked_until)
            ).toISOString()
          : null,
        freePeriodBoost:
          editable.value.data.contract_successor &&
          editable.value.data.free_period_boost
            ? `P0Y${editable.value.data.free_period_boost}M0DT0H0M0S`
            : null,
        cancellationReason: editable.value.data.cancellation_reason || null,
      };

      const updateContractMutation = `mutation Mutation($contractId: Int!, $contractSequence: Int, $contractType: String,
       $contractSuccessor: String, $contractStart: DateTime, $contractEnd: DateTime, $contractAnnotation: String,
       $billingBlockedUntil: DateTime, $freePeriodBoost: Duration, $cancellationReason: String) {
        Contract(
          contractId: $contractId,
          contract_sequence: $contractSequence,
          contract_type: $contractType,
          contract_successor: $contractSuccessor,
          contract_start: $contractStart,
          contract_end: $contractEnd,
          contract_annotation: $contractAnnotation,
          billing_blocked_until: $billingBlockedUntil,
          free_period_boost: $freePeriodBoost,
          cancellation_reason: $cancellationReason) {
            id
          }
        }`;

      superagent
        .post(altBackendUrl)
        .send({
          query: updateContractMutation,
          variables: {
            contractId: payload.id,
            contractSequence: payload.contractSequence,
            contractType: payload.contractType,
            contractSuccessor: payload.contractSuccessor,
            contractStart: payload.contractStart,
            contractEnd: payload.contractEnd,
            contractAnnotation: payload.contractAnnotation,
            billingBlockedUntil: payload.billingBlockedUntil,
            freePeriodBoost: payload.freePeriodBoost,
            cancellationReason: payload.cancellationReason,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resContract) => {
          if (
            !resContract.body?.data?.errors &&
            resContract.body?.data?.Contract
          ) {
            Vue.$toast.open({
              icon: "check",
              message: "Der Vertrag wurde erfolgreich gespeichert.",
            });
            setTimeout(() => {
              context.root.$router.go();
            }, 2000);
          } else {
            Vue.$toast.open({
              icon: "times-circle",
              type: "error",
              message: "FEHLER: Der Vertrag konnte nicht gespeichert werden.",
            });
            editable.form = false;
            console.table(payload);
            console.log(resContract.body?.data?.errors);
          }
        })
        .catch((err) => {
          Vue.$toast.open({
            icon: "times-circle",
            type: "error",
            message: "FEHLER: Der Vertrag konnte nicht gespeichert werden.",
          });
          editable.form = false;
          console.table(payload);
          console.error(err?.response?.body?.errors);
        });
    }

    function fetchExemptions() {
      const gqQueryExemptions = `
          query Query($contractId: Int!) {
            Contract($contractId: $contractId) {
              PaymentExemptions{
                id
                exemption_start
                exemption_end
                exemption_comment
                updatedat
                exemption_granted_by {
                  id
                  prename: firstName
                  name: lastName
                }
              }
            }
          }
        `;

      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryExemptions,
          variables: {
            contractId: parseInt(data.value.id),
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          data.value.PaymentExemptions =
            res.body.data.Contract.PaymentExemptions;
        })
        .catch((err) => {
          console.error(err);
          Vue.$toast.open({
            icon: "times-circle",
            type: "error",
            message: "FEHLER: Konnte die Beitragsbefreiungen nicht laden.",
          });
        });
    }

    function getPricings() {
      const gqQueryPricings = `
          query Query {
            Pricings(limit: 10000, skip: 0) {
              id
              title
              contract_key
              description
              invoice_amount
              invoice_currency
              isfreeplan
            }
          }
        `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryPricings,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          pricings.value = res.body?.data?.Pricings;
        })
        .catch((err) => {
          console.error(err);
          Vue.$toast.open({
            icon: "times-circle",
            type: "error",
            message: "FEHLER: Konnte die Vertragsarten nicht laden",
          });
        });
    }

    function saveExemption() {
      if (newExemption.value.start > newExemption.value.end) {
        Vue.$toast.open({
          icon: "times-circle",
          type: "error",
          message:
            "FEHLER: Ende kann nicht vor Beginn der Beitragsbefreiung liegen",
        });
        return;
      }
      const createExemptionMutation = `mutation Mutation($paymentExemptionId: String!, $contractId: Int, $exemptionStart: DateTime, $exemptionEnd: DateTime, $exemptionComment: String, $exemptionGrantedBy: Int) {
        PaymentExemption(paymentExemptionId: $paymentExemptionId, contract_id: $contractId, exemption_start: $exemptionStart, exemption_end: $exemptionEnd, exemption_comment: $exemptionComment, exemption_granted_by: $exemptionGrantedBy) {
          id
          exemption_start
          exemption_end
          exemption_comment
          exemption_granted_by {
            id
            prename
            name
          }
        }
      }
      `;

      superagent
        .post(altBackendUrl)
        .send({
          query: createExemptionMutation,
          variables: {
            paymentExemptionId: "-1",
            contractId: parseInt(data.value.id),
            exemptionStart: new Date(newExemption.value.start).toISOString(),
            exemptionEnd: new Date(newExemption.value.end).toISOString(),
            exemptionComment: newExemption.value.comment,
            exemptionGrantedBy: parseInt(newExemption.value.grantedBy),
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(() => {
          fetchExemptions();
          Object.assign(newExemption.value, {
            start: null,
            end: null,
            comment: null,
          });
        })
        .catch((err) => {
          console.error(err);
          Vue.$toast.open({
            icon: "times-circle",
            type: "error",
            message: "FEHLER: Konnte die Beitragsbefreiung nicht speichern.",
          });
        });
    }

    function deleteExemption(id) {
      const regexExp =
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      if (!id || !regexExp.test(id)) {
        Vue.$toast.open({
          icon: "times-circle",
          type: "error",
          message:
            "FEHLER: Keine gültige ID zum Löschen der Beitragsbefreiung übergeben.",
        });
        return;
      } else if (window.confirm("Beitragsbefreiung wirklich löschen?")) {
        const deleteExemptionMutation = `
              mutation Mutation($exemptionId: String!) {
                Delete {
                  PaymentExemption(id: $exemptionId)
                }
              }
            `;

        superagent
          .post(altBackendUrl)
          .send({
            query: deleteExemptionMutation,
            variables: {
              exemptionId: id,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            if (res.body.data.Delete?.PaymentExemption == true) {
              Vue.$toast.open({
                message: "Beitragsbefreiung erfolgreich gelöscht",
                icon: "check",
              });
            } else {
              Vue.$toast.open({
                message:
                  "FEHLER: Beitragsbefreiung konnte nicht gelöscht werden",
                icon: "times-circle",
              });
            }
            fetchExemptions();
          });
      }
    }

    return {
      editable,
      contractHasEnded,
      checkFormInput,
      toggleEdit,
      formDirty,
      formatDateTime,
      saveChanges,
      user,
      newExemption,
      saveExemption,
      deleteExemption,
      pricings,
      decisionToSave,
    };
  },
  computed: {
    warnings() {
      const warnings = [
        {
          key: "unpaidToPaid",
          active:
            !["EVAL1", "EVALA", "PSW22"].includes(
              this.editable?.data?.contract_type
            ) &&
            this.pricings?.find(
              (p) => p.contract_key === this.editable?.data?.contract_type
            )?.isfreeplan &&
            !this.pricings?.find(
              (p) => p.contract_key === this.editable?.data?.contract_successor
            )?.isfreeplan,
          message:
            "Der kostenlose Vertrag wird sich in einen kostenpflichtigen Vertrag umwandeln.",
        },
        {
          key: "paidToUnpaid",
          active:
            !this.pricings?.find(
              (p) => p.contract_key === this.editable?.data?.contract_type
            )?.isfreeplan &&
            this.pricings?.find(
              (p) => p.contract_key === this.editable?.data?.contract_successor
            )?.isfreeplan,
          message:
            "Der kostenplfichtige Vertrag wird sich in einen kostenlosen Vertrag umwandeln.",
        },
        {
          key: "unpaidToUnpaid",
          active:
            this.pricings?.find(
              (p) => p.contract_key === this.editable?.data?.contract_type
            )?.isfreeplan &&
            this.pricings?.find(
              (p) => p.contract_key === this.editable?.data?.contract_successor
            )?.isfreeplan,
          message:
            "Kostenloser Vertrag wechselt zu einem anderen kostenlosen Vertrag. Ist dies sinnvoll?",
        },
        {
          key: "paidToPaid",
          active:
            !(
              this.pricings?.find(
                (p) => p.contract_key === this.editable?.data?.contract_type
              )?.isfreeplan &&
              !this.pricings?.find(
                (p) =>
                  p.contract_key === this.editable?.data?.contract_successor
              )?.isfreeplan
            ) &&
            this.editable?.data?.contract_type !==
              this.editable?.data?.contract_successor,
          message:
            "Der Vertragsnachfolger unterscheidet sich vom aktuellen Vertrag. Ist dies so gewollt?",
        },
        {
          key: "startNewContract",
          active:
            this.editable?.data?.contract_successor &&
            new Date(this.editable?.data?.contract_end) <= new Date(),
          message:
            "Mit dieser Konfiguration wird der Folgevertrag sofort starten. Dies kann Auswikungen auf die Abrechnung und die Kommunikation mit dem Kunden haben!",
          decision: true,
        },
      ];
      return this.editable?.data.contract_successor
        ? warnings.filter((el) => el.active)
        : [];
    },
    isDecision() {
      return this.warnings?.filter((w) => w.decision).length > 0;
    },
    decisionsPending() {
      return (
        Object.values(this.decisionToSave).filter((v) => v).length !=
        this.warnings?.filter((w) => w.decision).length
      );
    },
  },
};
</script>

<style lang="scss" module>
.detail {
  grid-auto-flow: dense;
}

.changed {
  height: 10px;
  width: 10px;
  background: red;
  display: inline-block;
  border-radius: 5px;
  margin-left: 5px;
}
</style>
