<template>
  <div class="rg-grid">
    <div class="level w12">
      <h2>Berechtigungen</h2>
      <div class="level">
        <rg-button
          type="is-primary"
          icon-left="save"
          label="Speichern"
          @click="saveChanges()"
        />
      </div>
    </div>
    <div
      class="card w6"
      style="border-radius: 10px; padding: 20px; margin-bottom: 10px"
    >
      <br />
      <h3>Erweiterungen</h3>
      <br />
      <div
        v-for="extension in extensions"
        :key="extension.id"
        style="display: flex; gap: 10px; margin-bottom: 10px"
      >
        <div>
          <label class="switch" style="margin-right: 5px">
            <input
              type="checkbox"
              :checked="
                permittedExtensions &&
                permittedExtensions.includes(extension.id)
              "
              @change="changePermission(extension.id)"
            />
            <span class="slider" />
          </label>
        </div>
        <div>
          <div>
            <strong>
              <span>{{ extension.extension_name }}</span>
            </strong>
          </div>
          <div v-if="extension.extension_description">
            {{ extension.extension_description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, onMounted } from "@vue/composition-api";
import superagent from "superagent";
import Vue from "vue";
import { altBackendUrl } from "../../../constants";

export default {
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    const machine = ref({});
    // const extensions = ref([
    //   { id: 1, key: "rg_default", name: "Basismodul Rep.Guide" },
    // ]);
    const extensions = ref([]);
    const permissions = ref([]);
    let permittedExtensions = ref([]);

    watchEffect(() => {
      machine.value = props.data;
    });

    onMounted(() => {
      fetchExtensions();
      fetchPermissions();
    });

    function fetchExtensions() {
      superagent
        .get(
          "https://repguide-rest.fricke-holding.workers.dev/api/legacy/extensions"
        )
        .then(({ body: extensionList }) => {
          extensions.value = extensionList.data.Extensions;
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function fetchPermissions() {
      const gqQueryPermissions = `
      query Query($relationType: String, $relationId: Int) {
        AppPermissions(relationType: $relationType, relationId: $relationId) {
          id
          relation_type
          relation_id
          permitted_extensions
          createdat
          updatedat
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryPermissions,
          variables: {
            relationType: "machines",
            relationId: parseInt(machine?.value?.id) || null,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: perm }) => {
          permissions.value = perm.data.AppPermissions;
          permittedExtensions.value =
            permissions.value[0]?.permitted_extensions || [];
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function changePermission(id) {
      if (!permittedExtensions.value.includes(id)) {
        permittedExtensions.value.push(id);
      } else {
        permittedExtensions.value.splice(
          permittedExtensions.value.indexOf(id),
          1
        );
      }
    }

    function saveChanges() {
      const sorted_extensions = permittedExtensions.value.sort((a, b) => a - b);
      if (
        permissions?.value[0]?.relation_id &&
        parseInt(machine?.value?.id) !== permissions?.value[0]?.relation_id
      ) {
        Vue.$toast.open({
          message:
            "Integritätsprüfung fehlgeschlagen: ID der Maschine stimmt nicht mit der zugehörigen ID der Berechtigung überein.",
          icon: "times-circle",
        });
        return false;
      } else {
        const mSavePermissionsMutation = `
        mutation Mutation($appPermissionId: Int, $relationType: String, $relationId: Int, $permittedExtensions: [Int]) {
          AppPermission(id: $appPermissionId, relation_type: $relationType, relation_id: $relationId, permitted_extensions: $permittedExtensions) {
            id
          }
        }
        `;
        superagent
          .post(altBackendUrl)
          .send({
            query: mSavePermissionsMutation,
            variables: {
              appPermissionId: permissions.value[0]?.id || -1,
              relationType: "machines",
              relationId: machine.value.id,
              permittedExtensions: sorted_extensions,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then(() => {
            Vue.$toast.open({
              message: "Die Berechtigungen wurden erfolgreich gespeichert.",
              icon: "check",
            });
          })
          .catch((err) => {
            Vue.$toast.open(err);
          });
      }
    }

    return {
      machine,
      extensions,
      permittedExtensions,
      changePermission,
      saveChanges,
    };
  },
};
</script>
