<template>
  <div>
    <navigation />
    <main v-if="!loading">
      <h1>
        <fa icon="tractor" />{{
          machine.title
            ? machine.title
            : machine.loading
            ? "Machine wird geladen"
            : "Machine nicht gefunden"
        }}
        <code style="opacity: 0.5">{{ machine.status }}</code>
      </h1>
      <div>
        <code
          v-clipboard="() => machine.slug"
          v-clipboard:success="clipboardSuccessHandler"
          style="font-weight: bold"
          title="Slug"
          class="click-select"
        >
          {{ machine.slug }}
        </code>
        <span style="opacity: 0.5"> | </span>
        <code
          v-clipboard="() => machine.id"
          v-clipboard:success="clipboardSuccessHandler"
          style="font-weight: bold"
          title="ID"
          class="click-select"
        >
          {{ machine.id }}
        </code>
        <span style="opacity: 0.5"> | </span>
        <code title="Sprache">{{ machine.language }}</code>
        <code v-if="machine.parentid">
          <span style="opacity: 0.5"> | </span>
          Gehört zu
          <router-link
            title="Zur Eltern-Maschine wechseln"
            :to="'/machines/' + machine.parentid"
          >
            <code>{{ machine.parentid }}</code
            >&nbsp;
          </router-link>
        </code>
      </div>
      <br />
      <rg-tabs>
        <router-link
          tag="button"
          replace
          :to="'/machines/' + $route.params.id + '/home'"
        >
          <fa icon="tractor" />&nbsp;Übersicht
        </router-link>

        <router-link
          tag="button"
          replace
          :to="'/machines/' + $route.params.id + '/type'"
        >
          <fa icon="code-branch" />&nbsp;Typenstruktur
        </router-link>

        <router-link
          tag="button"
          replace
          :to="'/machines/' + $route.params.id + '/tech'"
        >
          <fa icon="server" />&nbsp;Technische Daten
        </router-link>

        <router-link
          tag="button"
          replace
          :to="'/machines/' + $route.params.id + '/tabs'"
        >
          <fa icon="folder-open" />&nbsp;Tabs
        </router-link>

        <router-link
          tag="button"
          replace
          :to="'/machines/' + $route.params.id + '/plan'"
        >
          <fa icon="clipboard-check" />&nbsp;Service
        </router-link>

        <router-link
          tag="button"
          replace
          :to="'/machines/' + $route.params.id + '/spareparts'"
        >
          <fa icon="clipboard-check" />&nbsp;Spare Parts
        </router-link>

        <router-link
          tag="button"
          replace
          :to="'/machines/' + $route.params.id + '/files'"
          disabled
        >
          <fa icon="file-alt" />&nbsp;Dateien
        </router-link>

        <router-link
          tag="button"
          replace
          :to="'/machines/' + $route.params.id + '/jaltest'"
        >
          <fa-layers>
            <fa icon="circle" transform="shrink-3 left-1.5 up-1.5" />
            <fa
              icon="circle"
              transform="right-5 down-5 shrink-10"
            /> </fa-layers
          >&nbsp;Jaltest
        </router-link>

        <router-link
          tag="button"
          replace
          :to="'/machines/' + $route.params.id + '/varta'"
        >
          <fa icon="car-battery" />&nbsp;Varta
        </router-link>

        <router-link
          tag="button"
          replace
          :to="'/machines/' + $route.params.id + '/permissions'"
        >
          <fa icon="lock" />&nbsp;Berechtigungen
        </router-link>

        <router-link
          tag="button"
          replace
          :to="'/machines/' + $route.params.id + '/connections'"
        >
          <fa icon="link" />&nbsp;Verknüpfungen
        </router-link>

        <a
          :href="'https://rep.guide/de/machine/' + machine.slug"
          target="_blank"
          rel="noopener noreferrer"
        >
          <fa icon="eye" />&nbsp;Rep.Guide&nbsp;<fa
            style="opacity: 0.5"
            icon="external-link-alt"
          />
        </a>

        <a
          v-if="machine.gitlabticket"
          :href="issues + machine.gitlabticket"
          target="_blank"
          rel="noopener noreferrer"
        >
          <fa icon="tag" />&nbsp;GitLab#{{ machine.gitlabticket }}&nbsp;<fa
            style="opacity: 0.5"
            icon="external-link-alt"
          />
        </a>
      </rg-tabs>
      <br /><br />
      <router-view :data="machine" />
    </main>
  </div>
</template>

<script>
import rg_tabs from "@/components/elements/rg-tabs";
import { issues } from "@/helper.js";
import Vue from "vue";
import superagent from "superagent";
import { altBackendUrl } from "@/constants.js";
import { ref, watchEffect } from "@vue/composition-api";

export default {
  name: "Detail",
  components: { "rg-tabs": rg_tabs },
  props: {},
  setup(props, context) {
    let machine = ref({});
    let loading = ref(true);

    watchEffect(() => {
      if (context.root.$router.currentRoute.params.id !== machine.id) {
        fetchData();
      }
    });
    let machineMarkets = ref([]);
    let machineIndustries = ref([]);

    function fetchData() {
      const getMachineQuery = `query Query($machineId: Int) {
          Machine(id: $machineId) {
            id,
            parentID: parentid,
            slug,
            vin,
            title,
            manufacturer,
            type,
            series,
            model,
            year,
            yearShort: yearshort,
            granitID: granitId,
            authorID: authorid,
            createdAt: createdat,
            updatedAt: updatedat,
            deletedAt: deletedat,
            comment,
            manufacturerUrl: urlmanufacturer,
            wikibooksUrl: urlwikibooks,
            konedataUrl: urlkonedata,
            traktorbookUrl: urltraktorbook,
            yearStart,
            yearEnd,
            konedataID: konedataid,
            hasTechdata,
            hasServiceplan,
            chassisStart: chassisnrStart,
            chassisEnd: chassisnrEnd,
            engine,
            transmission,
            gitlabticket,
            hasParts,
            descentBy: descentby,
            emission,
            techdataDate,
            status,
            firstLiveAt: firstliveat,
            language,
            region,
            dataSources: data_sources,
            isParent,
            jdEquipmentRefID: jd_equipment_ref_id,
            last_edited_by
            Data {
              originalfallbacklanguage
            }
          }
        }
      `;

      const getMachineMarketAndIndustry = `query Query($machineId: Int) {
          Machine(id: $machineId) {
            MachineIndustry {
              i18n_key
              id
              industry_title
            }
            MachineMarket {
              i18n_key
              id
              market_title
            }
          }
        }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: getMachineMarketAndIndustry,
          variables: { machineId: parseInt(context.root.$route.params.id) },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          machineMarkets.value = res.body.data.Machine.MachineMarket;
          machineIndustries.value = res.body.data.Machine.MachineIndustry;
        });

      superagent
        .post(altBackendUrl)
        .send({
          query: getMachineQuery,
          variables: { machineId: parseInt(context.root.$route.params.id) },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          machine.value = {
            ...res.body.data.Machine,
            machine_markets: machineMarkets,
            machine_industries: machineIndustries,
          };

          if (res.body.data.Machine.last_edited_by === null) {
            loading.value = false;
          } else {
            superagent
              .get(
                `https://repguide-rest.fricke-holding.workers.dev/api/legacy/user/by-id/${res.body.data.Machine.last_edited_by}`
              )
              .then((resEditor) => {
                machine.value.lastEditedByFullName =
                  resEditor.body?.data?.User?.prename ||
                  resEditor.body?.data?.User?.name
                    ? `${resEditor.body?.data?.User?.prename || ""} ${
                        resEditor.body?.data?.User?.name || ""
                      }`
                    : null;
                loading.value = false;
              });
          }
        });
    }

    fetchData();

    function clipboardSuccessHandler() {
      Vue.$toast.open({
        message: "Erfolgreich kopiert",
        icon: "clipboard-check",
      });
    }

    return { machine, loading, issues, clipboardSuccessHandler };
  },
};
</script>

<style></style>
