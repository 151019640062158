<template>
  <div>
    <rg-widget>
      Verträge
      <template #data>
        <div v-for="contract in contracts" :key="contract.id">
          <div class="level">
            <h3>
              <strong>{{ contract.contract_type }}</strong> (ID)
              <strong>{{ contract.id }}</strong>
              <div
                v-if="!contract.contract_end && !contract.contract_start"
                style="color: hsl(44, 100%, 48%)"
              >
                <fa icon="play" class="fa-fw" />
                &nbsp;
                <strong> Vertrag nicht aktiviert</strong>
              </div>
              <div
                v-else-if="
                  contract.contract_end &&
                  !contract.contract_successor &&
                  compareDates(
                    new Date(contract.contract_end),
                    new Date(),
                    'gte'
                  )
                "
                style="color: hsl(44, 100%, 48%)"
              >
                <fa icon="exclamation-triangle" class="fa-fw" />
                &nbsp;<strong>Kündigung eingegangen</strong>
              </div>
              <div
                v-else-if="
                  contract.contract_end &&
                  !contract.contract_successor &&
                  compareDates(
                    new Date(contract.contract_end),
                    new Date(),
                    'lt'
                  )
                "
                style="color: hsl(348, 100%, 61%)"
              >
                <fa icon="handshake-slash" class="fa-fw" />
                &nbsp;<strong>Vertrag gekündigt</strong>
              </div>
              <div
                v-else-if="
                  contract.contract_start &&
                  isContractInFuture(contract.contract_start)
                "
                style="color: grey"
              >
                <fa icon="arrow-up" class="fa-fw is-primary" />
                &nbsp;<strong>Zukünftiger Vertrag</strong>
              </div>
              <div
                v-else-if="
                  contract.contract_end &&
                  contractHasEnded(contract.contract_end) &&
                  contract.contract_successor
                "
                style="color: grey"
              >
                <fa icon="clock" class="fa-fw is-primary" />
                &nbsp;<strong>Vertrag abgelaufen</strong>
              </div>
              <div
                v-else-if="
                  (contract.contract_start &&
                    !contractHasEnded(contract.contract_end) &&
                    contract.contract_successor) ||
                  (contract.contract_start && !contract.contract_end)
                "
                style="color: #1575f7"
              >
                <fa icon="handshake" class="fa-fw is-primary" />
                &nbsp;<strong>Vertrag aktiv</strong>
              </div>
            </h3>
            <router-link tag="button" :to="'/contracts/' + contract.id">
              Vertrag öffnen
            </router-link>
          </div>
          <table class="rg-table condensed">
            <tr v-for="(property, key) in contract" :key="key">
              <th>{{ key }}</th>
              <td
                v-if="
                  [
                    'contract_start',
                    'contract_end',
                    'createdat',
                    'updatedat',
                  ].includes(key)
                "
                :title="property ? new Date(property).toLocaleString() : ''"
              >
                {{
                  property
                    ? new Date(property).toLocaleString(undefined, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })
                    : ""
                }}
                {{ property ? `(${new Date(property).toUTCString()})` : "" }}
              </td>
              <td v-else-if="key === 'contract_options'">
                <pre style="white-space: pre-wrap; word-break: break-word">{{
                  property
                }}</pre>
              </td>
              <td v-else-if="key === 'PaymentExemptions'">
                <p v-for="element in property" :key="element.id">
                  {{
                    element.exemption_start
                      ? new Date(element.exemption_start).toLocaleString(
                          undefined,
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            timeZone: "UTC",
                          }
                        )
                      : ""
                  }}
                  bis
                  {{
                    element.exemption_end
                      ? new Date(element.exemption_end).toLocaleString(
                          undefined,
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            timeZone: "UTC",
                          }
                        )
                      : ""
                  }}
                  {{
                    element.exemption_comment
                      ? `(${element.exemption_comment})`
                      : ""
                  }}
                </p>
              </td>
              <td v-else>
                {{ property }}
              </td>
            </tr>
          </table>
        </div>
      </template>
    </rg-widget>
  </div>
</template>

<script>
import RgWidget from "@/components/elements/rg-widget.vue";
import { ref } from "@vue/composition-api";
import superagent from "superagent";
import { altBackendUrl } from "../../../constants";
// import Vue from "vue";
// import { api } from "@/helper.js";

export default {
  name: "CompanyContracts",
  components: {
    RgWidget,
  },
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    let contracts = ref([]);

    const gqQuery = `query Query($companyId: Int){
                Company(id: $companyId) {
                  id
                 contracts: Contracts(limit: 1000, skip: 0) {
                    nodes {
                    id
                    contract_sequence: sequence
                    contract_type: type
                    contract_successor: successor
                    contract_start: startedAt
                    contract_end: endedAt
                    PaymentExemptions{
                      id
                      exemption_start
                      exemption_end
                      exemption_comment
                    }
                    }
                  }
                }
              }`;
    superagent
      .post(altBackendUrl)
      .send({
        query: gqQuery,
        variables: { companyId: parseInt(context.root.$route.params.id) },
      })
      .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
      .then((res) => {
        contracts.value = res.body?.data?.Company?.contracts.nodes;
        sortContracts();
        // console.log(contracts.value);
      });

    function sortContracts() {
      contracts.value.sort((a, b) =>
        a.contract_sequence < b.contract_sequence ? 1 : -1
      );
    }

    function compareDates(
      compare,
      compareWith = new Date(),
      comparator = "eq"
    ) {
      console.log(compare, compareWith);
      if (
        !compare ||
        !(compare instanceof Date) ||
        !compareWith ||
        !(compareWith instanceof Date) ||
        !comparator
      ) {
        console.warn(
          "Invalid function call in compareDates(). One or more parameters are not set correctly."
        );
        return false;
      } else {
        compare = compare.getTime();
        compareWith = compareWith.getTime();
      }
      if (comparator === "eq") {
        return compare === compareWith;
      } else if (comparator === "neq") {
        return compare !== compareWith;
      } else if (comparator === "lt") {
        return compare < compareWith;
      } else if (comparator === "lte") {
        return compare <= compareWith;
      } else if (comparator === "gt") {
        return compare > compareWith;
      } else if (comparator === "gte") {
        return compare >= compareWith;
      }
      console.warn("Unhandled case in function compareDates()");
      return false;
    }

    function contractHasEnded(contractEnd) {
      const nowDate = new Date(Date.now());
      const checkDate = new Date(contractEnd);
      return checkDate - nowDate > 0 ? false : true;
    }

    function isContractInFuture(contractStart) {
      return new Date(contractStart) > new Date();
    }
    return { contracts, contractHasEnded, compareDates, isContractInFuture };
  },
};
</script>

<style lang="scss" module>
</style>
