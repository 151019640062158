<template>
  <article
    style="margin-bottom: 5px"
    :class="[
      $style.version_entry,
      index === 0 ? $style.version_entry__newest : null,
    ]"
  >
    <span
      :style="
        version.publishedat && version.id == newestLive
          ? 'color:hsl(141, 53%, 53%)'
          : ''
      "
    >
      <fa icon="file" />
    </span>
    <div :class="$style.version_entry__info">
      <header class="level">
        <div>
          <h4
            :style="
              version.publishedat && version.id == newestLive
                ? 'color:hsl(141, 53%, 53%)'
                : ''
            "
          >
            Version {{ count - index }} (ID: {{ version.id }})
          </h4>
        </div>
        <rg-button
          v-if="!version.readonly || newestLive == version.id"
          icon-left="copy"
          label="Duplizieren"
          @click="
            $emit(
              'duplicate',
              newestLive == version.id ? { slug } : { hash: version.id }
            )
          "
        />
        <rg-button
          v-if="
            $store.state.user.tier === 'administration' && !version.readonly
          "
          icon-left="upload"
          class="is-danger"
          style="margin-left: 3px"
          label="LIVE"
          @click="$emit('sendlive', version.id)"
        />
      </header>
      <br>
      <div class="level">
        <span style="flex: 1">
          <fa
            class="fa-fw"
            :icon="version.updatedat ? 'calendar-alt' : 'calendar-times'"
          />
          <span>{{
            version.updatedat
              ? new Date(version.updatedat).toLocaleString()
              : "älter als 20.4.2020"
          }}</span>
        </span>
        <span style="flex: 1">
          <fa
            class="fa-fw"
            :icon="version.author ? 'user' : 'user-ninja'"
          />&nbsp;
          {{ version.author || "Autor unbekannt" }}
        </span>
      </div>
      <div class="level">
        <span style="flex: 1">
          <span
            :style="
              version.publishedat && version.id == newestLive
                ? 'color:hsl(141, 53%, 53%)'
                : ''
            "
          >
            <fa
              class="fa-fw"
              icon="circle"
            />&nbsp; Live seit
            {{
              version.publishedat
                ? new Date(version.publishedat).toLocaleString()
                : "-"
            }}
          </span>
        </span>
      </div>
      <hr>
      <div
        class="flex-right"
        style="gap: 5px"
      >
        <router-link
          v-if="version.editor_version !== 'v2'"
          :to="preview"
          target="_blank"
        >
          <rg-button
            icon="eye"
            title="Rep.Guide Vorschau"
          />
        </router-link>

        <router-link
          v-if="!version.readonly && version.editor_version !== 'v2'"
          :to="link"
        >
          <rg-button
            icon-left="pen"
            label="Bearbeiten (Admin-Tool)"
          />
        </router-link>

        <!-- <rg-dropdown
          v-if="!version.readonly && version.editor_version !== 'v2'"
          :width="420"
          position="bottom-left"
          stay
        >
          <template #trigger>
            <rg-button
              label="Bearbeiten (Admin-Tool)"
              icon-left="pen"
              icon-right="angle-down"
              @click="enableOldEditor = false"
            />
          </template>
          <div>
            <p style="font-size: large; font-weight: bold; color: red; padding-bottom: 0">
              Version im alten Editor bearbeiten
            </p>
            <div style="display: flex; gap: 5px; padding: 10px 15px">
              <label
                class="switch"
                style="margin-right:5px"
              >
                <input
                  v-model="enableOldEditor"
                  type="checkbox"
                >
                <span class="slider" />
              </label>
              <div style="flex: 1 1 0%;">
                <p style="padding: 4px 0">
                  Ich bestätige, dass ich mit Jan oder einem anderen Admin gesprochen habe,
                  es sich um einen Ausnahmefall handelt und ich unumgänglich den alten Editor verwenden muss.
                </p>
                <router-link
                  v-if="enableOldEditor"
                  :to="link"
                >
                  <rg-button
                    class="is-danger custom"
                    style="width: 100%"
                    label="Admin-Tool Editor öffnen"
                    icon-left="pen"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </rg-dropdown> -->

        <a
          v-if="!version.readonly"
          :href="`https://rep.guide/de/admin/techdata/${version.id}`"
          target="_blank"
        >
          <rg-button
            type="is-primary"
            icon-left="pen"
            icon-right="external-link-alt"
            label="Bearbeiten"
          />
        </a>
      </div>
    </div>
  </article>
</template>

<script>
// import dropdown from "@/components/elements/rg-dropdown";

export default {
  // components: {
  //   "rg-dropdown": dropdown
  // },
  props: {
    newestLive: { type: Number, default: 0 },
    version: { type: Object, default: () => {} },
    versions: { type: Object, default: () => {} },
    index: { type: Number, default: 0 },
    link: { type: String, default: "" },
    preview: { type: String, default: "" },
    slug: { type: String, default: "" },
    live: { type: Object, default: () => {} },
    count: { type: Number, default: 0 },
  },
  data: () => ({
    enableOldEditor: false,
  }),
};
</script>

<style lang="scss" module>
.version_entry {
  display: grid;
  grid-template-columns: 60px auto;
  text-decoration: none;
  background: var(--card-light-stack);
  border-radius: 6px;
  padding: 10px 20px 10px 10px;
  > span {
    font-size: 2.8em !important;
  }
}
.version_entry__info__live {
  background: red;
  border-radius: 10px;
  padding: 0 10px;
  letter-spacing: 1px;
  box-shadow: 0 4px 8px rgb(255 0 0 / 0.3);
  text-decoration: none;
  color: white;
}
.version_entry__info {
  b {
    padding: 15px 5px;
    font-size: 1.1rem;
  }
  div {
    user-select: none;
    color: var(--text-dark);
    hr {
      display: block;
      height: 20px;
      width: 2px;
      margin: 0;
      color: var(--text-dark);
    }
    > span {
      display: inline-flex;
      align-items: center;
      span:first-of-type {
        margin-right: 5px;
      }
    }
  }
}
</style>
