<template>
  <div>
    <navigation />
    <p v-if="loading">Lade Daten</p>
    <main v-else class="rg-grid">
      <div class="level w12">
        <h1><fa icon="folder-open" />&nbsp;Tab "{{ editable.tab_title }}"</h1>
        <div class="level">
          <rg-button
            type="is-primary"
            icon-left="save"
            label="Speichern"
            @click="saveChanges()"
          />
        </div>
      </div>

      <rg-widget title="Allgemein" class="w6 form">
        <template #data>
          <strong class="level"
            ><span>Titel</span>
            <span
              v-if="formDirty(tab.tab_title, editable.tab_title)"
              :class="$style.changed"
            />
          </strong>
          <input v-model="editable.tab_title" type="text" />

          <strong class="level"
            ><span>Untertitel</span>
            <span
              v-if="formDirty(tab.tab_subtitle, editable.tab_subtitle)"
              :class="$style.changed"
            />
          </strong>
          <input v-model="editable.tab_subtitle" type="text" />

          <strong class="level"
            ><span>Typ</span>
            <span
              v-if="formDirty(tab.tab_type, editable.tab_type)"
              :class="$style.changed"
            />
          </strong>
          <input v-model="editable.tab_type" type="text" />

          <strong class="level"
            ><span>Untertyp (URL)</span>
            <span
              v-if="formDirty(tab.tab_subtype, editable.tab_subtype)"
              :class="$style.changed"
            />
          </strong>
          <input v-model="editable.tab_subtype" type="text" />

          <strong class="level"
            ><span>Position</span>
            <span
              v-if="formDirty(tab.tab_priority, editable.tab_priority)"
              :class="$style.changed"
            />
          </strong>
          <input v-model="editable.tab_priority" type="text" />
        </template>
      </rg-widget>

      <rg-widget title="Darstellung" class="w6 form">
        <template #data>
          <strong class="level"
            ><span>Icon</span>
            <span
              v-if="formDirty(tab.tab_icon, editable.tab_icon)"
              :class="$style.changed"
            />
          </strong>
          <input v-model="editable.tab_icon" type="text" />

          <a
            style="
              display: inline-block;
              transform: translateY(-15px);
              margin-bottom: 10px;
            "
            href="http://fontawesome.com/icons"
            target="_blank"
            rel="noopener noreferrer"
          >
            <small>Liste der Unterstützen Icons</small>
          </a>

          <strong class="level"
            ><span>Tag</span>
            <span
              v-if="formDirty(tab.tab_tag, editable.tab_tag)"
              :class="$style.changed"
            />
          </strong>
          <input v-model="editable.tab_tag" type="text" />

          <strong class="level"
            ><span>Unternehmensfarbe</span>
            <span
              v-if="formDirty(tab.tab_color, editable.tab_color)"
              :class="$style.changed"
            />
          </strong>
          <input v-model="editable.tab_color" type="color" />

          <br />
          <h4>Vorschau</h4>
          <br />
          <div
            class="w12"
            style="
              border: 1px solid var(--black-4);
              border-radius: 4px;
              padding: 50px 0;
              text-align: center;
            "
          >
            <fa-layers
              v-if="
                (editable.tab_icon && editable.tab_icon === 'jaltest') ||
                (!editable.tab_icon &&
                  tab.tab_icon &&
                  tab.tab_icon === 'jaltest')
              "
            >
              <fa icon="circle" transform="shrink-3 left-1.5 up-1.5" />
              <fa icon="circle" transform="right-5 down-5 shrink-10" />
            </fa-layers>
            <fa
              v-else
              :icon="editable.tab_icon || tab.tab_icon"
              class="fa-fw"
            />
            &nbsp;{{ editable.tab_title || tab.tab_title }}
            <span v-if="editable.tab_tag || tab.tab_tag" class="tag">{{
              editable.tab_tag || tab.tab_tag
            }}</span>
          </div>
        </template>
      </rg-widget>

      <rg-widget title="Berechtigungen" class="w6 form">
        <template #data>
          <strong class="level"><span>Erweiterungen</span> </strong>
          <br />
          <div
            v-for="extension in extensions"
            :key="extension.id"
            style="display: flex; gap: 10px; margin-bottom: 10px"
          >
            <div>
              <label class="switch" style="margin-right: 5px">
                <input
                  type="checkbox"
                  :checked="
                    permittedExtensions &&
                    permittedExtensions.includes(extension.id)
                  "
                  @change="changePermission(extension.id)"
                />
                <span class="slider" />
              </label>
            </div>
            <div>
              <div>
                <strong>
                  <span>{{ extension.extension_name }}</span>
                </strong>
              </div>
              <div v-if="extension.extension_description">
                {{ extension.extension_description }}
              </div>
            </div>
          </div>
        </template>
      </rg-widget>

      <rg-widget title="Optionen" class="w6 form">
        <template #data>
          <fa icon="info" class="fa-fw" />Tab Optionen lassen sich nur von
          Administratoren über die Datenbank anpassen. <br /><br />
          <pre
            style="
              overflow: auto;
              background: var(--black-1);
              border-radius: 6px;
              padding: 10px 10px;
            "
            >{{ tab.tab_options || "keine Optionen definiert" }}</pre
          >
        </template>
      </rg-widget>
    </main>
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import superagent from "superagent";
import Vue from "vue";
import { formDirty } from "@/helper.js";
import { altBackendUrl } from "../../../constants";

export default {
  name: "Machinetab",
  components: {
    "rg-widget": () => import("@/components/elements/rg-widget"),
  },

  setup(props, context) {
    let loading = ref(true);
    let tab = ref({});
    let editable = ref({});
    let extensions = ref([]);
    let permissions = ref([]);
    let permittedExtensions = ref([]);

    onMounted(() => {
      fetchExtensions();
    });

    const gqQueryTabType = `
    query Query($tabTypeId: Int) {
      TabType(id: $tabTypeId) {
        id
        tab_type
        tab_subtype
        tab_title
        tab_icon
        tab_subtitle
        tab_color
        tab_tag
        tab_priority
        createdat
        updatedat
        tab_options
      }
    }
    `;
    superagent
      .post(altBackendUrl)
      .send({
        query: gqQueryTabType,
        variables: { tabTypeId: parseInt(context.root.$route.params?.id) },
      })
      .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
      .then((res) => {
        tab.value = { ...res.body.data.TabType };
        editable.value = JSON.parse(JSON.stringify(tab.value));
        fetchPermissions();
        loading.value = false;
      })
      .catch((error) => {
        if (error.status === 426) {
          Vue.$toast.open({
            message: "Sitzung abgelaufen. Bitte neu anmelden",
            icon: "exclamation",
            type: "error",
          });
          context.root.$store.dispatch("logout").then(() => {
            context.root.$router.push("/login");
          });
        }
      });

    function saveChanges() {
      const payload = {
        tab_id: parseInt(context.root.$route.params?.id),
        tab_title: editable.value.tab_title || null,
        tab_subtitle: editable.value.tab_subtitle || null,
        tab_type: editable.value.tab_type || null,
        tab_subtype: editable.value.tab_subtype || null,
        tab_priority: parseInt(editable.value.tab_priority) || null,
        tab_icon: editable.value.tab_icon || null,
        tab_color: editable.value.tab_color || null,
        tab_tag: editable.value.tab_tag || null,
      };

      const gqSaveTabTypeMutation = `
      mutation Mutation($tabTypeId: Int!, $tabType: String, $tabSubtype: String, $tabTitle: String, $tabIcon: String,
      $tabSubtitle: String, $tabColor: String, $tabTag: String, $tabPriority: Int) {
        TabType(id: $tabTypeId, tab_type: $tabType, tab_subtype: $tabSubtype, tab_title: $tabTitle, tab_icon: $tabIcon,
        tab_subtitle: $tabSubtitle, tab_color: $tabColor, tab_tag: $tabTag, tab_priority: $tabPriority) {
          id
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqSaveTabTypeMutation,
          variables: {
            tabTypeId: payload.tab_id,
            tabType: payload.tab_type,
            tabSubtype: payload.tab_subtype,
            tabTitle: payload.tab_title,
            tabIcon: payload.tab_icon,
            tabSubtitle: payload.tab_subtitle,
            tabColor: payload.tab_color,
            tabTag: payload.tab_tag,
            tabPriority: payload.tab_priority,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(() => {
          const sorted_extensions = permittedExtensions.value.sort(
            (a, b) => a - b
          );
          Vue.$toast.open({
            message:
              "Änderungen erfolgreich gespeichert. Speichere Modul-Berechtigungen...",
            icon: "hourglass-half",
          });
          if (
            permissions?.value[0]?.relation_id &&
            parseInt(tab?.value?.id) !== permissions?.value[0]?.relation_id
          ) {
            Vue.$toast.open({
              message:
                "Integritätsprüfung fehlgeschlagen: ID des Tabs stimmt nicht mit der zugehörigen ID der Berechtigung überein. Modulberechtigungen konnten nicht gespeichert werden.",
              icon: "times-circle",
            });
            return false;
          } else {
            const mSavePermissionsMutation = `
              mutation Mutation($appPermissionId: Int, $relationType: String, $relationId: Int, $permittedExtensions: [Int]) {
                AppPermission(id: $appPermissionId, relation_type: $relationType, relation_id: $relationId, permitted_extensions: $permittedExtensions) {
                  id
                }
              }
              `;
            superagent
              .post(altBackendUrl)
              .send({
                query: mSavePermissionsMutation,
                variables: {
                  appPermissionId: permissions.value[0].id || -1,
                  relationType: "machine_tab_types",
                  relationId: tab.value.id,
                  permittedExtensions: sorted_extensions,
                },
              })
              .set(
                "Authorization",
                `Bearer ${context.root.$store.state.user.apiToken}`
              )
              .then(() => {
                Vue.$toast.open({
                  message:
                    "Die Modulberechtigungen wurden erfolgreich gespeichert. Lade neu...",
                  icon: "check",
                });
                setTimeout(() => {
                  context.root.$router.go();
                }, 2000);
              })
              .catch((err) => {
                Vue.$toast.open(err);
              });
          }
        })
        .catch((error) => {
          Vue.$toast.open({
            message:
              error.status === 426
                ? "Sitzung abgelaufen. Bitte neu anmelden"
                : "Netzwerkfehler " + (error.status || ""),
            icon: "exclamation",
            type: "error",
          });
        });
    }

    function fetchExtensions() {
      superagent
        .get(
          "https://repguide-rest.fricke-holding.workers.dev/api/legacy/extensions"
        )
        .then(({ body: extensionList }) => {
          extensions.value = extensionList.data.Extensions;
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function fetchPermissions() {
      const gqQueryPermissions = `
      query Query($relationType: String, $relationId: Int) {
        AppPermissions(relation_type: $relationType, relation_id: $relationId) {
          id
          relation_type
          relation_id
          permitted_extension
          createdat
          updatedat
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryPermissions,
          variables: {
            relationType: "machine_tab_types",
            relationId: parseInt(tab?.value?.id) || null,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: perm }) => {
          permissions.value = perm.data.AppPermissions;
          permittedExtensions.value =
            permissions.value[0]?.permitted_extensions || [];
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function changePermission(id) {
      if (!permittedExtensions.value.includes(id)) {
        permittedExtensions.value.push(id);
      } else {
        permittedExtensions.value.splice(
          permittedExtensions.value.indexOf(id),
          1
        );
      }
    }

    return {
      editable,
      tab,
      formDirty,
      saveChanges,
      loading,
      extensions,
      permittedExtensions,
      changePermission,
      fetchPermissions,
    };
  },
};
</script>

<style lang="scss" module>
.detail {
  grid-auto-flow: dense;
}

.changed {
  height: 10px;
  width: 10px;
  background: red;
  display: inline-block;
  border-radius: 5px;
  margin-left: 5px;
}
</style>
