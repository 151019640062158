<template>
  <div>
    <navigation>
      <div style="display: flex; gap: 5px">
        <div>
          <router-link to="/accounts/add">
            <rg-button
              type="is-primary"
              icon-left="user-plus"
              title="Anlegen"
              label="Anlegen"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
          </router-link>
          <rg-dropdown>
            <template #trigger>
              <rg-button
                icon="chevron-down"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              />
            </template>
            <router-link to="/accounts/add">
              <rg-button icon-left="user-plus" label="Account" />
            </router-link>
            <router-link to="/companies/add">
              <rg-button icon-left="users" label="Unternehmen" />
            </router-link>
            <router-link to="/associations/add">
              <rg-button icon-left="building" label="Unternehmensgruppe" />
            </router-link>
          </rg-dropdown>
        </div>
      </div>
    </navigation>
    <div class="rg-grid" style="padding: 15px">
      <div class="level w12">
        <h1>Vertrag anlegen</h1>
        <div>
          <rg-button
            type="is-primary"
            icon-left="save"
            label="Speichern"
            :disabled="!checkFormInput()"
            @click="addContract()"
          />
        </div>
      </div>
      <rg-widget title="Allgemein" class="w8 form">
        <template #data>
          <strong class="level"><span>GRANIT Kundennr.</span> </strong>
          <span v-if="contract.granitId && !contract.granitIdValidated">
            <fa
              icon="exclamation-triangle"
              class="fa-fw"
              style="color: hsl(348, 100%, 61%)"
            />Diese Firma existiert nicht. Eventuell muss zunächst eine angelegt
            werden.</span
          >
          <input
            v-model="data.granitId"
            type="text"
            :style="
              !contract.granitIdValidated
                ? 'border-color: hsl(348, 100%, 61%)'
                : ''
            "
            @input="dispatchSearch()"
            @keypress.enter="dispatchSearch(0)"
          />
          <strong class="level"><span>Vertragsreihenfolge</span> </strong>
          <input
            v-model="data.contractSequence"
            type="text"
            disabled
            placeholder="Wird automatisch generiert"
          />
          <strong class="level"><span>Vertragsart</span> </strong>
          <select
            v-model="data.contractType"
            class="rg-select"
            :style="
              !contract.contractType ? 'border-color: hsl(348, 100%, 61%)' : ''
            "
            :disabled="!company || !company.id"
            @change="changeKey()"
          >
            <option
              v-for="option in pricings"
              :key="option.contract_key"
              :value="option.contract_key"
            >
              {{
                `${option.contract_key} - ${option.description} (${option.invoice_amount} ${option.invoice_currency})`
              }}
            </option>
          </select>
          <div class="level">
            <strong><span>Vertragsnachfolger</span></strong>
            <div style="display: flex; align-items: center">
              <span style="margin-right: 5px">Überschreiben</span>
              <label class="switch" style="margin: 0 5px 0 10px">
                <input
                  v-model="data.overrideSuccessor"
                  type="checkbox"
                  @click="
                    data.overrideSuccessor = data.overrideSuccessor
                      ? false
                      : true
                  "
                />
                <span class="slider" />
              </label>
            </div>
          </div>
          <select
            v-model="data.contractSuccessor"
            class="rg-select"
            :disabled="!data.overrideSuccessor"
          >
            <option
              v-for="option in pricings"
              :key="option.contract_key"
              :value="option.contract_key"
            >
              {{
                `${option.contract_key} - ${option.description} (${option.invoice_amount} ${option.invoice_currency})`
              }}
            </option>
          </select>

          <div class="level">
            <strong class="level"><span>Vertragsstart</span> </strong>
            <div style="display: flex; align-items: center">
              <span style="margin-right: 5px">Vertrag kündigen</span>
              <label class="switch" style="margin: 0 5px 0 10px">
                <input
                  v-model="data.cancelContract"
                  type="checkbox"
                  :disabled="!data.contractStart && !data.contractEnd"
                  @click="
                    data.cancelContract = data.cancelContract ? false : true
                  "
                />
                <span class="slider" />
              </label>
            </div>
          </div>
          <div
            :style="
              !contractValid()
                ? 'border: 1px solid #ff3860; border-radius: 7px; padding: 5px; margin-top: 5px; margin-bottom: 5px'
                : ''
            "
          >
            <div v-if="!contractValid()" style="color: #ff3860">
              &nbsp;<strong v-if="data.contractStart"
                >Achtung! Vertragsstart sollte vor Vertragsende liegen.</strong
              >
              <strong v-else
                >Wenn ein Vertragsende gesetzt ist, muss ein Vertragsstart
                angegeben sein.</strong
              >
            </div>
            <input v-model="data.contractStart" type="datetime-local" />

            <strong class="level"><span>Vertragsende</span> </strong>
            <input
              v-model="data.contractEnd"
              type="datetime-local"
              @change="updateContractEnd()"
            />
          </div>

          <strong class="level"><span>Beitragsfrei bis</span> </strong>
          <input
            v-model="data.billingBlockedUntil"
            type="datetime-local"
            :disabled="
              !data.contractType ||
              pricings.find((x) => x.contract_key === data.contractType)
                .invoice_amount === 0
            "
            @change="updateBlockedDate()"
          />
          <strong class="level"
            ><span>Beitragsbefreiung für Folgevertrag (in Monaten)</span>
          </strong>
          <input
            v-model="data.freePeriodBoost"
            type="number"
            min="0"
            :disabled="!data.contractSuccessor"
            :placeholder="
              !data.contractSuccessor ? 'Kein Vertragsnachfolger angegeben' : ''
            "
          />

          <hr />

          <strong class="level"><span>Kommentar</span> </strong>
          <input v-model="data.contractAnnotation" type="text" />
          <div v-if="data.cancelContract">
            <strong class="level"><span>Kündigungsgrund</span> </strong>
            <textarea v-model="data.cancellationReason" cols="30" rows="10" />
          </div>
        </template>
      </rg-widget>
      <rg-widget title="Informationen" class="w4 form">
        <template #data>
          <div class="level">
            <strong><span>Firmen ID</span></strong>
            <span>{{ company ? company.id : "" }}</span>
          </div>
          <div class="level">
            <strong><span>GRANIT Kundennr.</span></strong>
            <span>{{ company ? company.granitid : "" }}</span>
          </div>
          <div class="level">
            <strong><span>Firmenname</span></strong>
            <span>{{ company ? company.company_title : "" }}</span>
          </div>
          <div class="level">
            <strong><span>Land</span></strong>
            <span>{{
              company && company.Country[0]
                ? company.Country[0].country_name
                : ""
            }}</span>
          </div>
        </template>
      </rg-widget>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, onMounted } from "@vue/composition-api";
import { formDirty } from "@/helper.js";
import dropdown from "@/components/elements/rg-dropdown";
import superagent from "superagent";
import Vue from "vue";
import { altBackendUrl } from "../../../constants";

export default {
  components: {
    // dropdown,
    "rg-dropdown": dropdown,
    "rg-widget": () => import("@/components/elements/rg-widget"),
  },
  beforeRouteLeave(to, from, next) {
    next(
      !this.data.name ||
        window.confirm("Bearbeitung aktiviert. Formular wirklich verlassen?")
    );
  },
  setup(props, context) {
    let data = ref({
      granitIdValidated: null,
      contractSequence: null,
      contractType: null,
      contractSuccessor: null,
      contractPredecessor: null,
      overrideSuccessor: false,
      cancelContract: false,
      contractDuration: null,
      contractStart: null,
      contractEnd: null,
    });
    let company = ref({});
    let contract = ref({});
    let pricings = ref([]);
    let timeout = ref({
      granitid: null,
    });

    const dispatchSearch = (ms = 2000) => {
      clearTimeout(timeout.value.granitid);
      timeout.value.granitid = setTimeout(() => {
        validateGranitId();
      }, ms);
    };

    onMounted(() => {
      if (context.root.$route.params.company_id) getGranitId();
    });

    watchEffect(() => {
      contract.value = data.value;
      if (data.value.cancelContract) {
        data.value.contractSuccessor = null;
      } else if (!data.value.overrideSuccessor) {
        data.value.contractSuccessor = ["EVAL1"].includes(
          data.value.contractType
        )
          ? "STD12"
          : pricings.value.find(
              (el) => el.contract_key === data.value.contractType
            )?.contract_key;
      }
    });

    function getGranitId() {
      const gqQueryGranitId = `query Query($id: Int) {
        Company(id: $id) {
            granitid: granitId
        }
    }`;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryGranitId,
          variables: { id: context.root.$route.params.company_id },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          data.value.granitId = res.body?.data?.Company?.granitid;
          validateGranitId();
        })
        .catch((err) => {
          console.error(err);
        });
    }

    function validateGranitId() {
      const gqQueryCompany = `query Query($granitId: String) {
        Company(granitId: $granitId) {
          id
          company_title: title
          granitid: granitId
          Country {
            country_code
            country_name
          }
        }
    }`;

      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryCompany,
          variables: { granitId: contract.value.granitId },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          company.value = res?.body?.data?.Company;
          data.value.granitIdValidated = res?.body?.data?.Company?.id
            ? true
            : false;
          data.value.companyId = res?.body?.data?.Company?.id;
          if (res?.body?.data?.Company?.id) {
            fetchPricing();
            const gqQueryContractSequences = `query Query($companyId: Int) {
              Company(id: $companyId) {
                Contracts(limit: 1000, skip: 0) {
                  nodes {
                    contract_sequence: sequence
                  }
                }
              }
            }`;

            superagent
              .post(altBackendUrl)
              .send({
                query: gqQueryContractSequences,
                variables: { companyId: parseInt(res.body?.data?.Company?.id) },
              })
              .set(
                "Authorization",
                `Bearer ${context.root.$store.state.user.apiToken}`
              )
              .then((resSeq) => {
                data.value.contractSequence =
                  Math.max.apply(
                    Math,
                    resSeq.body.data.Company.contracts.nodes.map(
                      (o) => o.contract_sequence
                    )
                  ) + 1;
              });
          } else {
            data.value.contractSequence = null;
          }
        });
    }

    function contractValid() {
      if (
        (!data.value.contractStart && !data.value.contractEnd) ||
        (data.value.contractStart && !data.value.contractEnd) ||
        data.value.contractStart < data.value.contractEnd
      ) {
        return true;
      } else {
        return false;
      }
    }

    function checkFormInput() {
      return (
        contract.value.granitIdValidated &&
        contract.value.contractType &&
        contractValid()
      );
    }

    function formatDateTime(date = Date.now()) {
      const offset = new Date(date).getTimezoneOffset() * 60000;
      const newDate = new Date(new Date(date).setMilliseconds(0, 0) - offset);
      return date ? newDate.toISOString().slice(0, -1) : "";
    }

    function updateBlockedDate() {
      data.value.billingBlockedUntil = setDateEnd(
        data.value.billingBlockedUntil,
        1,
        null,
        null,
        false
      );
    }

    function updateContractEnd() {
      data.value.contractEnd = setDateEnd(
        data.value.contractEnd,
        "0",
        null,
        null,
        false,
        true
      );
    }

    function setDateEnd(
      date,
      isoInterval,
      isoIntervalDate,
      isoIntervalTime,
      skipCurrentMonth = false,
      retainDay = false
    ) {
      isoIntervalDate = isoInterval?.substr(0, isoInterval.indexOf("T"));
      isoIntervalTime = isoInterval?.substr(
        isoInterval.indexOf("T"),
        isoInterval.length
      );
      if (!isoInterval) {
        if (!data.value.contractEnd) return "";
        return formatDateTime(
          new Date(data.value.contractEnd).setHours(23, 59, 59)
        );
      }
      const oIntervalDate = {};

      isoIntervalDate.match(/(\d+)([A-Za-z])/g)?.map((el) => {
        let iPart = el.match(/[A-Za-z]+/g);
        let iValue = el.match(/\d+/g);
        oIntervalDate[iPart] = iValue[0];
      });
      const oIntervalTime = {};
      isoIntervalTime.match(/(\d+)([A-Za-z])/g)?.map((el) => {
        let iPart = el.match(/[A-Za-z]+/g);
        let iValue = el.match(/\d+/g);
        oIntervalTime[iPart] = iValue[0];
      });
      let interval =
        parseInt(oIntervalDate.Y || 0) * 12 + parseInt(oIntervalDate.M || 0);
      if (interval && interval <= 0) {
        interval =
          (oIntervalDate.D && parseInt(oIntervalDate.D) > 0) ||
          (oIntervalTime.H && parseInt(oIntervalTime.H) > 0) ||
          (oIntervalTime.M && parseInt(oIntervalTime.M) > 0) ||
          (oIntervalTime.S && parseInt(oIntervalTime.S) > 0);
      }
      const isoDate = new Date(date).toUTCString();
      return (
        formatDateTime(
          new Date(
            new Date(isoDate).getFullYear(),
            interval
              ? new Date(isoDate).getMonth() +
                interval +
                (skipCurrentMonth ? 1 : 0)
              : new Date(isoDate).getMonth(),
            retainDay ? new Date(isoDate).getDate() : 0,
            23,
            59,
            59
          )
        ) || ""
      );
    }
    function changeKey() {
      data.value.contractSuccessor = !data.value.overrideSuccessor
        ? data.value.contractType === "EVAL1"
          ? "STD12"
          : data.value.contractType
        : data.value.contractSuccessor;
      data.value.contractStart = ["EVAL1", "EVALA"].includes(
        data.value.contractType
      )
        ? null
        : data.value.contractStart || formatDateTime();
      if (
        data.value.contractType &&
        data.value.contractStart &&
        pricings.value.find((x) => x.contract_key === data.value.contractType)
          .contract_duration
      ) {
        data.value.contractDuration = pricings.value.find(
          (x) => x.contract_key === data.value.contractType
        ).contract_duration;
        data.value.contractEnd = setDateEnd(
          data.value.contractStart,
          data.value.contractType
            ? pricings.value.find(
                (x) => x.contract_key === data.value.contractType
              ).contract_duration
            : "0",
          null,
          null,
          data.value.contractType && data.value.contractType === "EVAL1"
            ? true
            : false
        );
      } else {
        data.value.contractEnd = null;
      }
    }

    function addContract() {
      const payload = {
        companyId: parseInt(company.value.id),
        contractAnnotation: contract.value.contractAnnotation || null,
        contractType:
          pricings.value.find(
            (x) => x.contract_key === contract?.value?.contractType
          )?.contract_key || null,
        invoiceAmount:
          pricings.value.find(
            (x) => x.contract_key === contract?.value?.contractType
          )?.invoice_amount || 0,
        invoiceCurrency:
          pricings.value.find(
            (x) => x.contract_key === contract?.value?.contractType
          )?.invoice_currency || null,
        invoiceItemNumber:
          pricings.value.find(
            (x) => x.contract_key === contract?.value?.contractType
          )?.item_number || null,
        contractSuccessor: contract.value.contractSuccessor || null,
        contractStart: contract.value.contractStart
          ? new Date(contract.value.contractStart).toISOString()
          : null,
        contractEnd: contract.value.contractEnd
          ? new Date(contract.value.contractEnd).toISOString()
          : null,
        billingBlockedUntil: contract.value.billingBlockedUntil
          ? new Date(
              formatDateTime(contract.value.billingBlockedUntil)
            ).toISOString()
          : null,
        freePeriodBoost: contract.value.freePeriodBoost
          ? `P0Y${contract.value.freePeriodBoost}M0DT0H0M0S`
          : null,
        contractCancellationPeriodEnd:
          contract.value.cancellationPeriodEnd || null,
        cancellationReason: contract.value.cancellationReason || null,
      };

      const createContractMutation = `mutation Mutation($contractId: Int!, $companyId: Int,
          $contractType: String, $contractSuccessor: String, $contractStart: DateTime,
          $contractEnd: DateTime, $contractCancellationPeriodEnd: DateTime, $contractAnnotation: String,
          $invoiceAmount: Float, $invoiceCurrency: String, $invoiceItemNumber: String,
          $billingBlockedUntil: DateTime, $freePeriodBoost: Duration, $cancellationReason: String) {
            Contract(contractId: $contractId, company_id: $companyId, contract_type: $contractType,
            contract_successor: $contractSuccessor, contract_start: $contractStart, contract_end: $contractEnd,
            contract_cancellation_period_end: $contractCancellationPeriodEnd, contract_annotation: $contractAnnotation,
            invoice_amount: $invoiceAmount, invoice_currency: $invoiceCurrency, invoice_item_number: $invoiceItemNumber,
            billing_blocked_until: $billingBlockedUntil, free_period_boost: $freePeriodBoost, cancellation_reason: $cancellationReason) {
              id
              company_id
            }
          }
        `;

      superagent
        .post(altBackendUrl)
        .send({
          query: createContractMutation,
          variables: {
            contractId: -1,
            companyId: payload.companyId,
            contractType: payload.contractType,
            contractSuccessor: payload.contractSuccessor,
            contractStart: payload.contractStart,
            contractEnd: payload.contractEnd,
            contractCancellationPeriodEnd:
              payload.contractCancellationPeriodEnd,
            contractAnnotation: payload.contractAnnotation,
            invoiceAmount: payload.invoiceAmount,
            invoiceCurrency: payload.invoiceCurrency,
            invoiceItemNumber: payload.invoiceItemNumber,
            billingBlockedUntil: payload.billingBlockedUntil,
            freePeriodBoost: payload.freePeriodBoost,
            cancellationReason: payload.cancellationReason,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resContract) => {
          if (
            !resContract.body?.data?.errors &&
            resContract.body?.data?.Contract
          ) {
            Vue.$toast.open({
              icon: "check",
              message: "Der Vertrag wurde erfolgreich angelegt.",
            });
            setTimeout(() => {
              context.root.$router.push({
                path: `/companies/${resContract?.body?.data?.Contract?.company_id}/contracts`,
              });
            }, 2000);
          } else {
            Vue.$toast.open({
              icon: "times-circle",
              type: "error",
              message: "FEHLER: Der Vertrag konnte nicht angelegt werden.",
            });
            console.table(payload);
            console.log(resContract.body?.data?.errors);
          }
        })
        .catch((err) => {
          Vue.$toast.open({
            icon: "times-circle",
            type: "error",
            message: "FEHLER: Der Vertrag konnte nicht angelegt werden.",
          });
          console.table(payload);
          console.error(err?.response?.body?.errors);
        });
    }

    function fetchPricing() {
      const gqQueryPicing = `
      query Pricings {
        Pricings(limit: 10000, skip: 0) {
          contract_key
          description
          invoice_amount
          invoice_currency
          item_number
          contract_duration
          predecessor
          country_key
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryPicing,
          variables: {
            languageIds: null,
            coutryIds: null,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: pricingList }) => {
          pricings.value = pricingList.data.Pricings.filter(
            (el) =>
              !el.country_key ||
              el.country_key === company?.value?.Country[0]?.country_code
          );
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    return {
      data,
      formDirty,
      addContract,
      checkFormInput,
      validateGranitId,
      company,
      contract,
      formatDateTime,
      setDateEnd,
      changeKey,
      updateBlockedDate,
      updateContractEnd,
      pricings,
      dispatchSearch,
      contractValid,
    };
  },
};
</script>

<style lang="scss" module>
.detail {
  grid-auto-flow: dense;
}

.changed {
  height: 10px;
  width: 10px;
  background: red;
  display: inline-block;
  border-radius: 5px;
  margin-left: 5px;
}
</style>
