var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{class:[
    _vm.$style.version_entry,
    _vm.index === 0 ? _vm.$style.version_entry__newest : null ],staticStyle:{"margin-bottom":"5px"}},[_c('span',{style:(_vm.version.publishedat && _vm.version.id == _vm.newestLive
        ? 'color:hsl(141, 53%, 53%)'
        : '')},[_c('fa',{attrs:{"icon":"file"}})],1),_c('div',{class:_vm.$style.version_entry__info},[_c('header',{staticClass:"level"},[_c('div',[_c('h4',{style:(_vm.version.publishedat && _vm.version.id == _vm.newestLive
              ? 'color:hsl(141, 53%, 53%)'
              : '')},[_vm._v(" Version "+_vm._s(_vm.count - _vm.index)+" (ID: "+_vm._s(_vm.version.id)+") ")])]),(!_vm.version.readonly || _vm.newestLive == _vm.version.id)?_c('rg-button',{attrs:{"icon-left":"copy","label":"Duplizieren"},on:{"click":function($event){return _vm.$emit(
            'duplicate',
            _vm.newestLive == _vm.version.id ? { slug: _vm.slug } : { hash: _vm.version.id }
          )}}}):_vm._e(),(
          _vm.$store.state.user.tier === 'administration' && !_vm.version.readonly
        )?_c('rg-button',{staticClass:"is-danger",staticStyle:{"margin-left":"3px"},attrs:{"icon-left":"upload","label":"LIVE"},on:{"click":function($event){return _vm.$emit('sendlive', _vm.version.id)}}}):_vm._e()],1),_c('br'),_c('div',{staticClass:"level"},[_c('span',{staticStyle:{"flex":"1"}},[_c('fa',{staticClass:"fa-fw",attrs:{"icon":_vm.version.updatedat ? 'calendar-alt' : 'calendar-times'}}),_c('span',[_vm._v(_vm._s(_vm.version.updatedat ? new Date(_vm.version.updatedat).toLocaleString() : "älter als 20.4.2020"))])],1),_c('span',{staticStyle:{"flex":"1"}},[_c('fa',{staticClass:"fa-fw",attrs:{"icon":_vm.version.author ? 'user' : 'user-ninja'}}),_vm._v(" "+_vm._s(_vm.version.author || "Autor unbekannt")+" ")],1)]),_c('div',{staticClass:"level"},[_c('span',{staticStyle:{"flex":"1"}},[_c('span',{style:(_vm.version.publishedat && _vm.version.id == _vm.newestLive
              ? 'color:hsl(141, 53%, 53%)'
              : '')},[_c('fa',{staticClass:"fa-fw",attrs:{"icon":"circle"}}),_vm._v(" Live seit "+_vm._s(_vm.version.publishedat ? new Date(_vm.version.publishedat).toLocaleString() : "-")+" ")],1)])]),_c('hr'),_c('div',{staticClass:"flex-right",staticStyle:{"gap":"5px"}},[(_vm.version.editor_version !== 'v2')?_c('router-link',{attrs:{"to":_vm.preview,"target":"_blank"}},[_c('rg-button',{attrs:{"icon":"eye","title":"Rep.Guide Vorschau"}})],1):_vm._e(),(!_vm.version.readonly && _vm.version.editor_version !== 'v2')?_c('router-link',{attrs:{"to":_vm.link}},[_c('rg-button',{attrs:{"icon-left":"pen","label":"Bearbeiten (Admin-Tool)"}})],1):_vm._e(),(!_vm.version.readonly)?_c('a',{attrs:{"href":("https://rep.guide/de/admin/techdata/" + (_vm.version.id)),"target":"_blank"}},[_c('rg-button',{attrs:{"type":"is-primary","icon-left":"pen","icon-right":"external-link-alt","label":"Bearbeiten"}})],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }