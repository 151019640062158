<template>
  <section>
    <div class="rg-grid">
      <div class="w6" style="overflow-x: auto">
        <h2>Übersicht</h2>
        <pre>{{ machine }}</pre>
      </div>
      <div v-if="family.parent && family.children" class="w6">
        <h2>Vererbung</h2>
        <machine-compact
          :active="machine.id === family.parent.id"
          :machine="family.parent"
        />
        <div
          v-for="child in family.children"
          :key="child.id"
          style="display: grid; grid-template-columns: 30px 1fr"
        >
          <div style="display: inline-flex; align-items: center; opacity: 0.5">
            <fa icon="level-up-alt" class="fa-fw fa-lg fa-rotate-90" />
          </div>
          <machine-compact :machine="child" :active="machine.id === child.id" />
        </div>
      </div>
      <div v-else class="w6">
        <h2>Vererbung</h2>
        <p>Keine Vererbung</p>
      </div>
    </div>
  </section>
</template>

<script>
import machine_compact from "@/components/elements/rg-machine-compact";
import { ref, watchEffect } from "@vue/composition-api";
import superagent from "superagent";
import { altBackendUrl } from "../../../constants";

export default {
  components: { "machine-compact": machine_compact },
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    let machine = ref({});
    let family = ref({
      parent: null,
      children: null,
    });
    watchEffect(() => {
      machine.value = props.data;
      if (machine.value.slug !== props.data.slug) fetchHeredity();
    });

    async function fetchHeredity() {
      const gqQueryParent = `
      query Query($machineId: Int) {
        Machine(id: $machineId) {
          id
          title
          slug
        }
      }
      `;
      const gqQueryChildren = `
      query Query($parentid: Int) {
        Machines(parentId: $parentid) {
          id
          slug
          title
        }
      }
      `;
      if (!machine.value.parentID && !machine.value.isParent) {
        return;
      } else {
        family.value.parent = machine.value.isParent
          ? machine.value
          : await superagent
              .post(altBackendUrl)
              .send({
                query: gqQueryParent,
                variables: { machineId: parseInt(machine.value.parentID) },
              })
              .set(
                "Authorization",
                `Bearer ${context.root.$store.state.user.apiToken}`
              )
              .then((res) => {
                return res.body.data.Machine;
              });
        family.value.children = await superagent
          .post(altBackendUrl)
          .send({
            query: gqQueryChildren,
            variables: {
              parentid: parseInt(machine.value.parentID || machine.value.id),
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            return res.body.data.Machines;
          });
      }
    }

    fetchHeredity();

    return { machine, family };
  },
};
</script>
