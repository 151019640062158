<template>
  <div class="rg-grid">
    <div class="level w12 discard">
      <h1 style="color: red">Hier gibt es nichts zu sehen...</h1>
    </div>
  </div>
</template>

<script>
// import { ref, watchEffect, computed } from "@vue/composition-api";
// import superagent from "superagent";
// import { api } from "@/helper.js";
// import Vue from "vue";

export default {
  components: {
    // "rg-widget": () => import("@/components/elements/rg-widget"),
    // rgTable: () => import("@/components/elements/Table"),
  },
  props: { data: { type: Object, default: () => {} } },
  // beforeRouteLeave(to, from, next) {
  //   !this.editable.form
  //     ? next()
  //     : window.confirm("Bearbeitung aktiviert. Formular wirklich verlassen?")
  //     ? next()
  //     : next(false);
  // },
  setup() {
    return {};
  },
};
</script>

<style>
table.permissionTable {
  overflow-x: auto;
}
table.permissionTable tr:nth-child(even) {
  background-color: #f2f2f2;
}
.is-danger {
  color: hsl(348, 100%, 61%);
}
</style>

<style lang="scss" module>
.detail {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-flow: dense;
  grid-auto-columns: 1fr 1fr;
}

.discard {
  overflow-x: auto;
}
</style>
