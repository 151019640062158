<template>
  <div :style="cssLevelVars">
    <navigation>
      <div
        v-if="inputArray.length"
        style="display:flex;align-items:center"
      >
        <rg-dropdown
          stay
          :width="500"
          position="bottom-center"
        >
          <template #trigger>
            <rg-button
              :notice="audit.errors"
              title="Formular auf Fehler überprüfen"
              style="margin-right:5px"
              icon-left="tasks"
              icon-right="chevron-down"
              label="Prüfen"
            />
          </template>
          <rg-button
            title="Fehlerüberprüfung starten"
            style="justify-content:center"
            label="Formular auf Fehler überprüfen"
            type="is-primary"
            @click="saveValidation(true)"
          /><br>
          <div v-if="audit.length">
            <hr>
            <p style="text-align:center">
              <strong v-if="audit.errors">{{ audit.errors }} Fehler gefunden, davon {{ audit.fatal }} <dfn title="Schwerwiegende Fehler verhindern das Speichern">schwerwiegend</dfn>.</strong>
            </p>
            <div
              v-if="!audit.errors"
              style="text-align:center"
            >
              <div :class="$style.audit_check">
                <fa
                  class="fa-fw"
                  icon="check"
                />
              </div><br>
              <strong>Keine Fehler gefunden</strong><br><br>
            </div>
            <template v-for="line in audit">
              <p
                v-if="line.errors.length"
                :key="line.id"
              >
                <fa
                  v-if="line.fatal"
                  class="fa-fw"
                  icon="exclamation"
                />&nbsp;
                <a
                  v-else
                  @click="theElderScrolls(line.line)"
                >
                  <strong>Zeile {{ line.line+1 }}</strong>: Element "{{ line.title }}" hat {{ line.fatal ? 'schwerwiegende' : '' }} Fehler.
                </a>
                <ul style="margin:0">
                  <li
                    v-for="error in line.errors"
                    :key="error"
                  >
                    {{ error }}
                  </li>
                </ul>
              </p>
            </template>
            <p style="text-align:center; opacity:.5">
              {{ audit.length }} {{ audit.length === 1 ? 'Zeile' : 'Zeilen' }} in {{ audit.performance }}ms überprüft.
            </p>
          </div>
          <div v-else>
            <hr>
            <p>
              <strong><fa
                icon="info"
                class="fa-fw"
              />&nbsp;Aktuell werden folgende Fehler erkannt:</strong>
              <ul>
                <li
                  v-for="rule in rules"
                  :key="rule"
                  v-html="rule"
                />
              </ul>
            </p>
          </div>
        </rg-dropdown>

        <input
          type="text"
          :style="{
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            width: '75px',
            borderRight: 'none',
            lineHeight: '18px',
            fontFamily: 'monospace',
            fontSize: '16px'
          }"
          :value="selectedNew"
          @keyup.enter="jumpTo()"
          @input="(e) => {
            if (e.target.value.match(/[^0-9]/g)) {
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
            }
            if (e.target.value > inputArray.length) {
              e.target.value = inputArray.length;
            }
            selectedNew = e.target.value
          }"
        >
        <rg-button
          :title="selectedNew ? `Zu Element ${selectedNew} scrollen` : ''"
          style="margin-right:5px; border-top-left-radius: 0px; border-bottom-left-radius: 0px"
          :icon="selectedNew-1 > selected ? 'long-arrow-alt-down' : selectedNew-1 < selected ? 'long-arrow-alt-up' : 'arrows-alt-v'"
          @click="jumpTo()"
        />
        <rg-dropdown
          stay
          :width="420"
          :height="690"
          icon="list-ol"
          triggertitle="Verzeichnisse"
          style="margin-right:5px"
        >
          <p><strong>Inhaltsverzeichnisse</strong></p>
          <div :class="$style.tabswitch">
            <rg-button
              :type="(directories.current === 'groups' ? 'is-primary' : '')"
              label="Gruppenstruktur"
              @click="directories.set('groups')"
            />
            <rg-button
              :type="(directories.current === 'marked' ? 'is-primary' : '')"
              label="Markierte Elemente"
              @click="directories.set('marked')"
            />
          </div>
          <hr>
          <template v-if="directories.current === 'groups'">
            <p
              v-if="!directories.groups.length"
              style="margin-top: 10px"
              class="text-center"
            >
              Keine Gruppen im Formular gefunden
            </p>
            <div>
              <div
                v-for="e in directories.groups"
                :key="e.id"
                :class="[$style.tocEntry, 'list-group-item']"
              >
                <div>
                  <div
                    :class="$style.techinput_level"
                    :title="'Level '+e.level"
                  >
                    <span
                      v-for="index in e.level"
                      :key="index"
                      :class="$style.techinput_level__dot"
                      :style="'background: '+getColor(index-1)"
                    ><br></span>
                    <span
                      :class="$style.techinput_level__dot__line"
                      :style="'background:'+e.color"
                    >{{ e.line+1 }}</span>
                  </div>
                  <a
                    :style="!e.title ? 'color:red' : ''"
                    @click.prevent="theElderScrolls(e.line)"
                  >{{ e.title || 'Gruppe ohne Titel' }}</a>
                </div>
                <div>
                  <div
                    v-for="child in e.children"
                    :key="child.id"
                    :class="$style.tocEntry"
                  >
                    <div
                      :class="$style.techinput_level"
                      :title="'Level '+child.level"
                    >
                      <span
                        v-for="index in child.level"
                        :key="index"
                        :class="$style.techinput_level__dot"
                        :style="'background: '+getColor(index-1)"
                      ><br></span>
                      <span
                        :class="$style.techinput_level__dot__line"
                        :style="'background:'+child.color"
                      >{{ child.line+1 }}</span>
                    </div>
                    <a
                      :style="!child.title ? 'color:red' : ''"
                      @click.prevent="theElderScrolls(child.line)"
                    >{{ child.title || 'Gruppe ohne Titel' }}</a>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div
            v-else
            style="margin-top: 10px"
          >
            <p
              v-if="!directories.marked.length"
              class="text-center"
            >
              Keine markierten Elemente im Formular gefunden
            </p>
            <div
              v-for="e in directories.marked"
              :key="e.id"
              :class="$style.flagsAndComments"
            >
              <fa
                icon="flag"
                :class="[(e.flagged ? $style.active : null), 'fa-fw']"
                :title="e.flagged ? 'Element markiert' : 'Element nicht markiert'"
              />
              &nbsp;
              <fa
                icon="comment"
                :class="[(!!e.comment ? $style.active : null), 'fa-fw']"
                :title="e.comment"
              />
              &nbsp;
              <span
                :class="$style.techinput_level__dot__line"
                :style="'background: '+getColor(e.level)"
              >{{ e.line+1 }}</span>
              &nbsp;
              <a
                :style="!e.title ? 'color:red' : ''"
                @click.prevent="theElderScrolls(e.line)"
              >{{ e.title || 'Gruppe ohne Titel' }}</a>
            </div>
          </div>
        </rg-dropdown>
      </div>
      <div v-else>
        Vorschau wird geladen…
      </div>
    </navigation>
    <main>
      <section>
        <h1>
          Vorschau <router-link :to="'/machines/'+machine.id+'/tech'">
            {{ machine.title }}
          </router-link>
        </h1>
        <br>
        <div
          class="rg-grid"
          style="margin-bottom: 100px;"
        >
          <widget
            class="w4"
            title="Maschinendaten"
          >
            <template #meta>
              <span :style="(machine.hastechdata ? 'color:#1575f7' : 'opacity:.5')"><fa
                class="fa-fw fa-lg"
                icon="file"
              /></span>
              <span :style="(machine.hasparts ? 'color:#1575f7' : 'opacity:.5')"><fa
                class="fa-fw fa-lg"
                icon="cogs"
              /></span>
              <span :style="(machine.hasserviveplan ? 'color:#1575f7' : 'opacity:.5')"><fa
                class="fa-fw fa-lg"
                icon="clipboard-check"
              /></span>
            </template>
            <template #data>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong style="font-size: 1.2em">{{ machine.title || 'Lädt…' }}</strong>
              </div>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="toggle-on"
                />&nbsp;Status</strong>
                <strong style="font-size: 1.2em">{{ machine.status !== undefined ? '"'+machine.status+'"' : 'Lädt…' }}</strong>
              </div>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="eye"
                />&nbsp;Kundenansicht</strong>
                <a
                  :href="'https://rep.guide/de/machine/'+machine.slug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong style="font-size: 1.2em">Zu Rep.Guide<span style="opacity:.5; margin-left:5px"><fa
                    icon="external-link-alt"
                    class="fa-fw"
                  /></span></strong>
                </a>
              </div>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  :icon="['fab', 'gitlab']"
                />&nbsp;Gitlab Ticket</strong>
                <router-link
                  v-if="!machine.gitlabticket"
                  :to="'/machines/'+machine.id+'/type'"
                >
                  <strong style="font-size: 1.2em">Verknüpfen<span
                    title="Noch keine ID hinterlegt"
                    style="opacity:.5"
                  ><fa
                    icon="exclamation-triangle"
                    class="fa-fw"
                  /></span></strong>
                </router-link>
                <a
                  v-else
                  :href="'https://frixlab.fricke.de/innovationlab/repguide/redaktion/-/issues/'+machine.gitlabticket"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong style="font-size: 1.2em"><span style="opacity:.5"><fa
                    icon="hashtag"
                    class="fa-fw"
                  /></span>{{ machine.gitlabticket }}<span style="opacity:.5; margin-left:5px"><fa
                    icon="external-link-alt"
                    class="fa-fw"
                  /></span></strong>
                </a>
              </div>
              <div class="level">
                <strong :style="!language.id ? 'color:red' : ''"><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="globe"
                />&nbsp;Eingabesprache</strong>
                <span
                  v-if="languages.find(lang => lang.id === language.id)"
                  style="font-size: 1.2em"
                >
                  {{ languages.find(lang => lang.id === language.id).language_name }}
                  ({{ languages.find(lang => lang.id === language.id).language_code }})
                </span>
              </div>
            </template>
          </widget>
          <widget class="w4">
            Aktuelle Version
            <template #data>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="hashtag"
                />&nbsp;ID</strong>
                <strong style="font-size: 1.2em">{{ versionmeta.id }}</strong>
              </div>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="user"
                />&nbsp;Erstellt von</strong>
                <span style="font-size: 1.2em">{{ versionmeta.author || 'Autor unbekannt' }}</span>
              </div>
              <div class="level">
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="calendar-alt"
                />&nbsp;Letzte Änderung</strong>
                <span style="font-size: 1.2em">{{ versionmeta.timestamp ? new Date(versionmeta.timestamp).toLocaleString() : 'vor dem 20.4.2020' }}</span>
              </div>
              <div class="level">
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="comment"
                />&nbsp;Kommentar</strong>
              </div>
              <div class="level">
                <span style="font-size: 1.2em">
                  <textarea
                    v-model="comment"
                    cols="50"
                    rows="5"
                  />
                </span>
              </div>
            </template>
          </widget>
          <widget class="w4">
            Statistiken
            <template #data>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="folder"
                />&nbsp;Gruppen / Untergruppen</strong>
                <span style="font-size: 1.2em"><strong>{{ stats.groups }}</strong><span>/{{ stats.subgroups }}</span></span>
              </div>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="server"
                />&nbsp;Technische Daten</strong>
                <span style="font-size: 1.2em"><strong>{{ stats.techdata }}</strong><span style="opacity:.5">/{{ stats.all }}</span></span>
              </div>
              <div
                class="level"
                style="margin-bottom:10px"
              >
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="wrench"
                />&nbsp;Anzugswerte</strong>
                <span style="font-size: 1.2em"><strong>{{ stats.torque }}</strong><span style="opacity:.5">/{{ stats.all }}</span></span>
              </div>
              <div class="level">
                <strong><fa
                  class="fa-fw"
                  style="opacity:.5"
                  icon="oil-can"
                />&nbsp;Füllmengen</strong>
                <span style="font-size: 1.2em"><strong>{{ stats.filling }}</strong><span style="opacity:.5">/{{ stats.all }}</span></span>
              </div>
            </template>
          </widget>
        </div>

        <p
          v-if="!previewForm.length"
          style="padding: 200px 0; text-align: center;"
        >
          Die Version {{ loading ? 'lädt…' : ' ist leer' }}
        </p>

        <div
          tag="div"
          :class="[$style.techinput_container, $style.hide_control]"
        >
          <section
            v-for="(element, index) in previewForm"
            :key="element.id"
            :class="$style.techinput_wrap"
            :style="'--level: '+(element.level * 50)+'px'"
          >
            <rg-element-level :level="element.level" />
            <component
              :is="element.is"
              :ref="element.line"
              :index="index"
              :element="element"
              :selected="selected === element.line"
              @select="selected = $event"
              @copy="copy(element, $event)"
            />
          </section>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import Vue from "vue";
import { ref, computed, watch } from "@vue/composition-api";
import {
  levelColors,
  getColor,
  generateStatus,
  units,
  specialChars,
} from "@/helper.js";
import { validate, rules } from "@/validate.js";
import superagent from "superagent";
import { uid } from "uid";

import controlBubble from "@/components/elements/tech_input/rg-control-bubble";
import elementLevel from "@/components/elements/tech_input/rg-element-level";
import attribute from "@/components/elements/tech_preview/rg-attribute";
import group from "@/components/elements/tech_preview/rg-group";
import dropdown from "@/components/elements/rg-dropdown";
import widget from "@/components/elements/rg-widget";
import { altBackendUrl } from "../../../constants";

export default {
  name: "TechdataInput",
  components: {
    "rg-dropdown": dropdown,
    "rg-control-bubble": controlBubble,
    "rg-element-level": elementLevel,
    attribute,
    group,
    widget,
  },
  setup(_, context) {
    const id = context.root.$route.params.id;

    const cssLevelVars = computed(() =>
      levelColors.map((c, i) => `--level-${i + 1}:${c};`).join("")
    );

    let version = context.root.$route.params.version;
    let versionmeta = ref("");
    let loading = ref(true);
    let machine = ref([]);
    let inputArray = ref([]);
    let childrenCount = ref(null);
    let selected = ref(-1);
    let selectedNew = ref(null);
    let audit = ref([]);
    let languages = ref([]);
    let language = ref({});
    let comment = ref(null);

    watch(
      () => selected.value,
      (newVal, oldVal) => {
        if (newVal !== oldVal) selectedNew.value = newVal + 1;
      }
    );

    const stats = computed(() => {
      let tc = 0,
        tq = 0,
        fl = 0,
        gp = 0,
        sgp = 0;
      inputArray.value.forEach((e) => {
        if (e.category === "Technische Daten") {
          tc++;
        }
        if (e.category === "Anzugswerte") {
          tq++;
        }
        if (e.category === "Füllmengen") {
          fl++;
        }
        if (e.is === "group" && e.level === 0) {
          gp++;
        } else if (e.is === "group") {
          sgp++;
        }
      });
      return {
        techdata: tc,
        torque: tq,
        filling: fl,
        groups: gp,
        subgroups: sgp,
        all: tc + fl + tq,
      };
    });

    const directories = ref({
      current: "groups",
      groups: computed(() => {
        let list = [];
        inputArray.value
          .filter((e) => e.is === "group")
          .forEach((element) => {
            if (list.length && list[list.length - 1].level < element.level) {
              list[list.length - 1].children.push(
                directories.value.generateEntry({ ...element })
              );
            } else {
              list.push(directories.value.generateEntry({ ...element }));
            }
          });
        return list;
      }),
      marked: computed(() => {
        let list = [];
        inputArray.value
          .filter((e) => e.flagged || e.comment)
          .forEach((element) => {
            list.push(directories.value.generateEntry({ ...element }));
          });
        return list;
      }),
      generateEntry(element) {
        return { ...element, color: getColor(element.level), children: [] };
      },
      set: (key) => {
        directories.value.current = ["groups", "marked"].includes(key)
          ? key
          : directories.value.current;
      },
    });

    let previewForm = computed(() => {
      inputArray.value.forEach((e, i) => {
        e.line = i;
      });
      return inputArray.value;
    });

    getLanguages();

    const machineQuery = `query Query($machineId: Int) {
      Machine(id: $machineId) {
        id
        slug
        title
        hasTechdata
        hasServiceplan
        hasParts
        status
        gitlabticket
      }
    }`;

    superagent
      .post(altBackendUrl)
      .send({
        query: machineQuery,
        variables: {
          machineId: parseInt(id),
        },
      })
      .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
      .then((res) => {
        machine.value = res.body?.data?.Machine;

        // FIXME: load unpublished WIP Version
        const machineWIPQuery = `query Query($machineWiPsMachineId: Int) {
          MachineWIPs(machineID: $machineWiPsMachineId) {
            id
            author
            machine_data
            machine_id
            publishedat
            createdat
            updatedat
            source_language_id
            wip_comment
          }
        }`;

        superagent
          .post(altBackendUrl)
          .send({
            query: machineWIPQuery,
            variables: {
              machineWiPsMachineId: parseInt(machine.value.id),
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((versionList) => {
            const version =
              versionList.body?.data?.MachineWIPs?.find(
                (v) => v.id == context.root.$route.params.version
              ) || [];
            console.log(version);

            language.value = {
              ...language.value,
              id: version?.source_language_id,
            };
            comment.value = version?.wip_comment;

            if (version.machine_data?.data || version.machine_data.length) {
              flatten(version.machine_data.data || version.machine_data);
            } else {
              Vue.$toast.open({
                message: "Version korrupt",
                type: "error",
                icon: "exclamation",
              });
            }

            delete version.machine_data;

            versionmeta.value = version;
          });
      })
      .catch((error) => {
        Vue.$toast.open(generateStatus(error.status));
      });

    function getLanguages() {
      const gqQueryAppLanguages = `
      query Query {
        AppLanguages {
          language_name
          id
          language_code
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryAppLanguages,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          languages.value = res.body.data.AppLanguages.sort((a, b) => {
            return a.language_name.localeCompare(b.language_name);
          });
        });
    }

    function generateElement(type = "attribute", level = 0, defaults) {
      const TYPES = [
        "string",
        "markdown",
        "html",
        "widget",
        "seperator",
        "link",
        "media",
      ];
      if (!["attribute", "group"].includes(type)) {
        return;
      }
      const baseElement = {
        id: uid(),
        is: type,
        line: -1,
        level,
        title: defaults.title || "",
        flagged: defaults.flagged || false,
        comment: defaults.comment || "",
        showComment: false,
        nanoid: defaults.nanoid || null,
      };

      return type === "group"
        ? {
            ...baseElement,
            description: defaults.description || "",
            showDesc: false,
          }
        : {
            ...baseElement,
            type: TYPES.includes(defaults.type) ? defaults.type : "string",
            unit: defaults.unit || "",
            value: defaults.value || "",
            category: defaults.category || "Technische Daten",
          };
    }

    function flatten(initial, level = 0) {
      initial.forEach((element) => {
        if (Object.keys(element)[0] === "group") {
          inputArray.value.push(generateElement("group", level, element.group));
        } else if (Object.keys(element)[0] === "attribute") {
          inputArray.value.push(
            generateElement("attribute", level, element.attribute)
          );
        }
        if (element.group?.childs.length) {
          flatten(element.group.childs, level + 1);
        }
      });
    }

    function theElderScrolls(line = -1) {
      if (typeof line === "object") {
        line = previewForm.value.findIndex((e) => e.id === line.id);
      }
      if (line >= 0) selected.value = line;
      context.refs[selected.value]?.[0]?.$el?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    function jumpTo() {
      if (isNaN(selectedNew.value)) return;
      selectedNew.value = Math.max(selectedNew.value, 1);
      selectedNew.value = Math.min(selectedNew.value, inputArray.value.length);
      selected.value = selectedNew.value - 1;
      Vue.nextTick(() => {
        context.refs[selectedNew.value]?.[0]?.$el?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      });
    }

    function copy(element) {
      let data = [{ ...element }];
      delete data[0].id;

      if (element.is === "group") {
        for (
          let i = inputArray.value.indexOf(element) + 1;
          i < inputArray.value.length;
          i++
        ) {
          if (inputArray.value[i].level <= element.level) {
            break;
          } else {
            data.push({ ...inputArray.value[i] });
            delete data[data.length - 1].id;
          }
        }
      }
      context.root.$store.commit("setCopyPasta", data);
      Vue.$toast.open({
        message: (element.is === "group" ? "Gruppe " : "Element ") + "kopiert",
        icon: "copy",
      });
    }

    function saveValidation() {
      return new Promise((resolve, reject) => {
        audit.value = [];
        validate([...inputArray.value])
          .then((result) => {
            Vue.$toast.open({
              message: "Überprüfung abgeschlossen",
              icon: "info",
            });
            audit.value = result;
            resolve(result);
          })
          .catch((error) => {
            Vue.$toast.open({
              message:
                "Vorgang abgebrochen: Schwerwiegender Fehler in Zeile " +
                error.line +
                "." +
                "Sollte die Zeile fehlerfrei sein, wende dich bitte an einen Admin.",
              icon: "exclamation",
              duration: 10000,
              type: "error",
            });
            console.error(error.error);
            console.error(error.element);
            reject(error);
          });
      });
    }

    function clipboardSuccessHandler({ value }) {
      Vue.$toast.open({
        message: `'${value}' erfolgreich kopiert`,
        icon: "clipboard-check",
      });
    }

    return {
      directories,
      childrenCount,
      machine,
      audit,
      rules,
      copy,
      saveValidation,
      versionmeta,
      theElderScrolls,
      jumpTo,
      inputArray,
      previewForm,
      units,
      specialChars,
      clipboardSuccessHandler,
      cssLevelVars,
      version,
      stats,
      loading,
      getColor,
      selected,
      selectedNew,
      languages,
      language,
      comment,
    };
  },
};
</script>

<style lang="scss" module>
.modal {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999999999;
  width: 500px;
  border-radius: 16px;
  padding: 40px 30px;
}

.tabswitch {
  display: flex;
  background: var(--card-light-stack);
  padding: 3px 1px;
  margin: 0px 15px 13px;
  border-radius: 10px;
  button {
    border-radius: 8px !important;
    margin: 0 2px !important;
    justify-content: center;
    font-size: 1.1em;
    padding: 7px;
  }
}

.limit_select {
  display: inline-flex;
  background: var(--black-1);
  border-radius: 6px;
  padding: 2px;
  &.limit_active {
    background: var(--white-5);
  }
  button {
    margin: 0 !important;
    padding: 5px 10px !important;
    border-radius: 5px !important;
    &:first-child {
      margin-right: 2px !important;
    }
    &.active {
      background: white;
      color: black;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }
  }
}

.techinput_container {
  padding: 0 60px 0 0;
  transition: padding 0.2s ease;
  &.hide_control {
    padding: 0 !important;
  }
}

.techinput_wrap {
  padding: 2px 0 2px 0;
  transition: padding 0.1s ease box-shadow 0.1s ease !important;
  position: relative;
  box-shadow: 0 0 0 0 #88f9;
  display: grid;
  grid-template-columns: var(--level) auto;
  .is_selected {
    box-shadow: 0 0 0 4px #88fc;
  }
  .techinput_index {
    position: absolute;
  }
}
.JSONdownload {
  height: 100px;
  font-size: 0.7em;
  width: 100%;
  resize: none;
  padding: 10px;
  border: none;
  box-shadow: 0 0 0 4px rgba(232, 73, 16, 0.3);
  border-radius: 4px;
}

.audit_check {
  background: #48f191;
  color: white;
  padding: 20px;
  font-size: 2em;
  display: inline-block;
  border-radius: 40px;
  margin: 20px 0;
}
.techinput_level {
  display: flex;
  align-items: center;
  justify-content: center;
  .techinput_level__dot {
    display: block;
    border-radius: 5px;
    width: 15px;
    height: 10px;
    background: rgba(128, 128, 128, 0.5);
    margin: 0 18px;
  }
}
.techinput_level__dot__line {
  display: inline-block;
  text-align: center;
  color: white;
  font-size: 0.9em;
  padding: 0 7px;
  border-radius: 10px;
  min-width: 40px;
}

.jsonupload {
  display: block;
  padding: 25px 10px;
  border: 1px dashed grey;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background: rgba(128, 128, 128, 0.1);
  }
  &:active {
    background: rgba(128, 128, 128, 0.2);
  }
  input {
    display: none;
  }
}

.tocEntry {
  padding: 5px 15px;
  display: flex;
  align-items: flex-start;
  flex-direction: column !important;
  &:last-of-type {
    margin-bottom: 10px;
  }
  &:first-of-type {
    margin-top: 10px;
  }
  .techinput_level {
    display: inline-flex;
    > span {
      margin: 0 5px;
      width: 15px;
      text-align: center;
    }
  }
  .tocEntry {
    margin: 0;
    padding: 5px 0 5px 20px !important;
    flex-direction: row !important;
    &:last-of-type {
      padding-bottom: 0px !important;
    }
    &:first-of-type {
      padding-top: 10px !important;
    }
    .techinput_level__dot {
      margin: 0 7px 0 17px;
    }
  }
}

.flagsAndComments {
  padding: 5px 15px;
  svg {
    opacity: 0.3;
  }
  .active {
    opacity: 1;
  }
}

.special_select {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  button {
    font-size: 1.3em;
    justify-content: center;
  }
}
</style>

<style lang="scss">
.flip-enter-active,
.flip-leave-active,
.flip-move {
  transition: all 0.15s ease !important;
}
.flip-enter,
.flip-leave-to {
  opacity: 0;
}
.flip-list-move {
  transition: transform 0.5s;
}

.opacity-enter-active,
.opacity-leave-active {
  transform: translateY(-50%) scale(1);
  transition: all 0.2s ease !important;
}
.opacity-enter,
.opacity-leave-to {
  opacity: 0;
  transform: translateY(-50%) scale(0.8) !important;
}

.opacity-basic-enter-active,
.opacity-basic-leave-active {
  transform: scale(1);
  transition: all 0.2s ease !important;
}
.opacity-basic-enter,
.opacity-basic-leave-to {
  opacity: 0;
  transform: scale(0.8) !important;
}

.ghost {
  opacity: 0.5;
}
.tocEntry_grip {
  cursor: row-resize;
}
</style>
