<template>
  <section class="rg-grid">
    <div
      class="w12"
      style="z-index: 9999"
    >
      <h2>Tabs der Maschine</h2>
      <br>
      <div
        class="card"
        style="border-radius: 10px; padding: 20px"
      >
        <datalist id="tab_tags">
          <option
            v-for="e in tab_tags"
            :key="e"
          >
            {{ e }}
          </option>
        </datalist>
        <div>
          <table class="rg-table">
            <tr>
              <th>ID</th>
              <th>Prio</th>
              <th>Typ<br><small>Gruppe</small></th>
              <th>URL</th>
              <th>Icon</th>
              <th>Titel</th>
              <th>Untertitel</th>
              <th>Tag</th>
              <th>Farbe</th>
              <th>Optionen</th>
              <th>Tab aktiv</th>
            </tr>
            <tr
              v-for="(tab, index) in tabs"
              :key="tab.tab_subtype + '_' + index"
            >
              <td>
                <code>{{ tab.id }}</code>
              </td>
              <td>
                <input
                  v-model="tab.tab_priority"
                  style="margin: 0; width: 55px"
                  type="number"
                  :placeholder="getTabType(tab.tab_type).tab_priority"
                >
              </td>
              <td>
                <strong>{{ getTabType(tab.tab_type).tab_subtype }}</strong><br>
                <small>{{ getTabType(tab.tab_type).tab_type }}</small>
              </td>
              <td>
                <a
                  :href="
                    'https://rep.guide/de/machine/' +
                      machine.slug +
                      '/' +
                      (tab.tab_path || getTabType(tab.tab_type).tab_subtype)
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ tab.tab_path || getTabType(tab.tab_type).tab_subtype }}
                </a>
              </td>
              <td style="display: flex; align-items: center">
                <div
                  :style="
                    'margin-right: 10px; width: 30px;' +
                      (!tab.tab_icon ? 'color:var(--black-5)' : '')
                  "
                >
                  <fa-layers
                    v-if="
                      tab.tab_icon === 'jaltest' ||
                        (!tab.tab_icon &&
                          getTabType(tab.tab_type).tab_icon === 'jaltest')
                    "
                  >
                    <fa
                      icon="circle"
                      transform="shrink-3 left-1.5 up-1.5"
                    />
                    <fa
                      icon="circle"
                      transform="right-5 down-5 shrink-10"
                    />
                  </fa-layers>
                  <fa
                    v-else
                    :icon="tab.tab_icon || getTabType(tab.tab_type).tab_icon"
                    class="fa-fw"
                  />
                </div>
                <input
                  v-model="tab.tab_icon"
                  style="margin: 0; width: 100px"
                  type="text"
                  :placeholder="getTabType(tab.tab_type).tab_icon"
                >
              </td>
              <td>
                <input
                  v-model="tab.tab_title"
                  style="margin: 0"
                  type="text"
                  :placeholder="getTabType(tab.tab_type).tab_title"
                >
              </td>
              <td>
                <input
                  v-model="tab.tab_subtitle"
                  style="margin: 0"
                  type="text"
                  :placeholder="getTabType(tab.tab_type).tab_subtitle"
                >
              </td>
              <td>
                <input
                  v-model="tab.tab_tag"
                  list="tab_tags"
                  style="margin: 0; width: 100px"
                  type="text"
                  :placeholder="getTabType(tab.tab_type).tab_tag"
                >
              </td>
              <td>
                <input
                  v-model="tab.tab_color"
                  style="margin: 0"
                  type="color"
                  :placeholder="getTabType(tab.tab_type).tab_color"
                >
              </td>
              <td :style="'display: inline-flex; z-index:'+(9999-index)">
                <rg-dropdown
                  stay
                  position="bottom-left"
                  style="margin-right: 5px; text-align: left;"
                  :width="420"
                >
                  <template #trigger>
                    <rg-button
                      icon-left="lock-open"
                      label="Freigaben"
                      class="is-primary"
                    />
                  </template>
                  <p
                    v-if="!tab.tab_active"
                    style="color:red"
                  >
                    <strong>Achtung:</strong> Dieser Tab ist inaktiv. Seine Berechtigungen werden daher nicht dargestellt. Um die ursprünglichen Berechtigungen zu laden, bitte den Tab zunächst aktivieren.
                  </p>
                  <p>
                    Tab wird angezeigt, wenn der Account mindestens eine der
                    folgenden Konditionen erfüllt.
                  </p>
                  <p>
                    <strong>Hat Zugriff auf Erweiterungen:</strong><br>
                  </p>
                  <div
                    v-for="extension in extensions"
                    :key="extension.id"
                    style="display: flex; gap: 10px; margin-bottom: 10px; margin-left:15px"
                  >
                    <div>
                      <label
                        class="switch"
                        style="margin-right: 5px"
                      >
                        <input
                          type="checkbox"
                          :checked="tab.tab_permissions.extension &&
                            tab.tab_permissions.extension.includes(extension.id)
                          "
                          @change="changePermission(tab.id, extension.id)"
                        >
                        <span class="slider" />
                      </label>
                    </div>
                    <div>
                      <div>
                        <strong>
                          <span>{{ extension.extension_name }}</span>
                        </strong>
                      </div>
                      <div v-if="extension.extension_description">
                        {{ extension.extension_description }}
                      </div>
                    </div>
                  </div>
                </rg-dropdown>
                <rg-dropdown
                  stay
                  position="top-left"
                  style="margin-right: 5px; text-align: left"
                  :width="500"
                >
                  <template #trigger>
                    <rg-button
                      :notice="
                        concatTabOptions(tab)
                          ? Object.keys(concatTabOptions(tab)).length
                          : null
                      "
                      icon="code"
                    />
                  </template>
                  <p>
                    <strong>Tab Optionen im JSON format:</strong><br>
                    <textarea
                      v-model="tab.tab_options"
                      style="font-family:monospace; width: 470px; overflow: auto; margin-bottom: 5px"
                      :placeholder="'vererbte Eigenschaften: ' + JSON.stringify(getTabType(tab.tab_type).tab_options || {}, null, 2)"
                      rows="10"
                    />
                    <span
                      v-if="((typeof tab.tab_options === 'string' && !tab.tab_options.trim()) || validateJSON(tab.tab_options))"
                      style="color: mediumseagreen"
                    >
                      <fa icon="check-circle" /> Valides JSON
                    </span>
                    <span
                      v-else
                      class="is-danger"
                    >
                      <fa icon="times-circle" /> Fehlerhaftes JSON (Speichern nicht möglich)
                    </span>
                    <br><br>
                    <strong>Vererbte Werte inkl. neuer Optionen:</strong><br>
                    <pre
                      style="
                        overflow: auto;
                        padding: 10px;
                        border-radius: 6px;
                        background: var(--black-1);
                      "
                    >{{ concatTabOptions(tab) || "keine Optionen definiert" }}</pre>
                  </p>
                </rg-dropdown>
              </td>
              <td>
                <label
                  class="switch"
                  style="margin: 0 5px 0 10px;"
                >
                  <input
                    v-model="tab.tab_active"
                    type="checkbox"
                    @change="save(tab)"
                  >
                  <span class="slider" />
                </label>
              </td>
              <td>
                <rg-button
                  :disabled="!((typeof tab.tab_options === 'string' && !tab.tab_options.trim()) || validateJSON(tab.tab_options))"
                  :title="!((typeof tab.tab_options === 'string' && !tab.tab_options.trim()) || validateJSON(tab.tab_options)) ? 'Tab Optionen sind fehlerhaft' : ''"
                  icon-left="save"
                  type="is-primary"
                  label="Speichern"
                  style="
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                  "
                  @click="save(tab)"
                />
                <rg-dropdown
                  position="top-left"
                  style="margin-right: 5px; text-align: left"
                >
                  <template #trigger>
                    <rg-button
                      style="
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                      "
                      icon="chevron-up"
                    />
                  </template>
                  <rg-button
                    icon-left="undo"
                    label="Tab zurücksetzen"
                    style="margin-right: 5px"
                    @click="reset(tab)"
                  />
                  <rg-button
                    label="Tab löschen"
                    icon-left="trash"
                    type="is-danger"
                    @click="remove(tab)"
                  />
                </rg-dropdown>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <br><br>
    </div>
    <div class="w12">
      <h2>Weitere Tabs hinzufügen</h2>
      <br>
      <div
        class="card"
        style="border-radius: 10px; padding: 20px"
      >
        <table class="rg-table">
          <tr>
            <th>ID</th>
            <th>Prio</th>
            <th>Typ<br><small>Gruppe</small></th>
            <th>Icon</th>
            <th>Titel<br><small>Untertitel</small></th>
            <th>Tag</th>
            <th>Farbe</th>
          </tr>
          <tr
            v-for="(tab, index) in tab_types"
            :key="tab.tab_subtype + '_' + index"
          >
            <td>
              <code>{{ tab.id }}</code>
            </td>
            <td>
              <strong>{{ tab.tab_priority }}</strong>
            </td>
            <td>
              <strong>{{ tab.tab_subtype }}</strong><br>
              <small>{{ tab.tab_type }}</small>
            </td>
            <td style="display: flex; align-items: center">
              <div style="margin-right: 10px; width: 30px">
                <fa-layers v-if="tab.tab_icon === 'jaltest'">
                  <fa
                    icon="circle"
                    transform="shrink-3 left-1.5 up-1.5"
                  />
                  <fa
                    icon="circle"
                    transform="right-5 down-5 shrink-10"
                  />
                </fa-layers>
                <fa
                  v-else
                  :icon="tab.tab_icon"
                  class="fa-fw"
                />
              </div>
            </td>
            <td>
              <strong>{{ tab.tab_title }}</strong><br>
              <small v-if="tab.tab_subtitle">{{ tab.tab_subtitle }}</small>
              <small
                v-else
                style="color: var(--black-5)"
              >ohne</small>
            </td>
            <td>
              <span
                v-if="tab.tab_tag"
                class="tag"
                style="text-transform: uppercase"
              >{{ tab.tab_tag }}</span>
              <small
                v-else
                style="color: var(--black-5)"
              >ohne</small>
            </td>
            <td>
              <strong :style="'color:' + tab.tab_color">{{
                tab.tab_color
              }}</strong>
            </td>
            <td style="text-align: right">
              <rg-dropdown
                position="top-left"
                style="margin-right: 5px; text-align: left"
                :width="500"
              >
                <template #trigger>
                  <rg-button
                    icon-left="cogs"
                    label="Optionen"
                    icon-right="chevron-up"
                  />
                </template>
                <p>
                  <strong
                    style="margin-bottom: 10px; display: block"
                  >Tab-Freigaben</strong>
                  Tab wird angezeigt, wenn der Account Zugriff auf mindestens eine der verfügbaren Erweiterungen hat. Diese können nach dem Hinzufügen konfiguriert werden.
                </p>
                <hr>
                <p>
                  <strong
                    style="margin-bottom: 10px; display: block"
                  >Tab-Optionen</strong>
                  Optionen können nur von Administratoren in der Datenbank
                  angepasst werden.
                </p>
                <pre
                  style="
                    overflow: auto;
                    margin: 10px 15px;
                    padding: 10px;
                    border-radius: 6px;
                    background: var(--black-1);
                  "
                >{{ tab.tab_options || "keine Optionen definiert" }}</pre>
              </rg-dropdown>
              <rg-button
                icon-left="plus"
                type="is-primary"
                label="Hinzufügen"
                @click="add(tab, ['cards', 'tiles'].includes(tab.tab_subtype))"
              />
            </td>
          </tr>
        </table>
        <div style="text-align: center; margin: 25px 0 10px">
          <router-link to="/machinetabs">
            Tabliste bearbeiten
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import dropdown from "@/components/elements/rg-dropdown";
import superagent from "superagent";
import { ref, onMounted } from "@vue/composition-api";
import Vue from "vue";
import { altBackendUrl } from "../../../constants";

export default {
  components: {
    "rg-dropdown": dropdown,
  },
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    onMounted(() => {
      fetchTabs();
      fetchExtensions();
      fetchTabPermissions();
    });

    let tabs = ref([]);
    let tab_types = ref([]);

    let tab_tags = ["neu", "tipp", "beta"];
    let extensions = ref([]);
    let permittedExtensions = ref({});
    let tabPermissions = ref([]);

    function fetchTabs() {
      const gqQueryTabTypes = `
    query Query {
      TabTypes {
        id
        tab_type
        tab_subtype
        tab_title
        tab_subtitle
        tab_icon
        tab_color
        tab_tag
        tab_priority
        tab_options
      }
    }
    `;
      const gqQueryMachineTabs = `
      query TabTypes($machineid: Int!) {
        Tabs(machineid: $machineid) {
          id
          tab_type
          tab_title
          tab_subtitle
          tab_icon
          tab_color
          tab_tag
          tab_priority
          tab_active
          tab_options
          tab_path
        }
      }
    `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryTabTypes,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          tab_types.value = res.body.data.TabTypes.sort(
            (a, b) => a.tab_priority - b.tab_priority
          );

          superagent
            .post(altBackendUrl)
            .send({
              query: gqQueryMachineTabs,
              variables: { machineid: parseInt(props.data.id) },
            })
            .set(
              "Authorization",
              `Bearer ${context.root.$store.state.user.apiToken}`
            )
            .then((res) => {
              tabs.value = res.body.data.Tabs;
              tabs.value = tabs.value
                ?.map((tab) => {
                  const perm =
                    tabPermissions.value.find(
                      (el) => parseInt(el.id) === tab.id
                    )?.tab_permissions || {};
                  permittedExtensions.value[tab.id] = perm.extensions;
                  return {
                    ...tab,
                    tab_permissions: {
                      extensions: perm.extensions || "",
                    },
                    tab_options: tab.tab_options
                      ? JSON.stringify(tab.tab_options, null, 2)
                      : "",
                  };
                })
                .sort((a, b) => {
                  return (
                    (a.tab_priority || getTabType(a.tab_type).tab_priority) -
                    (b.tab_priority || getTabType(b.tab_type).tab_priority)
                  );
                });
            })
            .catch((error) => {
              if (error.status === 426) {
                Vue.$toast.open({
                  message: "Sitzung abgelaufen. Bitte neu anmelden",
                  type: "error",
                  icon: "exclamation",
                });
                context.root.$store.dispatch("logout").then(() => {
                  context.root.$router.push("/login");
                });
              }
            });
        })
        .catch((error) => {
          if (error.status === 426) {
            Vue.$toast.open({
              message: "Sitzung abgelaufen. Bitte neu anmelden",
              icon: "exclamation",
              type: "error",
            });
            context.root.$store.dispatch("logout").then(() => {
              context.root.$router.push("/login");
            });
          }
        });
    }

    function getTabType(type) {
      return tab_types.value.find((tab_type) => tab_type.id === type);
    }

    function reset(tab) {
      if (
        window.confirm(
          "Möchtest du den Tab mit der ID " + tab.id + " wirklich zurücksetzen?"
        )
      ) {
        tab.tab_title = "";
        tab.tab_subtitle = "";
        tab.tab_icon = "";
        tab.tab_tag = "";
        tab.tab_color = getTabType(tab.tab_type).tab_color;
        tab.tab_priority = "";
        tab.tab_options = getTabType(tab.tab_type).tab_options || "";
        save(tab);
      }
    }

    function sluggify(string) {
      return string
        .trim()
        .toLowerCase()
        .replace(/\s/g, "")
        .replace(/[(|)|\\.]/g, "")
        .replace(/--+/g, "")
        .replace("ä", "ae")
        .replace("Ä", "Ae")
        .replace("ö", "oe")
        .replace("Ö", "Oe")
        .replace("ü", "ue")
        .replace("Ü", "Ue")
        .replace("ß", "ss")
        .replace(/\u00c4|\u00e4/, "s")
        .replace(/\s$/, "")
        .trim()
        .replace(/\s+?/g, "")
        .replace(/--+/, "")
        .replace(/^-/, "");
    }

    function concatTabOptions(tab) {
      return Object.assign(
        {},
        getTabType(tab.tab_type).tab_options,
        validateJSON(tab.tab_options) ? JSON.parse(tab.tab_options) : {}
      );
    }

    function validateJSON(string) {
      try {
        JSON.parse(string);
        return true;
      } catch (e) {
        return false;
      }
    }

    const saveTabMutation = `
      mutation Mutation($tabId: Int!, $machineid: Int, $tabType: Int, $tabSubtitle: String, $tabTitle: String, $tabIcon: String, $tabColor: String, $tabTag: String, $tabPriority: Int, $tabActive: Boolean, $tabOptions: JSON, $tabPath: String) {
        Tab(
          id: $tabId,
          machineid: $machineid,
          tab_type: $tabType,
          tab_subtitle: $tabSubtitle,
          tab_title: $tabTitle,
          tab_icon: $tabIcon,
          tab_color: $tabColor,
          tab_tag: $tabTag,
          tab_priority: $tabPriority,
          tab_active: $tabActive,
          tab_options: $tabOptions,
          tab_path: $tabPath
        ) {
          id
          machineid
          tab_type
        }
      }
    `;

    function save(tab) {
      let options = null;
      if (tab.tab_options && validateJSON(tab.tab_options)) {
        options = tab.tab_options;
      }

      superagent
        .post(altBackendUrl)
        .send({
          query: saveTabMutation,
          variables: {
            tabId: tab.id,
            tabSubtitle: tab.tab_subtitle?.trim(),
            tabTitle: tab.tab_title?.trim(),
            tabIcon: tab.tab_icon?.trim(),
            tabColor: tab.tab_color?.trim(),
            tabTag: tab.tab_tag?.trim(),
            tabPriority: tab.tab_priority,
            tabActive: tab.tab_active,
            tabOptions: options,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          if (res.body.errors) {
            Vue.$toast.open({
              message: "Der Tab konnte nicht gespeichert werden",
              icon: "exclamation",
              type: "error",
            });
            Vue.$toast.open({
              message: "Mehr informationen in der Konsole",
              icon: "exclamation",
            });
            console.log("errors:", res.body.errors);
          } else {
            //get permissionID
            const gqQueryAppPermissions = `
            query AppPermissions($relationType: String, $relationId: Int) {
              AppPermissions(relation_type: $relationType, relation_id: $relationId) {
                id
              }
            }
            `;
            superagent
              .post(altBackendUrl)
              .send({
                query: gqQueryAppPermissions,
                variables: {
                  relationType: "machine_tabs",
                  relationId: tab.id,
                },
              })
              .set(
                "Authorization",
                `Bearer ${context.root.$store.state.user.apiToken}`
              )
              .then(({ body: perm }) => {
                const appPermissionId = perm.data.AppPermissions[0].id;
                if (!appPermissionId) {
                  Vue.$toast.open({
                    message:
                      "Konnte die Berechtigungen für den Tab nicht finden. Erweiterungsberechtigungen konnten nicht gespeichert werden",
                    icon: "times-circle",
                  });
                } else {
                  //save extension permissions
                  const mSavePermissionsMutation = `
                  mutation Mutation($appPermissionId: Int, $relationType: String, $relationId: Int, $permittedExtensions: [Int]) {
                    AppPermission(id: $appPermissionId, relation_type: $relationType, relation_id: $relationId, permitted_extensions: $permittedExtensions) {
                      id
                    }
                  }
                  `;
                  superagent
                    .post(altBackendUrl)
                    .send({
                      query: mSavePermissionsMutation,
                      variables: {
                        appPermissionId: appPermissionId,
                        relationType: "machine_tabs",
                        relationId: tab.id,
                        permittedExtensions: permittedExtensions.value[tab.id],
                      },
                    })
                    .set(
                      "Authorization",
                      `Bearer ${context.root.$store.state.user.apiToken}`
                    )
                    .then(() => {
                      fetchTabs();
                      fetchTabPermissions();
                      Vue.$toast.open({
                        message:
                          "Die Berechtigungen wurden erfolgreich gespeichert.",
                        icon: "check",
                      });
                    })
                    .catch((err) => {
                      Vue.$toast.open({
                        message:
                          "Die Berechtigungen konnten nicht gespeichert werden.",
                        icon: "exclamation",
                      });
                      console.error(err);
                    });
                }
              })
              .catch((err) => {
                Vue.$toast.open({
                  message: "Beim Speichern ist ein Fheler aufgetreten.",
                  icon: "exclamation",
                });
                console.error(err);
              });

            Vue.$toast.open({
              message: "Änderungen gespeichert",
              icon: "check",
            });
            tabs.value.sort((a, b) => {
              return (
                (a.tab_priority || getTabType(a.tab_type).tab_priority) -
                (b.tab_priority || getTabType(b.tab_type).tab_priority)
              );
            });
          }
        })
        .catch((err) => {
          Vue.$toast.open({
            message: "Der Tab konnte nicht gespeichert werden",
            icon: "exclamation",
            type: "error",
          });
          Vue.$toast.open({
            message: "Mehr informationen in der Konsole",
            icon: "exclamation",
          });
          console.error(err?.response?.body?.errors);
        });
    }

    function remove(tab) {
      if (
        window.confirm(
          "Möchtest du den Tab mit der ID " + tab.id + " wirklich löschen?"
        )
      ) {
        const gqDeleteTabMutation = `
        mutation Mutation($machineTabId: Int) {
          Delete {
            MachineTab(id: $machineTabId)
          }
        }
        `;

        superagent
          .post(altBackendUrl)
          .send({
            query: gqDeleteTabMutation,
            variables: {
              machineTabId: tab.id,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then(() => {
            Vue.$toast.open({
              message: "Tab erfolgreich gelöscht",
              icon: "check",
            });
            tabs.value.splice(tabs.value.indexOf(tab), 1);
          });
      }
    }

    function add(tab, askforpath = false) {
      const path = askforpath
        ? window.prompt("Unter welcher URL soll der neue Tab aufrufbar sein?")
        : null;

      if (askforpath && !path) {
        window.alert(
          "Um diesen Tab hinzuzufügen muss eine URL angegeben werden"
        );
        return;
      }

      const payload = {
        tab_color: tab.tab_color,
        tab_path: path ? sluggify(path) : null,
      };

      if (
        tabs.value.some(
          (tab) =>
            (tab.tab_path || getTabType(tab.tab_type).tab_subtype) ===
            payload.tab_path
        )
      ) {
        window.alert(
          'Es gibt bereits einen Tab mit der URL "' + payload.tab_path + '"'
        );
        return;
      }
      superagent
        .post(altBackendUrl)
        .send({
          query: saveTabMutation,
          variables: {
            tabId: -1,
            tabType: tab.id,
            machineid: props.data.id,
            tabColor: tab.tab_color?.trim(),
            tabPath: path ? sluggify(path) : null,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          if (res.body.errors) {
            Vue.$toast.open({
              message: "Der Tab konnte nicht angelegt werden",
              icon: "exclamation",
              type: "error",
            });
            Vue.$toast.open({
              message: "Mehr informationen in der Konsole",
              icon: "exclamation",
            });
            console.log("errors:", res.body.errors);
          } else {
            Vue.$toast.open({
              message: "Tab erfolgreich angelegt",
              icon: "check",
            });
            tabPermissions.value.push({
              id: res.body.data.Tab.id,
              tab_permissions: {
                extensions: [1], //remove if default not wanted
              },
            });
            tabs.value.push({
              id: res.body.data.Tab.id,
              machineid: props.data.id,
              tab_type: tab.id,
              tab_color: tab.tab_color?.trim(),
              tab_path: path ? sluggify(path) : null,
              tab_active: true,
              tab_title: "",
              tab_subtitle: "",
              tab_icon: "",
              tab_tag: "",
              tab_priority: null,
              tab_permissions: {
                extensions: [1], //remove if default not wanted
              },
              tab_options: "",
            });
            tabs.value.sort((a, b) => {
              return (
                (a.tab_priority || getTabType(a.tab_type).tab_priority) -
                (b.tab_priority || getTabType(b.tab_type).tab_priority)
              );
            });

            //add app permissions
            const mSavePermissionsMutation = `
            mutation Mutation($appPermissionId: Int, $relationType: String, $relationId: Int, $permittedExtensions: [Int]) {
              AppPermission(id: $appPermissionId, relation_type: $relationType, relation_id: $relationId, permitted_extensions: $permittedExtensions) {
                id
              }
            }
            `;
            superagent
              .post(altBackendUrl)
              .send({
                query: mSavePermissionsMutation,
                variables: {
                  appPermissionId: -1,
                  relationType: "machine_tabs",
                  relationId: res.body.data.Tab.id,
                  permittedExtensions: [1], //remove if default not wanted
                },
              })
              .send({
                id: -1,
                relation_type: "machine_tabs",
                relation_id: res.body.data.Tab.id,
                permitted_extensions: [1], //
              })
              .set(
                "Authorization",
                `Bearer ${context.root.$store.state.user.apiToken}`
              )
              .then(() => {
                Vue.$toast.open({
                  message:
                    "Erweiterungs-Berechtigungen erfolgreich initialisiert. Lade neu...",
                  icon: "check",
                });
                setTimeout(() => {
                  context.root.$router.go();
                }, 2000);
              })
              .catch((err) => {
                console.error(err);
                Vue.$toast.open({
                  message: `Fehler beim Initialisieren der Erweiterungsberechtigungen für den neuen Tab.`,
                  icon: "exclamation",
                });
              });
          }
        });
    }

    function fetchExtensions() {
      superagent
        .get(
          "https://repguide-rest.fricke-holding.workers.dev/api/legacy/extensions"
        )
        .then(({ body: extensionList }) => {
          extensions.value = extensionList.data.Extensions;
        })
        .catch((err) => {
          Vue.$toast.open({
            message: "Die Extensions konnten nicht geladen werden.",
            icon: "exclamation",
          });
          console.error(err);
        });
    }

    function fetchTabPermissions() {
      const gqQueryTabPermissions = `
      query Query($machineId: Int) {
        Machine(id: $machineId) {
          ViewTabs {
            id
            tab_permissions
          }
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryTabPermissions,
          variables: {
            machineId: parseInt(props.data.id),
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(({ body: permissions }) => {
          tabPermissions.value = permissions.data.Machine.ViewTabs;
        })
        .catch((err) => {
          Vue.$toast.open({
            message: "Die Berechtigungen konnten nicht geladen werden.",
            icon: "exclamation",
          });
          console.error(err);
        });
    }

    function changePermission(tabId, extensionId) {
      if (!permittedExtensions.value[tabId].includes(extensionId)) {
        permittedExtensions.value[tabId].push(extensionId);
      } else {
        permittedExtensions.value[tabId].splice(
          permittedExtensions.value[tabId].indexOf(extensionId),
          1
        );
      }
    }

    return {
      machine: props.data,
      tab_types,
      tabs,
      add,
      save,
      tab_tags,
      getTabType,
      remove,
      reset,
      concatTabOptions,
      validateJSON,
      extensions,
      changePermission,
      permittedExtensions,
    };
  },
};
</script>

<style>
</style>
