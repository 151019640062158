<template>
  <div>
    <navigation>
      <div style="display: flex; gap: 5px;">
        <div>
          <router-link :to="{name:'add_contract', params:{company_id:data.id}}">
            <rg-button
              type="is-primary"
              icon-left="file-contract"
              title="Anlegen"
              label="Anlegen"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
          </router-link>
          <rg-dropdown>
            <template
              #trigger
            >
              <rg-button
                icon="chevron-down"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              />
            </template>
            <router-link :to="{name:'add_contract', params:{company_id:data.id}}">
              <rg-button
                icon-left="file-contract"
                label="Vertrag"
              />
            </router-link>
            <router-link to="/companies/add">
              <rg-button
                icon-left="users"
                label="Unternehmen"
              />
              <router-link to="/associations/add">
                <rg-button
                  icon-left="building"
                  label="Unternehmensgruppe"
                />
              </router-link>
              <router-link :to="{name:'add_account', params:{granitid:data.granitId, company: data.title, country: data.Country ? data.Country.country_code : null}}">
                <rg-button
                  icon-left="user-plus"
                  label="Account"
                />
              </router-link>
            </router-link>
          </rg-dropdown>
        </div>
        <a
          v-if="data.granitid"
          target="_blank"
          :href="`https://metabase.repguide.de/question/389-visited-machines-of-a-specific-company?granit_id=${data.granitid}`"
        >
          <rg-button
            icon-left="tractor"
            label="Maschinenhistorie"
          />
        </a>
      </div>
    </navigation>
    <main>
      <h1>
        <fa
          icon="industry"
          class="fa-fw"
        />{{ data.title }}
      </h1>
      <div>
        <code
          style="font-weight: bold"
          title="ID"
        >
          {{ data.id }}
        </code>
        <span style="opacity: 0.5"> | </span>
        <code
          style="font-weight: bold"
          title="Granit-ID"
        >
          {{ data.granitId }}
        </code>
      </div>
      <br>
      <rg-tabs>
        <router-link
          tag="button"
          replace
          :to="'/companies/' + $route.params.id + '/home'"
        >
          <fa icon="industry" />&nbsp;Übersicht
        </router-link>
        <router-link
          tag="button"
          replace
          :to="'/companies/' + $route.params.id + '/accounts'"
        >
          <fa icon="users" />&nbsp;Benutzer
        </router-link>
        <router-link
          tag="button"
          replace
          :to="'/companies/' + $route.params.id + '/contracts'"
        >
          <fa icon="file-contract" />&nbsp;Verträge
        </router-link>
      </rg-tabs>
      <br><br>
      <router-view :data="data" />
    </main>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import superagent from "superagent";
import {altBackendUrl} from "@/constants.js";
import dropdown from "@/components/elements/rg-dropdown";

export default {
  name: "UsersDetail",
  components: {
    "rg-dropdown": dropdown,
    "rg-tabs": () => import("@/components/elements/rg-tabs"),
  },
  props: {},
  setup(props, context) {
    let data = ref({});

    const gqQueryCompany = `
      query Query($companyId: Int) {
        Company(id: $companyId) {
          id
          title
          granitId
          Manager{
            id
          }
          SalesConsultant{
            id
          }
          companyComment
          companyOptions
          createdAt
          updatedAt
          Association{
            id
          }
          companyAnnotations
          GranitShop{
            id
          }
          ContractPartner {
            id
          }
          Country {
            id
            country_name
            country_code
          }
          Users(limit: -1, skip: -1) {
            nodes{
              id
              lastName
              firstName
            }
          }
          sap_id
        }
      }
    `;
    superagent
      .post(altBackendUrl)
      .send({
        query: gqQueryCompany,
        variables: { companyId: parseInt(context.root.$route.params.id) },
      })
      .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
      .then((res) => {
        const Company = res.body.data.Company
        data.value = {
            'id': Company?.id || null,
            'title': Company?.title || null,
            'granitId': Company?.granitId || null,
            'granitShopId': Company?.GranitShop?.id || null,
            'contractPartnerId': Company?.ContractPartner?.id || null,
            'companyAssociationId': Company?.Association?.id || null,
            'companyComment': Company?.companyComment || null,
            'managingUserId': Company?.Manager?.id || null,
            'sapId': Company?.sap_id || null,
            'Users': Company?.Users?.nodes || null,
            'salesRepresentativeId': Company.SalesConsultant?.id || null,
            'Country': Company?.Country[0] || null,
            'createdAt': Company?.createdAt || null,
            'updatedAt': Company?.updatedAt || null,
          }
      
      });

    return { data };
  },
};
</script>

<style lang="scss" module>
.detail {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-flow: dense;
  grid-auto-columns: 1fr 1fr;
}
</style>
