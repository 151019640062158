var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('rg-widget',{scopedSlots:_vm._u([{key:"data",fn:function(){return _vm._l((_vm.contracts),function(contract){return _c('div',{key:contract.id},[_c('div',{staticClass:"level"},[_c('h3',[_c('strong',[_vm._v(_vm._s(contract.contract_type))]),_vm._v(" (ID) "),_c('strong',[_vm._v(_vm._s(contract.id))]),(!contract.contract_end && !contract.contract_start)?_c('div',{staticStyle:{"color":"hsl(44, 100%, 48%)"}},[_c('fa',{staticClass:"fa-fw",attrs:{"icon":"play"}}),_vm._v(" "),_c('strong',[_vm._v(" Vertrag nicht aktiviert")])],1):(
                contract.contract_end &&
                !contract.contract_successor &&
                _vm.compareDates(
                  new Date(contract.contract_end),
                  new Date(),
                  'gte'
                )
              )?_c('div',{staticStyle:{"color":"hsl(44, 100%, 48%)"}},[_c('fa',{staticClass:"fa-fw",attrs:{"icon":"exclamation-triangle"}}),_vm._v(" "),_c('strong',[_vm._v("Kündigung eingegangen")])],1):(
                contract.contract_end &&
                !contract.contract_successor &&
                _vm.compareDates(
                  new Date(contract.contract_end),
                  new Date(),
                  'lt'
                )
              )?_c('div',{staticStyle:{"color":"hsl(348, 100%, 61%)"}},[_c('fa',{staticClass:"fa-fw",attrs:{"icon":"handshake-slash"}}),_vm._v(" "),_c('strong',[_vm._v("Vertrag gekündigt")])],1):(
                contract.contract_start &&
                _vm.isContractInFuture(contract.contract_start)
              )?_c('div',{staticStyle:{"color":"grey"}},[_c('fa',{staticClass:"fa-fw is-primary",attrs:{"icon":"arrow-up"}}),_vm._v(" "),_c('strong',[_vm._v("Zukünftiger Vertrag")])],1):(
                contract.contract_end &&
                _vm.contractHasEnded(contract.contract_end) &&
                contract.contract_successor
              )?_c('div',{staticStyle:{"color":"grey"}},[_c('fa',{staticClass:"fa-fw is-primary",attrs:{"icon":"clock"}}),_vm._v(" "),_c('strong',[_vm._v("Vertrag abgelaufen")])],1):(
                (contract.contract_start &&
                  !_vm.contractHasEnded(contract.contract_end) &&
                  contract.contract_successor) ||
                (contract.contract_start && !contract.contract_end)
              )?_c('div',{staticStyle:{"color":"#1575f7"}},[_c('fa',{staticClass:"fa-fw is-primary",attrs:{"icon":"handshake"}}),_vm._v(" "),_c('strong',[_vm._v("Vertrag aktiv")])],1):_vm._e()]),_c('router-link',{attrs:{"tag":"button","to":'/contracts/' + contract.id}},[_vm._v(" Vertrag öffnen ")])],1),_c('table',{staticClass:"rg-table condensed"},_vm._l((contract),function(property,key){return _c('tr',{key:key},[_c('th',[_vm._v(_vm._s(key))]),(
                [
                  'contract_start',
                  'contract_end',
                  'createdat',
                  'updatedat' ].includes(key)
              )?_c('td',{attrs:{"title":property ? new Date(property).toLocaleString() : ''}},[_vm._v(" "+_vm._s(property ? new Date(property).toLocaleString(undefined, { day: "2-digit", month: "2-digit", year: "numeric", }) : "")+" "+_vm._s(property ? ("(" + (new Date(property).toUTCString()) + ")") : "")+" ")]):(key === 'contract_options')?_c('td',[_c('pre',{staticStyle:{"white-space":"pre-wrap","word-break":"break-word"}},[_vm._v(_vm._s(property))])]):(key === 'PaymentExemptions')?_c('td',_vm._l((property),function(element){return _c('p',{key:element.id},[_vm._v(" "+_vm._s(element.exemption_start ? new Date(element.exemption_start).toLocaleString( undefined, { day: "2-digit", month: "2-digit", year: "numeric", timeZone: "UTC", } ) : "")+" bis "+_vm._s(element.exemption_end ? new Date(element.exemption_end).toLocaleString( undefined, { day: "2-digit", month: "2-digit", year: "numeric", timeZone: "UTC", } ) : "")+" "+_vm._s(element.exemption_comment ? ("(" + (element.exemption_comment) + ")") : "")+" ")])}),0):_c('td',[_vm._v(" "+_vm._s(property)+" ")])])}),0)])})},proxy:true}])},[_vm._v(" Verträge ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }